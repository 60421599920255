<!--NavBar-> редактировать дома -> Редактировать список домов  -->

<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="60%">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer
            v-model="showEditHomeDialog"
            temporary
            absolute
            right
            :permanent="getLockClaimDetail"
          >
            <edit-home-detail
              v-if="showEditHomeDialog"
              :home-id="currentHomeId"
              @close="showEditHomeDialog = false"
            />
          </v-navigation-drawer>
        </div>
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px">
                <v-col>
                  <span class="headline">Список домов</span>
                </v-col>
              </v-card-title>
              <v-row class="ml-4 mr-4">
                <v-col class="ml-2 mr-2 mt-4 ">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="ml-9 mr-9">
                  <v-data-table
                    :headers="dataHeaders"
                    :items="homeList"
                    :search="search"
                    height="40vh"
                    calculate-widths
                    fixed-header
                    dense
                    :footer-props="tableFooterProps"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <!-- editDetailHome открывается модальное окно  -->
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="editDetailHome(item.value)"
                        >
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.company }}
                          </td>
                          <td class="table_instruction" style="cursor:pointer">{{ item.city }}</td>
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.street }}
                          </td>
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.house_number }}
                          </td>
                          <!-- <td class="table_instruction" style="cursor:pointer">{{ item.house_fiasid }}</td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions style="padding: 24px; justify-content: end;">
                <v-btn
                  color="#fe81a1"
                  @click="closeDialog"
                  min-width="135"
                  tile
                  style="margin:12px"
                  outlined
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditHomeDetail from "@/components/EditHomeDetail";

export default {
  components: {
    EditHomeDetail
  },
  props: {
    showEditHome: {
      type: Boolean
    }
  },
  data: () => ({
    lockHomeDetail: true,
    dialog: false,
    showEditHomeDialog: false,
    currentLocalityId: null,
    benched: 0,
    currentHomeId: null,
    search: "",
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [
      { text: "Компания", value: "company", width: "15%" },
      { text: "Населенный пункт", value: "city", width: "15%" },
      { text: "Улица", value: "street", width: "15%" },
      { text: "Номер дома", value: "house_number", width: "10%" }
      // { text: "ФИАС код", value: "house_fiasid" }
    ]
  }),
  watch: {
    showEditCompany(value) {
      if (value) {
        this.$store.dispatch("getCompanyList");
      }
    },
    homeList() {
      return this.$store.getters.getterHomeList;
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditHome() {
      this.dialog = true;
    },
    async editDetailHome(id) {
      this.$store.commit("editDialogMount");
      await this.getHomeDetail(id);
      this.currentHomeId = id;
      this.showEditHomeDialog = true;
    },
    deleteCompany(id) {
      this.deleteCompanyAction(id);
    },
    ...mapActions({
      deleteCompanyAction: "deleteCompany",
      getHomeDetail: "getHomeDetail"
    })
  },
  computed: {
    ...mapGetters(["getLazyLoadEditDialog", "getLockClaimDetail"]),
    homeList() {
      return this.$store.getters.getterHomeList;
    }
  },

  mounted() {
    this.$store.dispatch("getLocalityList");
    this.$store.dispatch("getHomeList");
  }
};
</script>

<style></style>
