import Vue from "vue";
import Vuelidate from "vuelidate";
import store from "./store";
import App from "./App.vue";
import router from "./routes.js";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@/scss/style.scss";
import fixDateTime from "@/filters/fixdate";
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway";
import Vuebar from "vuebar";
import JsonExcel from "vue-json-excel";
import vClickOutside from "v-click-outside";
import VueYandexMetrika from "vue-yandex-metrika";
// import AutoReload from "@/plugins/AutoReload/AutoReload";

import VueClipboard from "vue-clipboard2"; // import

Vue.use(VueClipboard);
Vue.use(vClickOutside);
Vue.use(Vuebar);
Vue.component("downloadExcel", JsonExcel);
Vue.config.devtools = true;
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;
Vue.filter("datetime", fixDateTime);
new Vue({
  mixins: [clickaway],
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Vue.use(Vuelidate);
Vue.directive("success", {
  bind(el) {
    el.style.color = "green";
  }
});

Vue.use(VueYandexMetrika, {
  id: 90240791,
  router: router,
  env: process.env.NODE_ENV
});
