<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="60%">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer
            v-model="showEditExecutorDialog"
            :permanent="getLockExecutorDetail"
            temporary
            absolute
            right
            style="height:100vh"
          >
            <edit-executor-detail
              v-if="showEditExecutorDialog"
              :executor-id="currentExecutorId"
              @close="showEditExecutorDialog = false"
            />
          </v-navigation-drawer>
        </div>
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px ">
                <v-col>
                  <span class="headline">Список исполнителей</span>
                </v-col>
              </v-card-title>
              <v-row class="ml-4 mr-4">
                <v-col class="ml-4 mr-2 mt-4 ">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="ml-9 mr-9">
                  <v-data-table
                    :headers="dataHeaders"
                    :items="getterExecutorList"
                    :search="search"
                    height="40vh"
                    calculate-widths
                    fixed-header
                    dense
                    :footer-props="tableFooterProps"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="editDetailExecutor(item.value)"
                        >
                          <td style="cursor:pointer">
                            {{ item.specialization }}
                          </td>
                          <td style="cursor:pointer">
                            {{ item.get_requisites }}
                          </td>
                          <td style="cursor:pointer">{{ item.phone }}</td>
                          <!-- <td style="cursor:pointer">{{ item }}</td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions style="padding: 24px; justify-content: end;">
                <v-btn
                  color="#fe81a1"
                  @click="closeDialog"
                  tile
                  outlined
                  min-width="135"
                  style="margin:12px "
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditExecutorDetail from "@/components/EditExecutorDetail";

export default {
  components: {
    EditExecutorDetail
  },
  props: {
    showEditExecutor: {
      type: Boolean
    }
  },
  data: () => ({
    dialog: false,
    showEditExecutorDialog: false,
    currentExecutorId: null,
    benched: 0,
    search: "",
    testValue: true,
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [
      { text: "Специализация", value: "specialization", width: "15%" },
      { text: "Исполнитель", value: "get_requisites", width: "15%" },
      { text: "Телефон", value: "phone", width: "10%" }
      // { text: "Компания", value: "companies", width: "10%" }
    ]
  }),
  watch: {
    showEditExecutor(value) {
      if (value) {
        this.$store.dispatch("getCompanyList");
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditExecutorList() {
      this.dialog = true;
    },
    async editDetailExecutor(id) {
      this.$store.commit("editDialogMount");
      await this.getExecutorDetail(id);
      this.currentExecutorId = id;
      this.showEditExecutorDialog = true;
    },

    ...mapActions({
      getExecutorDetail: "getExecutorDetail"
    })
  },
  computed: {
    ...mapGetters(["getterExecutorList", "getLazyLoadEditDialog", "getLockExecutorDetail"])
  },
  mounted() {
    this.$store.dispatch("getExecutorList");
    console.log("getExecutorList", this.getterExecutorList);
  }
};
</script>

<style></style>
