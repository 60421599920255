<!-- Модальное окно, таблица >Список компаний  -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="50%">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer v-model="showEditCompanyDialog" temporary absolute right>
            <edit-company-detail
              v-if="showEditCompanyDialog"
              :company-id="currentCompanyId"
              @close="showEditCompanyDialog = false"
            />
          </v-navigation-drawer>
        </div>
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px ">
                <v-col>
                  <span class="headline">Список компаний</span>
                </v-col>
              </v-card-title>
              <v-row class="ml-4 mr-4">
                <v-col class="ml-2 mr-2 mt-4 ">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="ml-9 mr-9">
                  <v-data-table
                    :headers="dataHeaders"
                    :items="companyList"
                    :search="search"
                    height="40vh"
                    calculate-widths
                    fixed-header
                    dense
                    :footer-props="tableFooterProps"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="editDetailCompany(item)"
                        >
                          <td style="cursor:pointer">{{ item.text }}</td>

                          <td style="cursor:pointer">{{ item.inn }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions style="padding: 24px; justify-content: end;">
                <v-btn
                  color="#fe81a1"
                  @click="closeDialog"
                  min-width="135"
                  style="margin:12px"
                  outlined
                  tile
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditCompanyDetail from "@/components/EditCompanyDetail";

export default {
  components: {
    EditCompanyDetail
  },
  props: {
    showEditCompany: {
      type: Boolean
    }
  },
  data: () => ({
    formCompany: {
      companyName: "",
      inn: "",
      address: ""
    },
    dialog: false,
    showEditCompanyDialog: false,
    currentCompanyId: null,
    benched: 0,
    search: "",
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [
      { text: "Компания", value: "text", width: "15%" },

      { text: "ИНН", value: "inn", width: "10%" }
    ]
  }),
  watch: {
    showEditCompany(value) {
      if (value) {
        this.$store.dispatch("getCompanyList");
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditCompany() {
      this.dialog = true;
    },
    async editDetailCompany(id) {
      this.$store.commit("editDialogMount");
      this.getCompanyDetail(id);
      this.currentCompanyId = id;
      this.showEditCompanyDialog = true;
    },
    deleteCompany(id) {
      this.deleteCompanyAction(id);
    },
    ...mapActions({
      deleteCompanyAction: "deleteCompany",
      getCompanyDetail: "getCompanyDetail"
    })
  },
  computed: {
    ...mapGetters(["companyList", "getLazyLoadEditDialog"])
  },
  mounted() {
    this.$store.dispatch("getCompanyList");
  }
};
</script>

<style></style>
