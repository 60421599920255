<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="60%">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer
            v-model="showEditOwnerDialog"
            temporary
            absolute
            right
            style="height:100vh"
          >
            <edit-owner-detail
              v-if="showEditOwnerDialog"
              :owner-id="currentOwnerId"
              @close="showEditOwnerDialog = false"
            />
          </v-navigation-drawer>
        </div>

        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class="header" style="padding:24px ">
                <v-col>
                  <span class="headline">Список собственников</span>
                </v-col>
              </v-card-title>
              <v-row class="ml-4 mr-4">
                <v-col class="ml-2 mr-2 mt-4 ">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="ml-9 mr-9">
                  <v-data-table
                    :headers="dataHeaders"
                    :items="getApplicantList"
                    :search="search"
                    height="40vh"
                    calculate-widths
                    fixed-header
                    dense
                    :footer-props="tableFooterProps"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="editDetailOwner(item.value)"
                        >
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.personal_account }}
                          </td>
                          <td
                            class="table_instruction"
                            style="cursor:pointer"
                            :title="item.company"
                          >
                            {{ item.get_requisites }}
                          </td>
                          <td class="table_instruction" style="cursor:pointer">{{ item.phone }}</td>
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.address }}
                          </td>
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.placement }}
                          </td>
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.entrance }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions style="padding: 24px; justify-content: end;">
                <v-btn
                  color="#fe81a1"
                  @click="closeDialog"
                  min-width="135"
                  tile
                  style="margin:12px"
                  outlined
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditOwnerDetail from "@/components/EditOwnerDetail";

export default {
  components: {
    EditOwnerDetail
  },
  props: {
    showEditOwners: {
      type: Boolean
    }
  },
  data: () => ({
    dialog: false,
    lazyLoad: false,
    showEditOwnerDialog: false,
    currentOwnerId: null,
    benched: 0,
    search: "",
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [
      { text: "л/с", value: "personal_account", width: "8%" },
      { text: "Собственник", value: "get_requisites", width: "10%" },
      {
        text: "Телефон",
        value: "phone",
        width: "8%"
      },
      { text: "Адрес", value: "address", width: "15%" },
      { text: "Помещение", value: "placement", width: "8%" },
      {
        text: "Подъезд",
        value: "entrance",
        width: "5%"
      }
    ]
  }),
  watch: {
    showEditCompany(value) {
      if (value) {
        this.$store.dispatch("getCompanyList");

      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditOwnerList() {
      this.dialog = true;
    },

    async editDetailOwner(id) {
      this.$store.commit("editDialogMount");
              console.log (this.$store.commit("editDialogMount!!!!!!!!!!!!!!"))
      await this.getOwnerDetail(id);
      console.log("this id", id);
      this.currentOwnerId = id;
      this.showEditOwnerDialog = true;
    },
    deleteCompany(id) {
      this.deleteCompanyAction(id);
    },
    ...mapActions({
      deleteCompanyAction: "deleteCompany",
      getOwnerDetail: "getOwnerDetail"
    })
  },
  computed: {
    ...mapGetters(["getApplicantList", "getLazyLoadEditDialog"])
  },
  mounted() {
    this.$store.dispatch("getPropertyOwners");
  }
};
</script>

<style>
.table_instruction {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
  text-align: left;
}
</style>
