<template>
  <div class="sip-dialler">
    <v-sheet class="d-flex transparent mt-8">
      <v-text-field
        v-if="ua == null"
        v-model="formLogin.login"
        class="mr-4 ml-4"
        hide-details
        label="Логин"
      ></v-text-field>
      <v-text-field
        v-if="ua == null"
        v-model="formLogin.password"
        class="mr-4 ml-4"
        hide-details
        label="Пароль"
        type="password"
      ></v-text-field>
      <v-btn
        v-if="ua == null"
        class="mt-3"
        color="green"
        dark
        outlined
        tile
        title="Войти"
        @click="login"
      >
        Войти
      </v-btn>
      <v-text-field
        v-if="ua != null"
        v-model="phone"
        v-mask="'+7 (###) ###-##-##'"
        class="mr-4 ml-4"
        hide-details
        label="Телефон"
        @click="clickPhone"
      ></v-text-field>

      <v-btn
        v-show="!currentSession && ua != null"
        class="mt-3"
        color="#6292a8"
        outlined
        tile
        title="Позвонить"
        @click="makeCall"
      >
        Позвонить
      </v-btn>
      <v-btn
        v-if="callWaitingForAnswer"
        class="mr-2"
        color="green"
        dark
        height="64px"
        small
        title="Ответить"
        @click="answerCall"
      >
        Ответить
      </v-btn>
      <v-btn
        v-show="currentSession"
        color="error"
        dark
        height="64px"
        small
        title="Сбросить звонок"
        @click="hangUp"
      >
        Сбросить
      </v-btn>
      <div v-show="callInProcess && sipEnabled" class="ml-2">
        <v-btn block class="mb-2" color="orange" dark small title="Поставить звонок на удержание">
          Пауза
        </v-btn>
        <v-btn block color="orange" dark small title="Перенаправить звонок">
          Трансфер
        </v-btn>
      </div>
      <div v-show="callInProcess && sipEnabled" class="ml-2">
        <v-btn
          :color="muted ? 'green' : 'red'"
          block
          class="mb-2"
          dark
          small
          title="Выключить микрофон (незаметно для клиента)"
        >
          {{ muted ? "Вкл" : "Выкл." }} мкр.
        </v-btn>
        <v-btn block color="orange" dark small title="Донабор добавочного номера">
          Добор
        </v-btn>
      </div>
    </v-sheet>
    <v-sheet v-show="currentSession" class="pa-3 headline mt-4" elevation="2">
      {{ displayCallerName }} ({{ recipientPhone }})
    </v-sheet>

    <audio id="localAudio" ref="localAudio" autoPlay muted></audio>
    <audio id="remoteAudio" ref="remoteAudio" autoPlay></audio>
    <audio id="dialerSounds" ref="dialerSounds" autoPlay></audio>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import JsSIP from "jssip";
import { mapState } from "vuex";
import { VueMaskDirective } from "v-mask";
import Vue from "vue";

Vue.directive("mask", VueMaskDirective);

// const webRTCConfig = {
//   pcConfig: {
//     hackStripTcp: true, // Важно для хрома, чтоб он не тупил при звонке
//     rtcpMuxPolicy: 'negotiate', // Важно для хрома, чтоб работал multiplexing.
//     iceServers: [
//       // { urls: ['stun:5.101.116.53:3480'] },
//       {urls: ['stun:leverans.ru:3478']},
//     ],
//   },
//   mediaConstraints: {
//     audio: true, // Поддерживаем только аудио
//     video: false,
//   },
//   rtcOfferConstraints: {
//     offerToReceiveAudio: 1, // Принимаем только аудио
//     offerToReceiveVideo: 0,
//   },
// };

// eslint-disable-next-line no-unused-vars
const webRTCConfig = {
  pcConfig: {
    hackStripTcp: true, // Важно для хрома, чтоб он не тупил при звонке
    rtcpMuxPolicy: "negotiate", // Важно для хрома, чтоб работал multiplexing. Эту штуку обязательно нужно включить на астере.
    iceServers: []
  },
  mediaConstraints: {
    audio: true, // Поддерживаем только аудио
    video: false
  },
  rtcOfferConstraints: {
    offerToReceiveAudio: 1, // Принимаем только аудио
    offerToReceiveVideo: 0
  }
};

export default {
  name: "SipDialer",
  props: {
    callPhone: {
      type: [String, Number]
    }
  },
  mounted() {
    if (this.ua == null) {
      console.log("сработал первый if");
      if (localStorage.getItem("phoneLogin")) {
        console.log("сработал второй if");
        setTimeout(() => {
          console.log("сработал третий if");
          this.phoneLogin = localStorage.getItem("phoneLogin");
          this.login();
          console.log("сработал login");
        }, 5000);
      }
    }
  },
  data: () => ({
    phone: "",
    clearPhone: "",
    labelPhone: "",
    phoneLogin: "",
    phonePassword: "",
    // callTypeCode: null,
    // outsourcedCompany: '',
    // callerName: '',
    onHold: false,
    muted: false,
    currentSession: null, // текущая сессия, пусто, если сейчас оператор свободен
    ua: null,
    localClonedStream: null,
    session: "",
    formLogin: {
      login: "",
      password: ""
    }
  }),
  watch: {
    callPhone(phone) {
      if (phone) {
        this.phone = phone;
      }
    },
    sipAuth() {
      this.sipLogout();
      this.sipLogin();
    },
    operatorSessionPaused(oldPause, newPause) {
      if (!oldPause && newPause) {
        this._makeCall("91");
      } else if (oldPause && !newPause) {
        this._makeCall("99");
      }
    },
    operatorSessionStarted(oldStarted, newStarted) {
      if (!oldStarted && newStarted) {
        this._makeCall("99");
      } else if (oldStarted && !newStarted) {
        this._makeCall("91");
      }
    }
  },
  computed: {
    mediaControls() {
      return {
        local: this.$refs.localAudio,
        remote: this.$refs.remoteAudio,
        sounds: this.$refs.dialerSounds
      };
    },
    sipAuth() {
      const { login, password } = this.$store.state.sip;
      return { login, password };
    },
    ...mapState({
      callId: state => state.currentCall.id,
      operatorSessionPaused: state => state.session.paused,
      operatorSessionStarted: state => state.session.started,
      sipEnabled: state => !state.sip.isExternalPhone,

      callWaitingForAnswer: state => state.currentCall.callWaitingForAnswer,
      callInProcess: state => state.currentCall.callInProcess,
      // onHold: state => state.currentCall.onHold,
      // muted: state => state.currentCall.muted,

      displayCallerName: state => state.currentCall.callerName.replace("leverans", ""),
      recipientPhone: state => state.currentCall.phone
    })
  },
  methods: {
    clickPhone() {
      console.log("clickPhone");
      this.phone = "+7 (";
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    },
    openCallPanel() {
      this.$store.commit("setCallPanelVisibility", true);
    },
    closeCallPanel() {
      this.$store.commit("setCallPanelVisibility", false);
    },
    // sipLogin() {
    //   console.log('hi jssip')
    //   if (!this.sipEnabled || !this.sipAuth.login || !this.sipAuth.password) {
    //     return;
    //   }
    //
    //   console.log('hi jssip2')
    //   const socket = new JsSIP.WebSocketInterface(config['SIP_WS_URL']);
    //   this.ua = new JsSIP.UA({
    //     uri: `sip:${this.sipAuth.login}@${config['SIP_URL']}`,
    //     password: this.sipAuth.password,
    //     display_name: this.sipAuth.login,
    //     sockets: [socket],
    //   });
    //
    //   // присоединяем слушатели событий JSSIP
    //   this.onConnecting();
    //   this.onConnected();
    //   this.onRegistered();
    //   this.onRegistrationFailed();
    //   this.onUnregistered();
    //   this.onCall();
    //   this.makeCall();
    //
    //   this.ua.start();
    // },
    sipLogout() {
      if (!this.ua) {
        return;
      }
      console.log("SIP Logout");
      this.ua.stop();
    },
    onConnecting() {
      this.ua.on("connecting", () => {
        console.log("SIP connecting");
      });
    },
    onConnected() {
      this.ua.on("connected", () => {
        console.log("SIP connected");
      });
    },
    onRegistered() {
      this.ua.on("registered", () => {
        this.$store.commit("setSipConnection", true);
        this.$store.dispatch("pushNotification", {
          type: "success",
          message: "SIP: успешная регистрация в сервисе телефонии"
        });
        console.log("SIP registered");
      });
    },
    onUnregistered() {
      this.ua.on("unregistered", () => {
        this.$store.commit("setSipConnection", false);
        console.log("SIP unregistered");
      });
    },
    onRegistrationFailed() {
      this.ua.on("registrationFailed", data => {
        this.$store.commit("setSipConnection", false);
        this.$store.dispatch("pushNotification", {
          type: "error",
          message: `Проблема с регистрацией в сервисе телефонии: ${data.cause}`
        });
        console.error("SIP registrationFailed", data.cause);
      });
    },
    setupMediaStreams() {
      const peerconnection = this.currentSession.connection;
      const localStream = peerconnection.getLocalStreams()[0];

      if (localStream) {
        this.localClonedStream = localStream.clone();
        console.log("UA set local stream");
        // непонятно почему, но он в какой-то момент размутируется
        this.mediaControls.local.muted = true;
        this.mediaControls.local.srcObject = this.localClonedStream;
      }

      peerconnection.addEventListener("addstream", event => {
        console.log("UA session addstream");
        // this.mediaControls.local.muted = false; // предположительно баг
        this.mediaControls.remote.srcObject = event.stream;
      });
    },

    onCall(ua) {
      ua.on("newRTCSession", data => {
        // на любой звонок, позже определим
        const { session, request, originator } = data;
        const isOutgoing = originator === "local"; // определяем входящий/исходящий

        const callRawData = {
          sipId: request.getHeader("X-Sip-Id") || "",
          callerName: session.remote_identity.display_name,
          phone: session.remote_identity.uri.user.replace("+", ""),
          session,
          isOutgoing,
          isIncoming: !isOutgoing
        };
        if (callRawData.isIncoming) {
          if (callRawData.isIncoming && (this.currentSession || this.$store.state.session.paused)) {
            // ЗАНЯТО!
            console.warn("we`re busy - rejecting!");
            session.terminate({
              status_code: 486,
              reason_phrase: "Busy Here"
            });
            return;
          }
          this.currentSession = session; // а исходящая запомнилась еще раньше снаружи
          this.playSound("ring.mp3", true); // ДЗИНЬ!
          console.log("дзинь");
        }
        this.$store.dispatch("processNewCall", callRawData); // отправляем в АПИ

        session.on("connecting", () => {
          console.log("Cоединение установлено");
          if (isOutgoing) {
            this.setupMediaStreams(); // подключаем медиа-поток
          }
        });

        session.on("progress", () => {
          console.log("Звонок в процессе дозвона");
        });

        session.on("failed", () => {
          this.playSound("reject.mp3", false);
          console.log("Звонок оборвался");
          this.currentSession = null;
          this.$store.dispatch("closeCall", {
            status: 4 // REJECTED
          });
        });

        session.on("ended", () => {
          JsSIP.Utils.closeMediaStream(this.localClonedStream);
          console.log("Звонок завершен");
          this.currentSession = null;
          this.$store.dispatch("closeCall", {
            status: 0 // None
          });
        });

        session.on("accepted", () => {
          console.log("Звонок принят");
          this.stopSound("ring.mp3");
          // this.playSound('answered.mp3', false);
          this.$store.dispatch("answerCall");
        });
      });
    },
    // ХЭНДЛЕРЫ КНОПОК
    answerCall() {
      // хэндлер кнопки ответить
      console.log("кнопка ответ");
      console.log("this currentSession", this.currentSession);
      if (this.currentSession) {
        console.log("Отвечаем на звонок");
        this.currentSession.answer(webRTCConfig);
        // похоже если ждать onConnecting, то можно опоздать к потоку
        // поэтому при входящем настраиваем его вот тут сразу
        //  this.setupMediaStreams();
        // let peerconnection = this.session.connection;
        let peerconnection = this.currentSession.connection;
        const localStream = peerconnection.getLocalStreams()[0];
        console.log("answerCall localStream", localStream);

        // Handle local stream
        if (localStream) {
          // Clone local stream
          this.localClonedStream = localStream.clone();

          console.log("UA set local stream");

          let localAudioControl = document.getElementById("localAudio");
          localAudioControl.srcObject = this._localClonedStream;
          console.log("ttest answerCall localAudiocontrol");
        }

        // Как только астер отдаст нам поток абонента, мы его засунем к себе в наушники
        peerconnection.addEventListener("addstream", event => {
          console.log("UA session addstream");

          let remoteAudioControl = document.getElementById("remoteAudio");
          remoteAudioControl.srcObject = event.stream;
          console.log("test answerCall remoteAudioControl", remoteAudioControl);
        });
      }
    },
    login() {
      console.log("сработал work login");
      if (!localStorage.getItem("phoneLogin")) {
        this.phoneLogin = localStorage.setItem("phoneLogin", this.formLogin.login);
        this.phonePassword = localStorage.setItem("phonePassword", this.formLogin.password);
      } else {
        this.phoneLogin = localStorage.getItem("phoneLogin");
        this.phonePassword = localStorage.getItem("password");
      }
      let { phone } = this;
      this.phone = phone;
      let socket = new JsSIP.WebSocketInterface("wss://upr.leverans.ru:18089/ding/ws");
      const uri = `sip:operator1@upr.leverans.ru`;
      setTimeout(() => {
        this.ua = new JsSIP.UA({
          uri: uri, //:6050",
          password: "qazxswedc123",
          display_name: "operator1",
          sockets: [socket]
        });
        console.log("506 this ua", this.ua);

        console.log("ua", this.ua);
        console.log(phone, socket);
        //
        console.log(phone);
        phone = phone.replace(/\s/g, "");
        this.ua.on("connecting", () => {
          console.log("UA connecting");
        });
        this.ua.on("connected", () => {
          console.log("UA connected");
        });
        this.ua.on("registered", () => {
          console.log("UA registered");
        });
        this.ua.on("newRTCSession", data => {
          // на любой звонок, позже определим
          const { session, request, originator } = data;
          const isOutgoing = originator === "local"; // определяем входящий/исходящий
          console.log("session", session);
          console.log("request", request);
          console.log("isOutgoing", isOutgoing);
          const callRawData = {
            sipId: request.getHeader("X-Sip-Id") || "",
            callerName: session.remote_identity.display_name,
            phone: session.remote_identity.uri.user.replace("+", ""),
            session,
            isOutgoing,
            isIncoming: !isOutgoing
          };
          if (callRawData.isIncoming) {
            this.$store.commit("setCallPanelVisibility", true);
            console.log("callRawData", callRawData);
            this.$store.dispatch("processNewCall", callRawData);
          }
          this.currentSession = session;
          session.on("accepted", () => {
            this.$store.commit("setCallWaitingForAnswer", false);
            console.log("Звонок принят$");
            // this.stopSound('ring.mp3');
            // this.playSound('answered.mp3', false);
            // this.$store.dispatch('answerCall');
            console.log("Звонок принят&");
          });
        });

        this.ua.start();
        // this.onCall(ua)
      }, 100);
    },
    makeCall() {
      // хэндлер кнопки вызова номера
      this.clearPhone = this.phone.replace(/[-() ]/g, "");
      this.clearPhone = this.clearPhone.replace("+", "");
      console.log(this.clearPhone);
      this.session = this.ua.call(this.clearPhone, webRTCConfig);
      this.currentSession = this.session;
      this.session.on("connecting", () => {
        console.log("UA session connecting");
        let peerconnection = this.session.connection;
        let localStream = peerconnection.getLocalStreams()[0];
        console.log("makeCall localStream", localStream);

        // Handle local stream
        if (localStream) {
          // Clone local stream
          this._localClonedStream = localStream.clone();

          console.log("UA set local stream");

          let localAudioControl = document.getElementById("localAudio");
          localAudioControl.srcObject = this._localClonedStream;
          console.log("test makeCall localAudiocontrol");
        }

        // Как только астер отдаст нам поток абонента, мы его засунем к себе в наушники
        peerconnection.addEventListener("addstream", event => {
          console.log("UA session addstream");

          let remoteAudioControl = document.getElementById("remoteAudio");
          remoteAudioControl.srcObject = event.stream;
          console.log("test makeCall remoteAudioControl");
        });
      });

      // if (phone === '99' || phone === '999') return;
      // if (this.sipEnabled) {
      //   this._makeCall(phone);
      // } else {
      //   if (phone === '91' || phone === '900') return;
      //   this.$store.dispatch(
      //     'createExternalOutgoingCall',
      //     {phone, sipLogin: this.sipAuth.login},
      //   );
      // }
    },
    // _makeCall(phone) { // НАСТОЯЩИЙ хэндлер кнопки вызова номера
    //   if (!phone || this.currentSession || !this.sipEnabled) {
    //     return;
    //   }
    //   this.currentSession = this.ua.call(phone, webRTCConfig);
    // },
    // transfer() {
    //   if (!this.currentSession) {
    //     return;
    //   }
    //   this.currentSession.refer(this.phone);
    // },
    // sendDTMF() {
    //   if (!this.currentSession) {
    //     return;
    //   }
    //   this.currentSession.sendDTMF(this.phone);
    // },
    hangUp() {
      this.currentSession.terminate();
      JsSIP.Utils.closeMediaStream(this.localClonedStream);
      this.currentSession = null;
    },
    // mute() {
    //   if (!this.currentSession) {
    //     return;
    //   }
    //   if (this.currentSession.isMuted().audio) {
    //     this.currentSession.unmute({audio: true});
    //     this.muted = false;
    //   } else {
    //     this.currentSession.mute({audio: true});
    //     this.muted = true;
    //   }
    // },
    hold() {
      if (!this.currentSession) {
        return;
      }
      //   // важно isOnHold содержит инфу об обоих абонентах (кто =поставил= звонок на холд): нас интересует локальный пир
      //   if (this.currentSession.isOnHold().local) {
      //     this.currentSession.unhold();
      //     this.onHold = false;
      //   } else {
      //     this.currentSession.hold();
      //     this.onHold = true;
      //   }
      // },
      // ХЭНДЛЕРЫ КНОПОК закончились
      // утилиты
      // playSound(soundName, loop = false) {
      //   const soundsControl = this.mediaControls.sounds;
      //   soundsControl.pause();
      //   soundsControl.currentTime = 0.0;
      //   soundsControl.src = `/sounds/${soundName}`;
      //   soundsControl.loop = loop;
      //   soundsControl.play();
      // },
      // stopSound() {
      //   const soundsControl = this.mediaControls.sounds;
      //
      //   soundsControl.pause();
      //   soundsControl.currentTime = 0.0;
      // },
    }
  }
};
</script>

<style></style>
