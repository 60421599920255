<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        scrollable
        width="100%"
        :persistent="getPersistentCreateClaimDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card tile background-color="teal darken-3" show-arrows>
          <v-toolbar dark flat color="primary" max-height="117">
            <v-btn icon dark @click="closeEditSettings" class="mt-13">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="mt-13">Настройки пользователя</v-toolbar-title>
          </v-toolbar>
          <v-sheet>
            <v-tabs dark background-color="dark primary" show-arrows>
              <v-tabs-slider color="#fe81a1"></v-tabs-slider>
              <v-tab>
                <span style="font-weight: bold">Профиль</span>
              </v-tab>
              <v-tab>
                <span style="font-weight: bold">Создать пользователя</span>
              </v-tab>
              <v-tab>
                <span style="font-weight: bold">Пароль</span>
              </v-tab>
              <v-tab>
                <span style="font-weight: bold">Телеграм</span>
              </v-tab>
              <v-tab>
                <span style="font-weight: bold">Экспорт</span>
              </v-tab>

              <v-tab-item class="main_table">
                <v-card flat width="900">
                  <v-card-text>
                    <v-form v-model="valid">
                      <v-row>
                        <v-col sm="4">
                          <v-text-field v-model="currentUser.last_name" label="Фамилия">
                          </v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field v-model="currentUser.first_name" label="Имя">
                          </v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field v-model="currentUser.patronymic" label="Отчество">
                          </v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field
                            v-mask="'+7 (###) ###-##-##'"
                            v-model="currentUser.phone"
                            :label="labelPhone"
                            @click="clickPhone"
                            @change="changes = true"
                            @keyup.enter="changeClaim"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="4">
                          <v-text-field v-model="currentUser.email" label="email"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card-actions style="padding: 0; justify-content: end;">
                            <v-flex
                              absolute
                              class="ml-2 mb-7"
                              height="calc(100% - 10px)"
                              v-if="getSavingSuccessful"
                              style="color:#66BB6A"
                            >
                              <v-icon color="#66BB6A"> mdi-checkbox-marked-circle </v-icon>
                              СОХРАНЕНО!
                            </v-flex>
                            <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                              <v-icon v-if="getSavingError" color="#EF5350">
                                mdi-close-circle
                              </v-icon>
                              ОШИБКА
                            </v-flex>
                            <v-btn
                              color="#fe81a1"
                              min-width="135"
                              tile
                              @click="dialog = false"
                              outlined
                            >
                              Закрыть
                            </v-btn>
                            <v-btn
                              color="#6292a8"
                              min-width="135"
                              outlined
                              tile
                              style="margin-left:34px"
                              @click="saveUser"
                            >
                              Сохранить
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item class="main_table">
                <create-user @closeTab="dialog = false" ref="createUser" class="main_table" />
              </v-tab-item>
              <v-tab-item class="main_table">
                <v-card flat width="900">
                  <v-card-text>
                    <v-form ref="changePassword">
                      <v-row style="justify-content: space-around;">
                        <v-col sm="5">
                          <v-text-field
                            v-model="changePassword.oldPassword"
                            label="Старый пароль"
                            type="password"
                            :append-icon="showButton ? 'mdi-check' : 'undefined'"
                          >
                            <v-icon slot="appendIcon" color="#6292a8"></v-icon>
                          </v-text-field>
                          <v-text-field
                            :rules="lengthPassword"
                            v-model="changePassword.newPassword"
                            label="Новый пароль"
                            type="password"
                          >
                          </v-text-field>
                          <v-text-field
                            :rules="[ruleConfirmPassword]"
                            v-model="changePassword.confirmPassword"
                            label="Подтверждение пароля"
                            type="password"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-card-actions style="padding: 0 0 0 44px; justify-content: center;">
                            <v-flex
                              absolute
                              class="ml-2 mb-7"
                              height="calc(100% - 10px)"
                              v-if="getSavingSuccessful"
                              style="color:#66BB6A"
                            >
                              <v-icon color="#66BB6A"> mdi-checkbox-marked-circle </v-icon>
                              СОХРАНЕНО!
                            </v-flex>
                            <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                              <v-icon v-if="getSavingError" color="#EF5350">
                                mdi-close-circle
                              </v-icon>
                              ОШИБКА
                            </v-flex>

                            <v-btn
                              color="#fe81a1"
                              min-width="135"
                              outlined
                              tile
                              @click="dialog = false"
                            >
                              Закрыть
                            </v-btn>
                            <v-btn
                              :disabled="!showButton"
                              color="#6292a8"
                              min-width="135"
                              outlined
                              tile
                              style="margin-left:34px"
                              @click="saveChangePassword"
                            >
                              Сохранить
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card>
                  <v-card-text style="justify-content: space-around; display: flex;">
                    <v-list three-line subheader>
                      <v-subheader>Основные настройки</v-subheader>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="sound"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Телеграм бот</v-list-item-title>
                          <v-list-item-subtitle>
                            Подключение телеграм бота для распределения заявок на исполнителей
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>

                  <v-card-actions style=" justify-content: center; padding: 16px 0 0 146px;">
                    <v-flex
                      absolute
                      v-if="getSavingSuccessful"
                      class="ml-2 mb-7"
                      height="calc(100% - 10px)"
                      style="color:#66BB6A"
                    >
                      <v-icon color="#66BB6A">
                        mdi-checkbox-marked-circle
                      </v-icon>

                      СОХРАНЕНО!
                    </v-flex>
                    <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                      <v-icon v-if="getSavingError" color="#EF5350">
                        mdi-close-circle
                      </v-icon>
                      ОШИБКА <span v-if="getErrorText">{{ getErrorText }}</span>
                    </v-flex>

                    <v-btn
                      color="#fe81a1"
                      min-width="135"
                      outlined
                      tile
                      style="margin:12px"
                      @click="dialog = false"
                    >
                      Закрыть
                    </v-btn>
                    <v-btn
                      color="#6292a8"
                      min-width="135"
                      outlined
                      tile
                      style="margin:12px"
                      @click="saveSetting"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text
                    style="justify-content: space-around; display: flex; padding: 0 0 0 280px;"
                  >
                    <v-list three-line subheader>
                      <v-subheader style="padding: 42px 0px 30px 18px;">Основные настройки</v-subheader>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="notifications"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            >Фильтр заявок с выгрузкой в файл Excel
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Подключения модуля фильтрации заявок с возможностью отбора и выгрузки в
                            файл Excel
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions style=" justify-content: center; padding: 0 0 0 370px;">
                    <v-flex
                      absolute
                      class="ml-2 mb-7"
                      height="calc(100% - 10px)"
                      v-if="getSavingSuccessful"
                      style="color:#66BB6A"
                    >
                      <v-icon color="#66BB6A">
                        mdi-checkbox-marked-circle
                      </v-icon>
                      СОХРАНЕНО!
                    </v-flex>
                    <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                      <v-icon v-if="getSavingError" color="#EF5350">
                        mdi-close-circle
                      </v-icon>
                      ОШИБКА <span v-if="getErrorText">{{ getErrorText }}</span>
                    </v-flex>

                    <v-btn
                      color="#fe81a1"
                      min-width="135"
                      outlined
                      tile
                      style="margin:12px"
                      @click="dialog = false"
                    >
                      Закрыть
                    </v-btn>
                    <v-btn
                      color="#6292a8"
                      min-width="135"
                      outlined
                      tile
                      style="margin:12px"
                      @click="saveSetting"
                    >
                      Сохранить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-sheet>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import config from "@/config";
import CreateUser from "@/components/CreateUser";

export default {
  components: {
    CreateUser
  },
  mounted() {
    this.$store.dispatch("getLocalityList");
    this.setCurrentUser();
  },
  props: {
    showCreateCompany: {
      type: Boolean
    }
  },
  data: () => ({
    oldPasswordCorrect: false,
    showIcon: false,
    clearPhone: "",
    changePassword: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    changedData: {},
    currentUser: {},
    phoneField: "",
    labelPhone: "Телефон +7 (XXX) XXX-XX-XX",
    valid: true,
    dialog: false,
    formData: {
      companyName: "",
      locality: Number
    },
    requiredRules: [v => !!v || "Это поле обязательное"],
    lengthPassword: [v => (v && v.length >= 6) || "Пароль должен содержать не менее 6 символов"],
    notifications: false,
    sound: true,
    widgets: false
  }),
  methods: {
    ruleConfirmPassword() {
      if (this.changePassword.newPassword !== this.changePassword.confirmPassword) {
        return "Пароли не совпадают";
      } else {
        return true;
      }
    },
    async checkPassword() {
      const res = await this.$store.dispatch("checkPassword", {
        login: this.currentUser.username,
        password: this.changePassword.oldPassword
      });
      console.log("check", this.currentUser.username, this.changePassword.oldPassword);
      return res;
    },
    async saveChangePassword() {
      if (this.$refs.changePassword.validate()) {
        const res = await axios.patch(
          `${config.BACKEND_URL}/user/${this.currentUser.id}/`,
          { password: this.changePassword.confirmPassword },
          { headers: { Authorization: this.$store.state.auth.accessToken } }
        );
        const response = res.data;
        console.log("response", response);
        if (res.status === 200) {
          this.$store.commit("updateCorrectPasswordFalse");
          this.$store.commit("showSavingSuccessful");
          setTimeout(() => {
            this.$store.commit("hideSavingSuccessful");
          }, 3000);
        }
      }
    },
    setCurrentUser() {
      setTimeout(() => {
        this.currentUser = { ...this.getterCurrentUser };
      }, 1000);
    },
    saveUser() {
      Object.keys(this.currentUser).forEach(prop => {
        if (this.currentUser[prop] !== this.getterCurrentUser[prop]) {
          this.changedData[prop] = this.currentUser[prop];
          console.log("change prop", prop);
        }
      });
      console.log("payload phone", this.changedData);
      try {
        if (this.changedData) {
          this.clearPhone = this.changedData.phone.replace(/[-() ]/g, "");
          this.clearPhone = this.clearPhone.replace("+", "");
          this.changedData.phone = this.clearPhone;
          console.log("changeData", this.changedData.phone);
          console.log("change clearPhone", this.clearPhone);
        }
      } catch (error) {
        console.log("change error.phone", error);
      }
      console.log("getterCurrentUser", this.getterCurrentUser);
      console.log("changeData", this.changedData);
      console.log("currentUser", this.currentUser);
      const payload = { id: this.currentUser.id, data: this.changedData };
      this.$store.dispatch("editUser", payload);
    },
    logout() {
      localStorage.removeItem("token");
      window.location.reload();
    },
    closeEditSettings() {
      this.dialog = false;
      // this.$refs.createUser.formReset()
    },
    saveSetting() {
      console.log("save");
    },
    clickPhone() {
      console.log("clickPhone");
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    },
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
    },
    getDialogEditSettings() {
      this.dialog = true;
    },
    saveCompany() {
      this.$store.dispatch("saveCompany", this.formData);
      this.$emit("close");
      console.log("formData saveCompany", this.formData);
      console.log(this.localityList);
      this.$store.commit("showSavingSuccessful");
      setTimeout(() => {
        this.$store.commit("hideSavingSuccessful");
        this.closeDialog();
      }, 1500);
    }
  },
  computed: {
    ...mapGetters([
      "localityList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "getterCurrentUser"
    ]),
    showButton() {
      return this.$store.state.correctPassword;
    }
  },

  watch: {
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
      },
      deep: true
    },
    changePassword: {
      handler: function() {
        console.log("сработал watch", this.changePassword.oldPassword);
        if (this.changePassword.oldPassword != "undefined") {
          if (this.changePassword.oldPassword.length > 5) {
            this.checkPassword();
          }
        }
      },
      deep: true
    },
    currentUser(newValue) {
      this.currentUser = newValue;
    }
  }
};
</script>
<style>
.main_table {
  display: flex;
  justify-content: space-around;
}
</style>
