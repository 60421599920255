<!-- Детальная информация в модальном окне при клике на одну из заявок находящийся в общей таблице -->
<!-- xmlns:height="http://www.w3.org/1999/xhtml" -->
<template>
  <v-card class="card-wrapper" @keyup.esc="close" style="height:100vh">
    <template>
      <v-sheet>
        <v-card-title
          :class="{
            'header-emergency': localClaimDetail.emergency === true,
            'header-payable': localClaimDetail.payable === true
          }"
          class="header "
          style="padding:24px "
        >
          <v-row style="padding-left: 24px;font-size: 1.5rem!important;font-weight: 400; letter-spacing: normal!important;">
            <v-col style="max-width: 677px;">
              Заявка № {{ localClaimDetail.internal_id }} &emsp;&emsp; id -
              {{ localClaimDetail.id }} &emsp;&emsp;
              {{ localClaimDetail.created | momentDateTime }}
            </v-col>

            <transition name="fade">
              <v-col sm="2">
                <span v-if="emergencyHeader == true"> Аварийная </span>
                <span v-if="payableHeader == true"> Платная </span>
              </v-col>
            </transition>
          </v-row>
        </v-card-title>
      </v-sheet>
      <!-- <v-sheet color="#a6ced0" elevation="1" height="22">
        <div class="subheader">
          <v-col class="table_instruction"> л/с № {{ localClaimDetail.personal_account }} </v-col>
          <v-col class="table_instruction">
            {{ localClaimDetail.applicant_last_name }} {{ localClaimDetail.applicant_first_name }}
            {{ localClaimDetail.applicant_patronymic }}
          </v-col>
          <v-col class="table_instruction">
            {{ localClaimDetail.address }}
          </v-col>
        </div>
      </v-sheet> -->
      <v-card-text style="padding:0 34px 0   ">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col sm="12">
                <v-textarea
                  v-model="localClaimDetail.text"
                  :rows="1"
                  autofocus
                  class="body-1 mt-4 mb-4 "
                  hide-details
                  no-resize
                  outlined
                  row-height="20"
                  @input="changes = true"
                  v-on:keyup.enter="changeClaim"
                  v-on:keyup.esc="close"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4">
                <div :title="statusList.find(status => status.text === localClaimDetail.status)">
                  <v-select
                    v-model="localClaimDetail.status"
                    :items="statusList"
                    label="Статус заявки"
                    @change="changes = true"
                  ></v-select>
                </div>
              </v-col>
              <v-col sm="4">
                <v-select
                  v-model="localClaimDetail.claim_type"
                  :items="relativeClaimTypeList"
                  clearable
                  hide-details
                  label="Тип заявки"
                  @change="changeClaimType"
                />
              </v-col>
              <v-col sm="4">
                <div>
                  <v-select
                    v-model="localClaimDetail.executor"
                    :items="relativeExecutorList"
                    clearable
                    label="Исполнитель"
                    @change="changes = true"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localClaimDetail.personal_account"
                  readonly
                  label="Лицевой счет"
                >
                </v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field v-model="localClaimDetail.address" readonly label="Адрес">
                </v-text-field>
              </v-col>

              <v-col sm="4">
                <v-text-field
                  v-model="phoneField"
                  v-mask="'+#(###) ###-##-##'"
                  placeholder="+7"
                  label="Телефон"
                  @change="changes = true"
                  @keyup.enter="changeClaim"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localClaimDetail.company_name"
                  disabled
                  label="Компания"
                  @change="changes = true"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localClaimDetail.applicant_individual"
                  color="#000"
                  disabled
                  label="Заявитель"
                  @change="changes = true"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field v-model="userName" disabled label="Назначил" readonly />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentJs"
                      clearable
                      label="Дата выполнения"
                      readonly
                      v-bind="attrs"
                      @change="changes = true"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    active-picker="DATE"
                    no-title
                    scrollable
                    @change="changes = true"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="3">
                <v-list-item style="padding:0; margin: 16px 0 0 0;">
                  <v-list-item-action style=" margin-right: 10px ">
                    <v-checkbox
                      v-model="localClaimDetail.emergency"
                      @change="emergencyCheck"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Аварийная</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col sm="2">
                <v-list-item class="mt-4" style="padding:0; width: 100px;">
                  <v-list-item-action style=" margin-right: 10px ">
                    <v-checkbox
                      v-model="localClaimDetail.payable"
                      @change="payableCheck"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Платная</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-icon style="margin:6px 8px 0 50px;">mdi-content-copy</v-icon>

              <a
                style="margin: 42px 0 0 0; text-decoration: underline; font-size: 1rem;"
                type="button"
                v-clipboard:copy="
                  phoneField +
                    localClaimDetail.address +
                    localClaimDetail.personal_account +
                    localClaimDetail.internal_id +
                    localClaimDetail.id +
                    localClaimDetail.text
                "
                v-clipboard:success="doCopy"
              >
                Копировать
              </a>
            </v-row>
            <v-row>
              <v-col>
                <div v-bar class="vuebar-element">
                  <!-- el1 -->

                  <!-- el2 -->
                  <table style="width:98%" class="">
                    <tr v-for="item in getClaimLog" :key="item.id">
                      <div v-if="item.message.length > 0">
                        <td class="pr-2 ">{{ item.changed | momentDate }}</td>
                        <td class="pr-2">{{ item.changed | momentHour }}</td>
                        <td class="pr-2 table_instruction" style="max-width: 120px; width: 120px;">
                          {{ item.pointer != null ? item.pointer : item.executor }}
                          <!--          <b>{{ getterCurrentUserInitials }}</b>-->
                        </td>
                        <td style="max-width: 540px; width: 540px; white-space: normal;">
                          {{ item.message }}
                          <span v-if="item.message.indexOf('Фото') != -1">
                            <v-icon small @click="getPhotoDialog">mdi-camera-image </v-icon>
                          </span>
                        </td>
                      </div>
                    </tr>
                  </table>

                  <!-- dragger will be automatically added here -->
                </div>
              </v-col>
            </v-row>
            <v-row class="textarea">
              <v-col>
                <v-textarea
                  v-model="message"
                  :rows="1"
                  hint="Нажмите ctrl+enter, чтобы отправить комментарий"
                  no-resize
                  tile
                  outlined
                  v-on:keyup.enter.ctrl="onEnter"
                  placeholder="Оставьте комментарий к заявке"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="display: flex; justify-content: end;" sm="12">
                <v-btn color="#6292a8" min-width="135" tile outlined @click="onEnter">
                  Отправить
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <transition name="fade">
        <div v-show="changes">
          <v-sheet>
            <v-card-actions class="cardAction">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex v-if="getSavingSuccessful" style="color:#66BB6A; margin: 10px;">
                СОХРАНЕНО!
              </v-flex>
              <v-flex v-if="getSavingError" style="color: #EF5350; margin: 10px;">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="errorText">({{ errorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                style="margin: 8px 7px 8px 0px;"
                color="#fe81a1"
                min-width="135"
                tile
                outlined
                @click="close"
                >Закрыть
              </v-btn>
              <v-btn
                style="margin:0 22px "
                color="#6292a8"
                min-width="135"
                outlined
                tile
                @click="changeClaim"
                >Сохранить
              </v-btn>
            </v-card-actions>
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialogImageResult" max-width="60%">
                  <v-card>
                    <v-card-title class="text-h5">
                      Фото результата выполненной заявки №{{ localClaimDetail.id }}
                    </v-card-title>
                    <v-card-text class="card-image"
                      ><img :src="imagePhotoReport" auto height: width="100%" alt="">
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="green darken-1"
                        min-width="135"
                        outlined
                        tile
                        @click="dialogImageResult = false"
                      >
                        Закрыть
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";
import { VueMaskDirective } from "v-mask";
import axios from "axios";
import config from "@/config";

Vue.directive("v-mask", VueMaskDirective);

export default {
  mounted() {
    this.$store.dispatch("getExecutorList");
    this.$store.dispatch("getRelativeExecutorList", this.executorPayload);
    this.$store.dispatch("getRelativeClaimTypeList", this.localClaimDetail.company);
    this.emergencyHeader = this.localClaimDetail.emergency;
    this.payableHeader = this.localClaimDetail.payable;
    if (this.localClaimDetail.contact_phone) {
      this.phoneField = this.localClaimDetail.contact_phone;
    }
    console.log("this.localClaimDetail", this.localClaimDetail);
    this.date = this.localClaimDetail.planned_date;
    this.pointerName();
  },
  props: {
    baseClaimDetail: {
      type: Object
    }
  },
  data: () => ({
    date: "",
    chatId: "",
    dialogImageResult: false,
    dialog: false,
    phoneField: "",
    valid: true,
    planned_date: "",
    loading: false,
    select: null,
    benched: "",
    changes: false,
    message: "",
    savingSuccessful: false,
    executorPayload: {},
    statusValueResult: "",
    menu: false,
    emergencyHeader: "false",
    payableHeader: "false",
    userList: [],
    userName: "",
    dateCreateTitle: "",
    imageResult: "",
    errorText: "",
    statusList: [
      { value: 1, text: "Принята" },
      { value: 2, text: "Исполнение" },
      { value: 3, text: "Закрыта" },
      { value: 4, text: "Отменена" }
    ],
    marker: true
  }),
  beforeMount() {
    this.localClaimDetail = { ...this.baseClaimDetail };
    this.imageResult = this.localClaimDetail.claim_result_image;
    this.executorPayload.company = this.localClaimDetail.company;
    this.executorPayload.claim_type = this.localClaimDetail.claim_type;
    this.$store.dispatch("getClaimTypeList");
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getPropertyOwners");
    this.$store.dispatch("getClaimLog", this.localClaimDetail.id);
    this.$store.dispatch("getUsersList");
    this.getUser();
  },
  methods: {
    doCopy: function() {
      this.$copyText(
        `
        Номер заявки: ${this.localClaimDetail.internal_id} 
        id: ${this.localClaimDetail.id}
        Адрес: ${this.localClaimDetail.address}
        Телефон клиента: ${this.phoneField} 
        Описание заявки: ${this.localClaimDetail.text}
        `
      ).then(
        function() {
          console.log("Successfully copied");
        },
        function(error) {
          console.log(error);
          console.log("Cannot copy, copy failed");
        }
      );
    },

    getPhotoDialog() {
      setTimeout(() => {
        this.dialogImageResult = true;
      }, 200);
    },
    close() {
      this.$emit("close");
      this.$store.commit("unlockedClaimDetail");
    },
    pointerName() {
      this.$store.state.usersList.forEach(user => {
        console.log("user*", user);
        if (user.id == this.localClaimDetail.pointer) {
          console.log("user", user.get_full_name);
          this.userName = user.get_full_name;
        }
      });
    },
    getUser() {
      this.$store.state.usersList.forEach(user => {
        if (user.id == this.localClaimDetail.pointer) console.log("user", user.get_full_name);
      });
    },
    changeClaim() {
      console.log("local", this.localClaimDetail);
      console.log("local date", this.date);
      console.log("baseClaimDetail ", this.baseClaimDetail);
      if (this.phoneField) {
        this.clearPhone = this.phoneField.replace(/[-() ]/g, "");
        this.clearPhone = this.clearPhone.replace("+", "");
        this.localClaimDetail.contact_phone = this.clearPhone;
      }
      if (this.date != null) {
        this.localClaimDetail.planned_date = `${this.date}T00:00`;
      }
      this.$emit("update:details", this.localClaimDetail);
      this.$store.commit("unlockedClaimDetail");
    },
    getStatus(statusValue) {
      this.statusList.forEach(status => {
        if (status["value"] === statusValue) {
          this.statusValueResult = status["text"];
        }
      });
      return this.statusValueResult;
    },
    changeClaimType() {
      this.executorPayload.claim_type = this.localClaimDetail.claim_type;
      this.$store.dispatch("getRelativeExecutorList", this.executorPayload);
      console.log("executorPayload", this.executorPayload);
      this.changes = true;
    },
    savePicker() {
      this.$refs.menu.save(this.localClaimDetail.planned_date);
      this.changes = true;
    },
    clearMessage() {
      this.message = "";
    },
    sendMessageTelegram(chatId, text) {
      const token = "bot5261851830:AAF9v6oBcTddOORqAtMFMFKbVJaOy0JUU0Y";
      // const chatId = 127037610
      // const text = 'Привет'
      axios.post(`https://api.telegram.org/${token}/sendMessage`, { chat_id: chatId, text: text });
    },
    onEnter() {
      console.log("onEnter", this.message);
      this.$store.dispatch("getExecutorDetail", this.localClaimDetail.executor).then(response => {
        console.log("response getExecutorDetail in claimDetail", response);
        this.chatId = response.chat_id;
      });
      if (this.message) {
        console.log("setTimeout this.message", this.message);
        const payload = { message: this.message, data: this.localClaimDetail };
        this.$store.dispatch("addClaimLog", payload);
        this.$store.dispatch("getClaimLog", this.localClaimDetail.id);
        setTimeout(() => {
          try {
            const user = `${this.$store.state.currentUser.last_name} ${this.$store.state.currentUser.first_name} ${this.$store.state.currentUser.patronymic}`;
            const text = `Комментарий к заявке № ${this.localClaimDetail.id}: ${this.message}\n${user}`;
            this.sendMessageTelegram(this.chatId, text);
            this.clearMessage();
          } catch (e) {
            console.log(`error - ${e}`);
          }
        }, 300);

        console.log("localClaimDetail.executor", this.localClaimDetail.executor);
      }
    },
    emergencyCheck(newValue) {
      this.emergencyHeader = this.localClaimDetail.emergency;
      this.changes = true;
      if (newValue === true) {
        this.localClaimDetail.payable = false;
        this.payableHeader = false;
      }
    },
    payableCheck(newValue) {
      this.payableHeader = this.localClaimDetail.payable;
      this.changes = true;
      if (newValue === true) {
        this.localClaimDetail.emergency = false;
        this.emergencyHeader = false;
      }
    },
    formatDate(date) {
      console.log("DATE2", this.localClaimDetail.planned_date);
      console.log("DATE", date);
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    }
  },
  filters: {
    momentHour: function(date) {
      return moment(date).format("HH:mm");
    },
    momentDate: function(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    momentDateTime: function(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    }
  },
  computed: {
    ...mapGetters([
      "getSavingSuccessful",
      "getClaimTypeList",
      "companyList",
      "getApplicantList",
      "getUsersList",
      "getClaimLogList",
      "getSavingError",
      "getErrorText",
      "getterCurrentUser"
    ]),
    imagePhotoReport() {
      const path = this.imageResult.split("/").slice(3);
      const relativePath = path.join("/");
      const sliceBackendUrl = config.BACKEND_URL.split("/")
        .slice(0, 3)
        .join("/");
      const result = `${sliceBackendUrl}/${relativePath}`;
      return result;
    },
    getComputedExecutor: function(executor) {
      this.$store.dispatch("getExecutorDetail", executor);
      const executorDetail = this.$store.state.executorDetail;
      return executorDetail;
    },

    executorList() {
      const executorList = this.$store.getters.getExecutorList;
      console.log("computed executorList", executorList);
      return executorList;
    },
    relativeExecutorList() {
      const relativeExecutorList = this.$store.getters.getRelativeExecutorList;
      console.log("computed relativeExecutorList", relativeExecutorList);
      return relativeExecutorList;
    },
    relativeClaimTypeList() {
      const relativeClaimType = this.$store.getters.getRelativeClaimTypeList;
      return relativeClaimType;
    },
    getClaimLog() {
      const logLists = this.$store.state.claimLogList;
      logLists.forEach(element => {
        if (element.status) {
          this.statusList.forEach(status => {
            if (status["value"] === element.status) {
              element["status"] = status["text"];
            }
          });
        }
      });
      console.log(typeof logLists);
      console.log("logLists", logLists);

      return logLists;
    },
    computedDateFormattedMomentJs() {
      return this.formatDate(this.date);
    }
  },
  watch: {
    changes(newValue) {
      console.log("w lockedClaimDetail");
      if (newValue === true) {
        this.$store.commit("lockedClaimDetail");
      }
    }
  }
};
</script>
<style lang="scss">
.table_instruction {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
  text-align: left;
  padding: 0;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vuebar-element {
  height: 100px;
  width: 100%;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.card-image {
  width: 300px; /* Ширина изображения */
  height: 300px; /* Высота изображения */
  background-repeat: no-repeat; /* Не повторяем */
  background-size: cover; /* Размер фона */
  background-position: center; /* Позиция картинки по центру */
}

.textarea {
  width: 648px;
  height: 10px;
}
.cardAction {
  margin: 10px 16px 10px 42px;
}
</style>
