<!-- Форма создания исполнителя  -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" :persistent="getPersistentCreateClaimDialog" width="50%">
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px ">
                <v-col>
                  <span class="headline">Создать исполнителя</span>
                </v-col>
              </v-card-title>
              <v-sheet color="#a6ced0">
                <v-layout>
                  <v-flex sm="3" style="color: #636162">
                    <span v-if="formData.applicant">
                      {{ $store.state.houseDetail.street }}
                      {{ $store.state.houseDetail.number }}
                      кв. {{ localPropertyOwnerDetail.placement }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-sheet>
              <v-card-text style="padding:24px ">
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col :cols="otherField ? 5 : 6" :sm="otherField ? 5 : 6">
                        <v-autocomplete
                          v-model="formData.companies"
                          :items="companyList"
                          :rules="requiredRules"
                          label="Компания"
                          multiple
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col :cols="otherField ? 3 : 6" :sm="otherField ? 3 : 6">
                        <v-select
                          v-model="formData.specialization"
                          :items="claimTypeList"
                          :rules="requiredRules"
                          label="Специализация"
                        ></v-select>
                      </v-col>
                      <v-col v-if="otherField" sm="4">
                        <v-text-field
                          v-model="otherSpecialization"
                          label="Введите название"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="formData.employee.last_name"
                          :rules="requiredRules"
                          label="Фамилия"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="formData.employee.first_name"
                          :rules="requiredRules"
                          label="Имя"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col :sm="4">
                        <v-text-field
                          v-model="formData.employee.patronymic"
                          label="Отчество"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="formData.phone"
                          v-mask="'+7 (###) ###-##-##'"
                          :label="labelPhone"
                          :rules="lengthPhone"
                          required
                          @click="clickPhone"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions class="justify-end" style="padding:24px; justify-content: end;">
                <v-flex
                  v-if="getSavingSuccessful"
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>

                  СОХРАНЕНО!
                </v-flex>
                <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="errorText">{{ errorText }}</span>
                </v-flex>

                <v-btn
                  style="margin:12px "
                  min-width="135"
                  color="#fe81a1"
                  tile
                  outlined
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  style="margin:12px "
                  min-width="135"
                  color="#6292a8"
                  tile
                  outlined
                  @click="saveExecutor"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { VueMaskDirective } from "v-mask";

Vue.directive("mask", VueMaskDirective);

export default {
  async mounted() {
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getClaimTypeList");
    // this.$store.dispatch("getEmployeeList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateExecutor: {
      type: Boolean
    }
  },
  data: () => ({
    currentClaimTypeId: null,
    claimTypeInList: false,
    dialog: false,
    otherField: false,
    otherSpecialization: "",
    errorText: "",
    formData: {
      companies: [],
      specialization: null,
      phone: "",
      employee: { first_name: "", last_name: "", patronymic: "" }
    },
    labelPhone: "Телефон +7 (XXX) XXX-XX-XX",
    claimTypeList: [
      { value: "Слесарь-сантехник", text: "Слесарь-сантехник" },
      { value: "Электрик", text: "Электрик" },
      { value: "Инженер", text: "Инженер" },
      { value: "Диспетчер", text: "Диспетчер" },
      { value: "other", text: "Другое" }
    ],
    requiredRules: [v => !!v || "Это поле обязательное"],
    lengthPhone: [
      v => !!v || "Это поле обязательное",
      v => (v && v.length > 17) || "Введите корректно номер"
    ]
  }),
  methods: {
    getDialogCreateExecutor() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100),
        (this.formData.phone = "");
    },

    saveExecutor() {
      if (this.$refs.form.validate()) {
        console.log("formData.companies", this.formData.companies);
        this.$store.dispatch("getClaimTypeListCompany", this.formData.companies);
        setTimeout(() => {
          console.log(this.$store.state.claimTypeList);
          const resList = [];
          this.$store.state.claimTypeList.forEach(claimType => {
            resList.push(claimType.name);
          });
          if (this.formData.specialization === "other") {
            this.claimTypeInList = resList.includes(this.otherSpecialization);
          } else {
            this.claimTypeInList = resList.includes(this.formData.specialization);
            console.log("test", resList, this.formData.specialization);
            console.log("res", this.claimTypeInList);
          }
          if (this.claimTypeInList) {
            console.log("we already have");
            if (this.otherSpecialization !== "") {
              console.log("we already have2");
              const payload = {};
              payload["companyId"] = this.formData.companies[0];
              payload["name"] = this.otherSpecialization;
              console.log("payload1", payload);
              this.$store.dispatch("getCurrentClaimType", payload).then(currentClaimType => {
                this.currentClaimTypeId = currentClaimType[0].id;
              });
              setTimeout(() => {
                this.formData.specialization = this.currentClaimTypeId;
                this.$store.dispatch("saveExecutor", this.formData);
                console.log("formData", this.formData);
                this.$store.commit("showSavingSuccessful");
                setTimeout(() => {
                  this.$store.commit("hideSavingSuccessful");
                  this.closeDialog();
                }, 1500);
              }, 1500);
            } else {
              console.log("we already have3");
              const payload = {};
              payload["companyId"] = this.formData.companies[0];
              payload["name"] = this.formData.specialization;
              this.$store.dispatch("getCurrentClaimType", payload).then(currentClaimType => {
                this.currentClaimTypeId = currentClaimType[0].id;
                console.log("currentClaimType", currentClaimType);
                console.log("currentClaimTypeId", this.currentClaimTypeId);
                this.formData.specialization = this.currentClaimTypeId;
                this.$store.dispatch("saveExecutor", this.formData);
              });
              setTimeout(() => {
                this.closeDialog();
              }, 1500);
            }
          } else {
            console.log("сработал else");
            if (this.otherSpecialization !== "") {
              console.log("!!!1", this.formData.companies[0]);
              this.$store
                .dispatch("saveClaimType", {
                  company: this.formData.companies[0],
                  name: this.otherSpecialization
                })
                .then(result => {
                  this.currentClaimTypeId = result.id;
                  console.log("currentClaimTypeId", this.currentClaimTypeId);
                  console.log("!!!2", this.formData.companies[0]);
                });
              setTimeout(() => {
                this.formData.specialization = this.currentClaimTypeId;
                this.$store.dispatch("saveExecutor", this.formData);
                this.$store.commit("showSavingSuccessful");
                setTimeout(() => {
                  this.closeDialog();
                }, 1500);
              }, 1500);
            } else {
              this.$store
                .dispatch("saveClaimType", {
                  company: this.formData.companies[0],
                  name: this.formData.specialization
                })
                .then(result => {
                  this.currentClaimTypeId = result.id;
                  console.log("currentClaimTypeId", this.currentClaimTypeId);
                });
              setTimeout(() => {
                this.formData.specialization = this.currentClaimTypeId;
                this.$store.dispatch("saveExecutor", this.formData);
              }, 500);
              setTimeout(() => {
                this.$store.state.savingError = false;
                this.closeDialog();
              }, 1500);
            }
          }
        }, 200);
      }
    },

    clickPhone() {
      console.log("clickPhone");
      // При повторном вводе номер телефона сбрасывается. Из за плейсхолдера ниже
      // this.formData.phone = "+7 (9";
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    }
  },
  computed: {
    ...mapGetters([
      "companyList",
      "getClaimTypeList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError"
    ])

    // companyList(){
    //   const compList = this.$store.getters.companyList
    //   return compList
    // },
    // claimTypeInList(){
    //   const claimTypeInList = this.$store.getters.claimTypeInList
    //   console.log('computed claimTypeList', claimTypeInList)
    //   return claimTypeInList
    // }
  },
  watch: {
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
        console.log("сработал commit canNotBeClosedCreateClaimDialog");
        // TODO Проверить. Возможность упростить  this.otherField = this.formData.specialization == 'other';
        // if (this.formData.specialization === "other") {
        //   this.otherField = true;
        // } else {
        //   this.otherField = false;
        // }
        this.otherField = this.formData.specialization == "other";
      },
      deep: true
    }
  }
};
</script>
