<!-- Модальное окно Создания специализации  -->
<template>
  <v-dialog v-model="dialog" width="50%">
    <v-layout>
      <v-flex>
        <v-card>
          <v-card-title class=" header" style="padding:24px ">
            <v-col>
              <span class="headline">Создать специализацию</span>
            </v-col>
          </v-card-title>

          <v-card-text style="padding:24px ">
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col sm="6">
                    <v-select
                      v-model="formData.company"
                      :items="companyList"
                      :rules="requiredRules"
                      label="Компания"
                      required
                    ></v-select>
                  </v-col>
                  <v-col sm="6">
                    <v-text-field
                      v-model="formData.name"
                      :rules="requiredRules"
                      label="Специализация"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions style="justify-content: end; padding:24px ">
            <v-flex
              absolute
              class="ml-2 mb-7"
              height="calc(100% - 10px)"
              v-if="getSavingSuccessful"
              style="color:#66BB6A"
            >
              <v-icon color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>

              СОХРАНЕНО!
            </v-flex>
            <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
              <v-icon v-if="getSavingError" color="#EF5350">
                mdi-close-circle
              </v-icon>
              ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn
              color="#fe81a1"
              min-width="135"
              style="margin:12px"
              @click="closeDialog"
              outlined
              tile
            >
              Закрыть
            </v-btn>
            <v-btn
              color="#6292a8"
              min-width="135"
              style="margin:12px"
              @click="saveClaimType"
              outlined
              tile
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    formData: {
      company: "",
      name: ""
    },
    requiredRules: [v => !!v || "Это поле обязательное"]
  }),
  methods: {
    getDialogCreateClaimType() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
    },
    saveClaimType() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("saveClaimType", this.formData);
        this.$store.commit("showSavingSuccessful");
        setTimeout(() => {
          this.$store.commit("hideSavingSuccessful");
          this.$store.dispatch("getClaimTypeList");
          this.dialog = false;
        }, 1500);
      }
    }
  },
  computed: {
    ...mapGetters([
      "companyList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError"
    ])
  },
  watch: {
    handler: function() {
      this.$store.commit("canNotBeClosedCreateClaimDialog");
    }
  }
};
</script>

<style></style>
