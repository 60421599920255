<template>
  <div>
    <edit-settings ref="childEditSettingsComponent" :show-edit-settings="showEditSettings" />
    <create-company ref="childCreateCompanyComponent" :show-create-company="showCreateCompany" />
    <create-executor
      ref="childCreateExecutorComponent"
      :show-create-executor="showedDialogs.showCreateExecutor"
    />
    <create-locality
      ref="childCreateLocalityComponent"
      :show-create-locality="showCreateLocality"
    />
    <create-home ref="childCreateHomeComponent" :show-create-home="showCreateHome" />
    <create-owners
      ref="childCreateOwnerComponent"
      :show-create-owners="showCreateOwners"
      @close="closeDialog"
    />
    <upload-owners ref="childUploadOwnersComponent" :show-upload-owners="showUploadOwners" />
    <create-claim ref="childComponent" :show-create-claim="showCreateClaim" />
    <edit-company-list ref="childEditCompanyComponent" :show-edit-company="showEditCompany" />
    <edit-owner-list ref="childEditOwnerComponent" :show-edit-owners="showEditOwners" />
    <edit-executor-list ref="childEditExecutorComponent" :show-edit-executor="showEditExecutor" />
    <create-claim-type
      ref="childCreateClaimTypeComponent"
      :show-create-claim-type="showCreateClaimType"
    />
    <edit-claim-type-list
      ref="childEditClaimTypeComponent"
      :show-edit-claim-type-list="showEditClaimTypeList"
    />

    <edit-locality-list ref="childEditLocalityComponent" :show-edit-locality="showEditLocality" />
    <edit-home-list ref="childEditHomeComponent" :show-edit-home="showEditHome" />
    <edit-user-list ref="childEditUsersComponent" :show-edit-users-list="showEditUsers" />
  </div>
</template>

<script>
import CreateCompany from "@/components/CreateCompany";
import CreateExecutor from "@/components/CreateExecutor";
import CreateLocality from "@/components/CreateLocality";
import CreateHome from "@/components/CreateHome";
import CreateOwners from "@/components/CreateOwners";
import UploadOwners from "@/components/UploadOwners";
import CreateClaim from "@/components/CreateClaim";
import EditCompanyList from "@/components/EditCompanyList";
import EditOwnerList from "@/components/EditOwnerList";
import EditExecutorList from "@/components/EditExecutorList";
import EditLocalityList from "@/components/EditLocalityList";
import EditHomeList from "@/components/EditHomeList";
import EditSettings from "@/components/EditSettings";
import CreateClaimType from "@/components/CreateClaimType";
import EditUserList from "@/components/EditUserList";
import EditClaimTypeList from "@/components/EditClaimTypeList";

export default {
  components: {
    EditClaimTypeList,
    CreateClaimType,
    EditExecutorList,
    EditCompanyList,
    EditOwnerList,
    EditLocalityList,
    CreateExecutor,
    CreateCompany,
    CreateLocality,
    CreateHome,
    CreateOwners,
    UploadOwners,
    CreateClaim,
    EditHomeList,
    EditSettings,
    EditUserList
  },
  data: () => ({
    createMenu: [
      { slug: "showUploadOwners", title: "Загрузить собственников" },
      { slug: "showCreateOwners", title: "Создать собственника" },
      { slug: "showCreateCompany", title: "Создать компанию" },
      { slug: "showCreateExecutor", title: "Создать исполнителя" },
      { slug: "showCreateLocality", title: "Создать населенный пункт" },
      { slug: "showCreateHome", title: "Создать дом" },
      { slug: "", title: "Сформировать отчет" }
    ],
    editMenu: [
      { slug: "showEditOwners", title: "Редактировать собственников" },
      { slug: "showEditCompany", title: "Редактировать компании" },
      { slug: "showEditExecutor", title: "Редактировать исполнителей" },
      { slug: "showEditLocality", title: "Редактировать населенные пункты" },
      { slug: "showEditHome", title: "Редактировать дома" }
    ],
    offset: true,

    showedDialogs: {
      showCreateOwners: false,
      showCreateCompany: false,
      showCreateExecutor: false,
      showCreateLocality: false,
      showCreateHome: false,
      showUploadOwners: false,
      showEditOwners: false,
      showEditCompany: false,
      showEditExecutor: false,
      showCreateClaimType: false,
      showEditHome: false,
      showEditLocality: false
    },
    showEditSettings: true,
    showCreateClaim: true,
    showCreateOwners: true,
    showEditOwners: true,
    showUploadOwners: true,
    showEditExecutor: true,
    showEditClaimTypeList: true,
    showCreateHome: true,
    showEditHome: true,
    showCreateCompany: true,
    showEditCompany: true,
    showCreateLocality: true,
    showEditLocality: true,
    showCreateClaimType: true,
    showEditUsers: true
  }),

  methods: {
    openEditSettingsFunction() {
      this.$refs.childEditSettingsComponent.getDialogEditSettings();
    },
    openEditLocalityFunction() {
      this.$refs.childEditLocalityComponent.getDialogEditLocality();
    },
    openCreateLocalityFunction() {
      this.$refs.childCreateLocalityComponent.getDialogCreateLocality();
    },
    openEditCompanyFunction() {
      this.$refs.childEditCompanyComponent.getDialogEditCompany();
    },
    openCreateCompanyFunction() {
      this.$refs.childCreateCompanyComponent.getDialogCreateCompany();
    },
    openEditHomeFunction() {
      this.$refs.childEditHomeComponent.getDialogEditHome();
    },
    openCreateHomeFunction() {
      this.$refs.childCreateHomeComponent.getDialogCreateHome();
    },
    openEditExecutorFunction() {
      this.$refs.childEditExecutorComponent.getDialogEditExecutorList();
    },
    openCreateClaimTypeFunction() {
      this.$refs.childCreateClaimTypeComponent.getDialogCreateClaimType();
    },
    openCreateExecutorFunction() {
      this.$refs.childCreateExecutorComponent.getDialogCreateExecutor();
    },
    uploadOwnersFunction() {
      this.$refs.childUploadOwnersComponent.getDialogUploadOwners();
    },
    editOwnerListFunction() {
      this.$refs.childEditOwnerComponent.getDialogEditOwnerList();
    },
    openCreateOwnerFunction() {
      this.$refs.childCreateOwnerComponent.getDialogCreateOwner();
    },
    openCreateClaimFunction() {
      this.$refs.childComponent.getDialogWindow();
    },
    openEditUsersFunction() {
      this.$refs.childEditUsersComponent.getDialogEditUsers();
    },
    openEditClaimTypeFunction() {
      this.$refs.childEditClaimTypeComponent.getDialogEditClaimTypeList();
    },
    MenuAction(index) {
      this.showedDialogs[index] = true;
    },
    closeDialog() {
      this.showedDialogs.showCreateExecutor = false;
      this.showedDialogs.showCreateCompany = false;
      this.showedDialogs.showCreateLocality = false;
      this.showedDialogs.showCreateHome = false;
      this.showedDialogs.showCreateOwners = false;
      this.showedDialogs.showUploadOwners = false;
      this.showedDialogs.showEditOwners = false;
      this.showedDialogs.showEditCompany = false;
      this.showedDialogs.showEditExecutor = false;
      this.showedDialogs.showEditLocality = false;
      this.showedDialogs.showEditHome = false;
      this.showEditHome = false;
    }
    // openCreateClaim(){
    //   this.openCreateClaimFunction()
    // }
  }
};
</script>

<style scoped>
.v-system-bar {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  min-width: 100%;
}
</style>
