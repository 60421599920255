<!-- TODO Компонент не используется -->
<template>
  <v-card height="100%" class="card-wrapper" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class="header">
          <v-row class=" py-3 pt-3">
            <v-col cols="12" sm="12">
              Редактировать населенный пункт
            </v-col>
          </v-row>
        </v-card-title>
      </v-sheet>

      <v-card-text class="pa-1 body-1">
        <v-form class="compact-form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  class="mr-4"
                  v-model="localLocalityDetail.prefix"
                  @change="changes = true"
                  label="Префикс"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  class="mr-4 ml-2"
                  v-model="localLocalityDetail.name"
                  @change="changes = true"
                  label="Населенный пункт"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-spacer></v-spacer>
      </v-card-text>
      <v-row>
        <v-col> </v-col>
      </v-row>
      <transition name="fade">
        <div>
          <v-sheet class="pb-10">
            <v-card-actions class="pa-4 mb-10 ml-3 pb-30">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex class="ml-2" v-if="getSavingSuccessful" style="color:#66BB6A">
                СОХРАНЕНО!
              </v-flex>
              <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                color="#fe81a1"
                @click="closeEditCompanyDetail"
                outlined
                min-width="135"
                tile
                style="margin:12px"
                >Закрыть
              </v-btn>
              <v-btn
                color="#6292a8"
                outlined
                tile
                style="margin:12px"
                min-width="135"
                @click="saveLocality"
                >Сохранить
              </v-btn>
            </v-card-actions>
            <template> </template>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    localityId: null
  },
  data() {
    return {
      baseLocalityDetail: {},
      localLocalityDetail: {},
      changedData: {},
      valid: true,
      changes: false
    };
  },
  created() {
    this.$store.dispatch("getLocalityList");
    this.baseLocalityDetail = { ...this.getDetailLocality };
    this.localLocalityDetail = { ...this.getDetailLocality };
  },
  methods: {
    closeEditCompanyDetail() {
      this.$emit("close");
    },
    saveLocality() {
      Object.keys(this.baseLocalityDetail).forEach(prop => {
        if (this.baseLocalityDetail[prop] !== this.localLocalityDetail[prop]) {
          this.changedData[prop] = this.localLocalityDetail[prop];
        }
      });
      console.log("changeData", this.changedData);
      this.$store.dispatch("changeLocality", {
        id: this.localityId,
        data: this.changedData
      });
      setTimeout(() => {
        this.$emit("close");
      }, 1500);
    }
  },
  computed: {
    ...mapGetters(["localityList", "getSavingSuccessful", "getSavingError"]),
    getDetailLocality() {
      return this.$store.state.detailLocality;
    }
  }
};
</script>
