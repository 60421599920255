<template>
  <div class="nav-bar">
    <!-- TODO expend ? -->
    <nav class="navbar navbar-edpand-lg navbar-light ba-white nav-1">
      <div class="container mw-o pd-3">
        <a class="navbar-brand" href="#">
          <img alt="" class="d-inline-block align-top" height="27" src="" width="" />
        </a>

        <button
          class="navbar-toggler"
          data-target="#navbarSupported"
          data-toggle="collapse"
          type="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item"></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { getVersion } from "@/plugins/AutoReload/utils.js";

export default {
  name: "Navbar",
  created() {
    this.frontVersion = getVersion();
    console.log("frontVersion", this.frontVersion);
  }
};
</script>

<style scoped>
a {
  color: #000;
}
</style>
