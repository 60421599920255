<!-- Модальное окно при нажатии на одного из собственника -->
<template>
  <v-card class="card-wrapper" height="100%" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class=" header" style="padding:24px ">
          <v-col>
            <span class="headline">Редактировать список собственников</span>
          </v-col>
        </v-card-title>
      </v-sheet>

      <v-card-text style="padding: 24px ;">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localOwnerDetail.personal_account"
                  label="Лицевой счет"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>

              <v-col sm="4">
                <v-text-field
                  v-model="localOwnerDetail.phone"
                  v-mask="'+7 (###) ###-##-##'"
                  label="Телефон"
                  @click="clickPhone"
                  @change="changes = true"
                ></v-text-field>
              </v-col>

              <v-col sm="4" v-if="localOwnerDetail.individual == null">
                <v-text-field
                  v-model="localOwnerDetail.entity.name"
                  label="Организация"
                  @change="changes = true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="localOwnerDetail.individual != null">
                <v-text-field
                  v-model="localOwnerDetail.individual.last_name"
                  label="Фамилия"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
              <v-col sm="4" v-if="localOwnerDetail.individual != null">
                <v-text-field
                  v-model="localOwnerDetail.individual.first_name"
                  label="Имя"
                  @change="changes = true"
                />
              </v-col>
              <v-col sm="4" v-if="localOwnerDetail.individual != null">
                <v-text-field
                  v-model="localOwnerDetail.individual.patronymic"
                  label="Отчество"
                  @change="changes = true"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <transition name="fade">
        <div>
          <v-sheet >
            <v-card-actions style="padding:24px">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex v-if="getSavingSuccessful" class="ml-2" style="color:#66BB6A">
                СОХРАНЕНО!
              </v-flex>
              <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                tile
                style="margin:12px"
                color="#fe81a1"
                min-width="135"
                outlined
                @click="closeEditOwnerDetail"
                >Закрыть
              </v-btn>
              <v-btn
                tile
                style="margin:12px"
                color="#6292a8"
                min-width="135"
                outlined
                @click="saveEditOwnerDetail"
                >Сохранить
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    ownerId: null
  },
  data: () => ({
    baseOwnerDetail: {},
    localOwnerDetail: {},
    changedData: {
      individual: {}
    },
    valid: true,
    changes: false,
    lengthPhone: [
      v => !!v || "Это поле обязательное",
      v => (v && v.length > 17) || "Введите корректно номер"
    ]
  }),
  created() {
    this.$store.dispatch("getLocalityList");
    this.baseOwnerDetail = { ...this.getDetailOwner };
    this.localOwnerDetail = { ...this.getDetailOwner };
  },
  methods: {
    closeEditOwnerDetail() {
      this.$emit("close");
    },
    saveEditOwnerDetail() {
      try {
        Object.keys(this.baseOwnerDetail).forEach(prop => {
          if (this.baseOwnerDetail[prop] !== this.localOwnerDetail[prop]) {
            this.changedData[prop] = this.localOwnerDetail[prop];
            console.log("prop", this.baseOwnerDetail[prop], this.localOwnerDetail[prop]);
            if (this.changedData["phone"]) {
              this.changedData["phone"] = this.changedData["phone"].replace(/[-() ]/g, "");
              this.changedData["phone"] = this.changedData["phone"].replace("+", "");
            }
          }
        });
      } catch (e) {
        console.log("block 1");
      }
      try {
        Object.keys(this.baseOwnerDetail.individual).forEach(prop => {
          if (this.baseOwnerDetail.individual[prop] !== this.localOwnerDetail.individual[prop]) {
            console.log("prop individual", prop);
            this.changedData.individual[prop] = this.localOwnerDetail.individual[prop];
          }
        });
      } catch (e) {
        console.log("block 2");
      }
      console.log("base -", this.baseOwnerDetail, "local-", this.localOwnerDetail);
      console.log("changedData", this.changedData, this.changedData.individual);
      if (this.localOwnerDetail.phone) {
        this.clearPhone = this.localOwnerDetail.phone.replace(/[-() ]/g, "");
        this.clearPhone = this.clearPhone.replace("+", "");
        this.localOwnerDetail.phone = this.clearPhone;
      }
      console.log("localOwnerDetail", this.localOwnerDetail);
      this.$store.dispatch("changeOwner", {
        id: this.ownerId,
        data: this.localOwnerDetail
      });
      // this.$emit('close')
      // this.$store.dispatch('getPropertyOwners')
    },
    clickPhone() {
      this.localOwnerDetail.phone = "+7 (";
    }
  },
  changeClaim() {
    console.log("changeClaim");
  },
  computed: {
    ...mapGetters(["localityList", "getSavingSuccessful", "getSavingError"]),
    getDetailOwner() {
      return this.$store.state.ownerDetail;
    }
  }
};
</script>
<style >


</style>
