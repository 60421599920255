<!-- TODO компонент не используется -->
<template>
  <v-card height="100%" class="card-wrapper" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class="header" style="padding:24px;">
          <v-col>
            <span class="headline">Редактировать дом</span>
          </v-col>
        </v-card-title>
      </v-sheet>

      <v-card-text style="padding:24px;">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col sm="6">
                <v-select
                  v-model="localHomeDetail.company"
                  :items="companyList"
                  label="Компания"
                  @change="changes = true"
                ></v-select>
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-model="localHomeDetail.city"
                  label="Населенный пункт"
                  @change="changes = true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  v-model="localHomeDetail.street"
                  @change="changes = true"
                  label="Улица"
                >
                </v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-model="localHomeDetail.house_number"
                  @change="changes = true"
                  label="Номер дома"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <transition name="fade">
        <div>
          <v-sheet>
            <v-card-actions style="padding:22px">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex class="ml-2" v-if="getSavingSuccessful" style="color:#66BB6A">
                СОХРАНЕНО!
              </v-flex>
              <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                color="#fe81a1"
                @click="closeEditCompanyDetail"
                outlined
                min-width="135"
                tile
                style="margin:12px"
                >Закрыть
              </v-btn>
              <v-btn
                color="#6292a8"
                outlined
                tile
                style="margin:12px"
                min-width="135"
                @click="saveHome"
                >Сохранить
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    homeId: null
  },
  data() {
    return {
      baseCompanyDetail: {},
      localCompanyDetail: {},
      changedData: {},
      valid: true,
      changes: false
    };
  },
  created() {
    this.$store.dispatch("getLocalityList");
    this.$store.dispatch("getCompanyList");
    this.baseHomeDetail = { ...this.getDetailHome };
    console.log("baseHomeDetail", this.baseHomeDetail);
    this.localHomeDetail = { ...this.getDetailHome };
    console.log("localHomeDetail", this.localHomeDetail);
  },
  methods: {
    closeEditCompanyDetail() {
      this.$emit("close");
    },
    saveHome() {
      Object.keys(this.baseHomeDetail).forEach(prop => {
        if (this.baseHomeDetail[prop] !== this.localHomeDetail[prop]) {
          this.changedData[prop] = this.localHomeDetail[prop];
        }
      });
      console.log("changeData", this.changedData);
      this.$store.dispatch("changeHome", {
        id: this.homeId,
        data: this.changedData
      });
      setTimeout(() => {
        this.$emit("close");
      }, 1500);
    }
  },
  computed: {
    ...mapGetters(["localityList", "companyList", "getSavingSuccessful", "getSavingError"]),
    getDetailHome() {
      return this.$store.state.detailHome;
    }
  }
};
</script>
<style></style>
