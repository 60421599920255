<!-- По идеи страничка логина, но из действующего только background -->
<template>
  <div class="login">
    <v-container>
      <div class="d-flex justify-center align-items-center">
        <v-card class="login__form">
          <v-card-title class="pa-4 primary">
            <span class="justify-center headline white--text">
              Upravhouse crm
            </span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col class="body-1 py-0" cols="12" sm="12">
                    Регистрация:
                  </v-col>
                  <v-col cols="8" sm="8">
                    <v-text-field
                      v-model="login"
                      label="Логин"
                      hide-details
                      dense
                      outlined
                      light
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" sm="8">
                    <v-text-field
                      v-model="last_name"
                      label="Фамилия"
                      hide-details
                      dense
                      outlined
                      light
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" sm="8">
                    <v-text-field
                      v-model="first_name"
                      label="Имя"
                      hide-details
                      dense
                      outlined
                      light
                    ></v-text-field>
                  </v-col>

                  <v-col cols="8" sm="8">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      outlined
                      light
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" sm="8">
                    <v-text-field
                      v-model="phone"
                      v-mask="'+7 (###) ###-##-##'"
                      :label="labelPhone"
                      @click="clickPhone"
                      hide-details
                      v-on:keyup.enter="logIn"
                      dense
                      required
                      outlined
                      light
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    login: "",
    phone: "",
    email: "",
    labelPhone: "Телефон",
    emailRules: [v => !!v || "E-mail is required", v => /.+@.+/.test(v) || "E-mail must be valid"]
  }),
  methods: {
    logIn() {
      this.$store.dispatch("login", {
        login: this.login,
        password: this.password
      });
    },
    clickPhone() {
      console.log("clickPhone");
      this.phone = "+7 (";
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    }
  }
};
</script>

<style lang="scss">
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__form {
    max-width: 400px;
  }
}
</style>
