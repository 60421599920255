<!-- Модальное окно Создать собственника -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" :persistent="getPersistentCreateClaimDialog" width="50%">
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class="header" style="padding:24px">
                <v-col>
                  <span class="headline">Создать собственника</span>
                </v-col>
              </v-card-title>
              <v-sheet>
                <v-layout>
                  <v-flex sm="3" style="color: #636162">
                    <span v-if="formData.applicant">
                      {{ $store.state.houseDetail.street }}
                      {{ $store.state.houseDetail.number }}
                      кв. {{ localPropertyOwnerDetail.placement }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-sheet>
              <v-card-text style="padding:24px ">
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col sm="4">
                        <v-select
                          v-model="company"
                          label="Компания"
                          :items="companyList"
                          @change="company => changeCompanyList(company)"
                        ></v-select>
                      </v-col>

                      <v-col sm="4">
                        <v-select
                          v-model="formData.address"
                          :items="companyListBlank ? HouseList : $store.state.relativeHouseList"
                          :rules="requiredRules"
                          label="Адрес"
                          @change="genPersonalAccountMethod"
                        ></v-select>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="formData.phone"
                          v-mask="'+7(###) ###-##-##'"
                          :label="labelPhone"
                          @click="clickPhone"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="formData.placement"
                          :rules="requiredRules"
                          label="Помещение"
                          required
                          @change="genPersonalAccountMethod"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field v-model="formData.entrance" label="Подъезд"></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field v-model="formData.floor" label="Этаж"></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          :disabled="switchNumberAccount"
                          :rules="requiredRules"
                          :value="genPersonalAccount"
                          label="Номер лицевого счета"
                          maxlength="10"
                          readonly
                          required
                          hint="Номер заполнился автоматически, если Вы хотите его отредактировать,
                           выберите собственный номер"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="4">
                        <v-switch
                          v-model="switchNumberAccount"
                          label="Собственный номер"
                          @change="customNumberAccount"
                        ></v-switch>
                      </v-col>
                      <v-col v-if="switchNumberAccount" sm="4">
                        <v-text-field
                          v-model="customPersonalAccount"
                          :rules="requiredRules"
                          label="Номер лицевого счета"
                          maxlength="10"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-switch
                          style="max-width: 160px;"
                          v-model="switchOwner"
                          label="Организация"
                        ></v-switch>
                      </v-col>

                      <v-col v-if="!switchOwner" md="4" sm="6">
                        <v-text-field
                          v-model="formData.individual.last_name"
                          label="Фамилия"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="!switchOwner" md="4" sm="6">
                        <v-text-field
                          v-model="formData.individual.first_name"
                          label="Имя"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="!switchOwner" md="4" sm="6">
                        <v-text-field
                          v-model="formData.individual.patronymic"
                          label="Отчество"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="switchOwner">
                        <v-text-field
                          v-model="formData.entity.name"
                          label="Организация"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions style="justify-content: end; padding:24px">
                <v-flex
                  v-if="getSavingSuccessful"
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>

                  СОХРАНЕНО!
                </v-flex>
                <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
                </v-flex>

                <v-btn
                  style="margin:12px"
                  min-width="135"
                  color="#fe81a1"
                  outlined
                  tile
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  style="margin:12px "
                  color="#6292a8"
                  min-width="135"
                  outlined
                  tile
                  @click="saveOwners"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { VueMaskDirective } from "v-mask";
import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);
Vue.directive("mask", VueMaskDirective);

export default {
  async mounted() {
    this.$store.dispatch("getHouseList");
    this.$store.dispatch("getCompanyList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateOwners: {
      type: Boolean
    }
  },
  data: () => ({
    genPersonalAccount: "",
    labelPhone: "Телефон +7 (XXX) XXX-XX-XX",
    dialog: false,
    companyListBlank: true,
    customPersonalAccount: null,
    company: "",
    formData: {
      personal_account: null,
      phone: "",
      address: Number,
      placement: "",
      entrance: null,
      floor: null,
      personalAccount: "",
      individual: { last_name: "", first_name: "", patronymic: "" },
      entity: { name: "" }
    },
    switchOwner: {
      type: Boolean
    },
    switchNumberAccount: false,
    requiredRules: [v => !!v || "Это поле обязательное"],
    lengthPhone: [v => (v && v.length > 17) || "Введите корректно номер"]
  }),
  methods: {
    changeCompanyList(companyId) {
      this.$store.dispatch("getRelativeHouseList", companyId);
      this.companyListBlank = false;
    },
    customNumberAccount() {
      console.log(this.genPersonalAccount);
    },
    getDialogCreateOwner() {
      this.dialog = true;
    },
    genPersonalAccountMethod() {
      if (this.formData.placement) {
        this.$store.dispatch("getHomeDetail", this.formData.address);
        setTimeout(() => {
          const home = this.$store.state.detailHome;
          console.log("home^", home);
          this.genPersonalAccount = `${home.id}${home.house_number}${this.formData.placement}`;
        }, 500);
      }
    },
    middleware(event) {
      return event.target.className !== "modal";
    },
    closeDialog() {
      this.dialog = false;
      this.companyListBlank = true;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
    },
    saveOwners() {
      if (this.$refs.form.validate()) {
        // if (this.formData.phone){
        //   this.formData.phone = this.formData.phone.replace(/[-() ]/g, '')
        //   this.formData.phone = this.formData.phone.replace('+', '')
        // }
        this.formData.personal_account = this.genPersonalAccount;
        if (this.customPersonalAccount != null) {
          this.formData.personal_account = this.customPersonalAccount;
        }
        this.$store.dispatch("savePropertyOwner", this.formData);
        this.$emit("close");
        console.log("formData", this.formData);
        this.$store.commit("showSavingSuccessful");
        setTimeout(() => {
          this.$store.commit("hideSavingSuccessful");
          this.closeDialog();
        }, 1500);
        this.$store.dispatch("getPropertyOwners");
        this.companyListBlank = true;
      }
    },
    changeSwitcher() {
      this.$store.dispatch("changeSwitchProperty");
    },
    clickPhone() {
      console.log("clickPhone");
      this.formData.phone = "+7 (";
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    }
  },
  computed: {
    ...mapGetters([
      "HouseList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "companyList"
    ]),
    switchCompany() {
      const switchCompany = this.$store.state.switchCompanyCreateProperty;
      return switchCompany;
    }
  },
  watch: {
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
      },
      deep: true
    }
  }
};
</script>
<style></style>
