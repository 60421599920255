import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import config from "@/config";
import router from "@/routes";
import moment from "moment";

Vue.use(Vuex);
// Vue.config.devtools = process.env.NODE_ENV === "development";
const backendUrl = config.BACKEND_URL;
const botToken = "bot5261851830:AAF9v6oBcTddOORqAtMFMFKbVJaOy0JUU0Y";
const store = new Vuex.Store({
  state: {
    auth: {
      logined: localStorage.getItem("token") !== null,
      accessToken: "Token " + localStorage.getItem("token") || null
      //operatorId: localStorage.getItem('operator_id') || null,
    },
    socket: {
      message: "",
      isConnected: false,
      reconnectError: false,
      info: ""
    },
    sip: {
      login: localStorage.getItem("sip_login") || null,
      password: localStorage.getItem("sip_password") || null,
      isConnected: false,
      isExternalPhone: JSON.parse(localStorage.getItem("is_external_phone")) || false,
      currentCall: null
    },
    callPanel: {
      isOpen: false,
      callNumber: ""
    },
    session: {
      id: null,
      state: 0,
      started: false,
      paused: false,
      canBePaused: false,
      canBeFinished: false,
      pauseTime: 0,
      sessionTime: 0,
      // в коде не используется
      overpaused: false
    },
    calls: {
      list: [],
      // currentCallId: null,
      current: {}
    },
    currentCall: {
      id: null,
      callWaitingForAnswer: false,
      callInProcess: false,
      typeCode: null,
      outsourcedCompany: "",
      outsourced: false,
      phone: "",
      sipId: "",
      callerName: ""
    },
    token: "Token f42a4b8cad2b185229190d24dd27334fcc384405", // a12995c2388c585b3cad611bf74440c1558d84aa 4b37178243ca66834e2cfa882a91e642ea9b6df9
    currentUser: {
      first_name: "",
      last_name: "",
      patronymic: "",
      last_login: "",
      role: ""
    },
    currentClaimType: "",
    errorLoginValue: "",
    claims: [],
    localityList: [],
    companyList: [],
    claimTypeList: [],
    employeeList: [],
    houseList: [],
    executorList: [],
    relativeExecutorList: [],
    relativeClaimTypeList: [],
    relativeHouseList: [],
    propertyOwnerList: [],
    propertyOwner: {},
    usersList: [],
    userDetail: {},
    homeList: [],
    claimLogList: [],
    cityTypeList: [],
    fiasAddressList: [],
    correctPassword: false,
    switchCompanyCreateProperty: true,
    savingSuccessful: false,
    savingError: false,
    dialogCreateClaim: false,
    detailCompany: "",
    detailLocality: "",
    detailHome: "",
    baseDetailCompany: "",
    errorText: "",
    editCompanyDetail: false,
    dialogEditClaim: false,
    claimDetail: {},
    ownerDetail: {},
    executorDetail: {},
    claimTypeDetail: "",
    persistentCreateClaimDialog: false,
    persistentCreateClaimTypeDialog: false,
    lockClaimDetail: false,
    lockExecutorDetail: false,
    lazyLoadEditDialog: false,
    searchCompanyResult: {},
    houseDetail: "",
    newCompanyId: "",
    nameSearchCompany: "Название компании",
    filterData: {
      company: "",
      pointer: "",
      applicant__address: "",
      claim_type: "",
      created__lte: "",
      created__gte: "",
      status: "",
      emergency: "",
      payable: "",
      executor: ""
    }
  },
  mutations: {
    setSipConnection(state, payload) {
      state.sip.isConnected = payload;
    },
    setCallPanelVisibility(state, payload) {
      state.callPanel.isOpen = payload;
    },
    setCallWaitingForAnswer(state, payload) {
      state.currentCall.callWaitingForAnswer = payload;
    },
    updateCorrectPasswordTrue(state) {
      state.correctPassword = true;
      console.log("Сработал updateCorrectPassword", state.correctPassword);
    },
    updateCorrectPasswordFalse(state) {
      state.correctPassword = false;
    },
    updateGetCurrentUser(state, user) {
      state.currentUser = user;
    },
    updateClaims(state, claims) {
      state.claims = claims;
    },
    updateClaimDetail(state, claimDetail) {
      state.claimDetail = claimDetail;
    },
    updateDetailHome(state, detailHome) {
      state.detailHome = detailHome;
    },
    updateDetailOwner(state, ownerDetail) {
      state.ownerDetail = ownerDetail;
    },
    updateDetailExecutor(state, executorDetail) {
      state.executorDetail = executorDetail;
      console.log("work mutation updateDetailExecutor");
    },
    updateDetailClaimType(state, claimTypeDetail) {
      state.claimTypeDetail = claimTypeDetail;
    },
    updateLocalityList(state, localityList) {
      state.localityList = localityList;
    },
    updateCompanyList(state, companyList) {
      state.companyList = companyList;
    },
    setSearchCompanyResult(state, searchCompanyResult) {
      state.searchCompanyResult = searchCompanyResult;
    },
    setErrorLoginValue(state, errorLoginValue) {
      state.errorLoginValue = errorLoginValue;
    },
    updateClaimTypeList(state, claimTypeList) {
      state.claimTypeList = claimTypeList;
    },
    updateCityTypeList(state, cityTypeList) {
      state.cityTypeList = cityTypeList;
    },
    updateEmployeeList(state, employeeList) {
      state.employeeList = employeeList;
    },
    updateHouseList(state, houseList) {
      state.houseList = houseList;
    },
    updatePropertyOwnerList(state, propertyOwnerList) {
      state.propertyOwnerList = propertyOwnerList;
    },
    updateGetFiasAddressList(state, fiasAddressList) {
      state.fiasAddressList = fiasAddressList;
    },
    updatePropertyOwner(state, propertyOwnerDetail) {
      console.log("сработал updatePropertyOwner", propertyOwnerDetail);
      state.propertyOwner = propertyOwnerDetail;
      console.log("state propertyOwner", state.propertyOwner);
    },
    updateNameSearchCompany(state, nameSearchCompany) {
      state.nameSearchCompany = nameSearchCompany;
    },
    resetPropertyOwner(state) {
      console.log("Сработала мутация assign propertyOwner");
      console.log("state propertyOwner", state.propertyOwner);
    },
    updateExecutorList(state, executorList) {
      state.executorList = executorList;
    },
    updateRelativeExecutorList(state, executorList) {
      state.relativeExecutorList = executorList;
    },
    updateRelativeClaimTypeList(state, relativeClaimTypeList) {
      state.relativeClaimTypeList = relativeClaimTypeList;
    },
    updateRelativeHouseList(state, relativeHouseList) {
      state.relativeHouseList = relativeHouseList;
    },
    updateUsersList(state, usersList) {
      state.usersList = usersList;
    },
    updateUserDetail(state, userDetail) {
      console.log("сработал updateUserDetail", userDetail);
      state.userDetail = userDetail;
    },
    updateHomeList(state, homeList) {
      state.homeList = homeList;
    },
    updateClaimLogList(state, claimLogList) {
      state.claimLogList = claimLogList;
    },
    updateNewCompanyId(state, newCompanyId) {
      state.newCompanyId = newCompanyId;
    },
    showSavingSuccessful(state) {
      state.savingSuccessful = true;
    },
    hideSavingSuccessful(state) {
      state.savingSuccessful = false;
    },
    showSavingError(state) {
      state.savingError = true;
    },
    hideSavingError(state) {
      state.savingError = false;
    },
    updateSavingError(state, errorText) {
      state.errorText = errorText;
    },
    showDialogCreateClaim(state) {
      state.dialogCreateClaim = true;
    },
    hideDialogCreateClaim(state) {
      state.dialogCreateClaim = false;
    },
    showDialogEditClaim(state) {
      state.dialogEditClaim = true;
    },
    hideDialogEditClaim(state) {
      state.dialogEditClaim = false;
    },
    showEditCompanyDetail(state) {
      state.editCompanyDetail = true;
    },
    hideEditCompanyDetail(state) {
      state.editCompanyDetail = false;
    },
    lockedClaimDetail(state) {
      state.lockClaimDetail = true;
    },
    unlockedClaimDetail(state) {
      state.lockClaimDetail = false;
    },
    lockedExecutorDetail(state) {
      state.lockExecutorDetail = true;
    },
    unlockedExecutorDetail(state) {
      state.lockExecutorDetail = false;
    },
    canBeClosedCreateClaimDialog(state) {
      state.persistentCreateClaimDialog = false;
    },
    canNotBeClosedCreateClaimDialog(state) {
      state.persistentCreateClaimDialog = true;
    },
    editDialogMount(state) {
      state.lazyLoadEditDialog = true;
    },
    editDialogUmount(state) {
      state.lazyLoadEditDialog = false;
    },

    updateLogin(state, payload) {
      localStorage.setItem("token", payload.token);
      localStorage.setItem("userId", payload.id);
      state.auth.accessToken = "Token " + payload.token;
      state.auth.logined = true;
      if (state.currentUser) {
        console.log("currentUser*", state.currentUser);
        if (state.currentUser.last_login == null && state.currentUser.role !== 2) {
          router.push({ name: "preset" });
        } else {
          console.log(moment().format(), state.currentUser.id);
          router.push({ name: "claimboard" });
        }
      }
    },
    logout(state) {
      localStorage.removeItem("token");
      state.auth.accessToken = null;
      state.auth.logined = false;
    },
    updateDetailCompany(state, detailCompany) {
      state.detailCompany = detailCompany;
    },
    updateDetailLocality(state, detailLocality) {
      state.detailLocality = detailLocality;
    },
    updateHouseDetail(state, houseDetail) {
      state.houseDetail = houseDetail;
    },
    updateFilterData(state, filterData) {
      state.filterData = filterData;
    },
    updateCurrentClaimType(state, currentClaimType) {
      state.currentClaimType = currentClaimType;
    },

    setCallNumber(state, payload = null) {
      if (payload !== null) {
        state.callPanel.callNumber = payload;
      }
    },
    setCallData(state, payload) {
      const { callerName, phone, isIncoming, sipId } = payload;
      const call = state.currentCall;
      call.sipId = sipId;
      call.phone = phone;
      if (isIncoming) {
        console.log("incoming call");
        // "парсим" callerName
        call.typeCode = 0; // по дефолту обычный входящий
        call.outsourcedCompany = ""; // ...и никакого аутсорсинга
        call.callerName = callerName;
        if (callerName.search("leverans") !== -1) {
          call.callerName = callerName.replace("Q", "_");
        } else if (callerName === "callback") {
          call.typeCode = 2; // коллбэк
        } else if (callerName.search("callback") !== -1) {
          call.typeCode = 2; // коллбэк
          call.outsourcedCompany = call.callerName.replace(/callback /, "");
        } else {
          call.outsourcedCompany = call.callerName; // аутсорс, а тип все равно входящий
        }

        call.callWaitingForAnswer = true;
        call.callInProcess = false;
      } else {
        console.log("outgoing call");
        call.typeCode = 1;
        call.callerName = "";
        call.outsourcedCompany = "";
      }
      call.outsourced = Boolean(call.outsourcedCompany);
    },
    setCallAnswered(state, { currentCall }) {
      console.log("setCallAnswered currentCall", currentCall);
      currentCall.callWaitingForAnswer = false;
      currentCall.callInProcess = true;
    }
  },
  // -------------- Уведомления -----------
  setSipConnection(state, payload) {
    state.sip.isConnected = payload;
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async closeCall({ state, commit, dispatch }, { status = 0 }) {
      commit("setDashboardBgColor");
      commit("setCallPanelVisibility", false);
      console.log("Звонок оборвался");
      dispatch("callStatusUpdate", {
        id: state.currentCall.id,
        status: 0 // None
        // status: status, // REJECTED
      });
      commit("clearCurrentCall");
      // commit('setCurrentCallId', null);
    },
    // eslint-disable-next-line no-unused-vars
    async answerCall({ state, commit, dispatch }, payload) {
      commit("setCallAnswered");
      dispatch("callStatusUpdate", {
        id: state.currentCall.id,
        status: 2 // ANSWERED
      });
    },
    async signUp(payload) {
      try {
        const res = await axios.post(backendUrl + "/user/create-admin/", {
          last_name: payload.last_name,
          first_name: payload.first_name,
          email: payload.email,
          phone: payload.phone,
          username: payload.username
        });
        console.log(res);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async processNewCall({ commit }, payload) {
      console.log("processNewCall");
      commit("setCallData", payload);
      // const call = state.currentCall;

      // commit('setDashboardBgColor', {
      //   callTypeCode: call.typeCode,
      //   outsourced: Boolean(call.outsourcedCompany),
      // });
      // commit('setCallPanelVisibility', true);
      // dispatch('getCallerInfo', call);
      // send call data to API
      // dispatch('callCreate', call);
    },
    // eslint-disable-next-line no-unused-vars
    pushNotification({ state, commit }, payload) {
      const timestamp = new Date().getTime();
      const { type, message } = payload;
      const newNotification = {
        type,
        message,
        timestamp
      };
      commit("addNotification", newNotification);
      setTimeout(() => {
        commit("removeNotification", Notification);
      }, config["NOTIFICATION_DURATION"]);
    },
    async getCurrentUser({ commit }, id) {
      try {
        const res = await axios.get(`${backendUrl}/user/${id}/`, {
          headers: { Authorization: `Token ${process.env.VUE_APP_TOKEN}` }
        });
        const response = res.data;
        console.log("response getCurrentUser", response);
        commit("updateGetCurrentUser", response);
      } catch (e) {
        console.log("getCurrentUser!", e);
      }
    },
    //
    async login({ state, dispatch, commit }, payload) {
      try {
        const { password, login } = payload;
        const url = "/user/api-token-auth/";
        const headers = {
          "X-CSRFToken": config.CSRF_TOKEN,
          accept: "application/json"
        };
        const response = await axios.post(
          backendUrl + url,
          { username: login, password },
          { headers }
        );
        const { data } = response;
        console.log("response login", response.data.id);
        console.log("state auth", state.auth);
        dispatch("getCurrentUser", response.data.id);
        setTimeout(() => {
          commit("updateLogin", { ...data });
        }, 1000);
      } catch (error) {
        console.log("error login", error.response.data.non_field_errors[0]);
        const errorValue = error.response.data.non_field_errors[0];
        commit("setErrorLoginValue", errorValue);
      }
    },
    // изменения пароля
    async checkPassword({ state, commit }, payload) {
      try {
        const { password, login } = payload;
        const url = "/user/api-token-auth/";
        const headers = {
          "X-CSRFToken": config.CSRF_TOKEN,
          accept: "application/json"
        };
        const response = await axios.post(
          backendUrl + url,
          { username: login, password },
          { headers }
        );
        console.log("response login", response.data);
        const token = response.data.token;
        const id = response.data.id;
        console.log("response state", state.currentUser.id, localStorage.getItem("token"));
        if (state.currentUser.id === id && localStorage.getItem("token") === token) {
          commit("updateCorrectPasswordTrue");
        }
        return response;
      } catch (error) {
        console.log(error.response);
      }
    },
    async logout({ commit, dispatch }) {
      // const headers = { Authorization: `Bearer ${this.state.auth.accessToken}` };
      // const url = '/auth/';
      try {
        // const response = await axios.delete(
        //   backendUrl + url,
        //   { headers },
        // );
      } catch (error) {
        // надо все равно упорно продолжать выходить, несмотря ни на что
        console.log(error.response);
        dispatch("errorNotification", {
          response: error.response
        });
      }
      commit("logout");
      setTimeout(() => {
        router.push({ name: "Login" });
      }, 0);
    },
    async editUser({ state, commit, dispatch }, payload) {
      const { id, data } = payload;
      const res = await axios.patch(`${backendUrl}/user/${id}/`, data, {
        headers: { Authorization: state.auth.accessToken }
      });
      const response = res.data;
      console.log("response", response);
      if (res.status === 200) {
        commit("showSavingSuccessful");
        setTimeout(() => {
          commit("hideSavingSuccessful");
        }, 3000);
      }
      dispatch("getCurrentUser", localStorage.getItem("userId"));
    },

    async fetchClaims({ state, commit }) {
      let filterDataIsEmpty = true;
      let filterUrl = `${backendUrl}/claim/?`;
      try {
        for (const [key, value] of Object.entries(state.filterData)) {
          if (value == null) {
            state.filterData[key] = "";
          } else if (value != "") {
            filterDataIsEmpty = false;
            filterUrl += `${key}=${value}&`;
            console.log("filterUrl")
          }
        }
        if (!filterDataIsEmpty) {
          console.log("сработал await fetch");

          const res = await fetch(filterUrl, {
            headers: { Authorization: state.auth.accessToken }
          });
          const claims = await res.json();
          commit("updateClaims", claims);
        } else {
          if (state.currentUser.companies) {
            const res = await fetch(`${backendUrl}/claim/`, {
              headers: { Authorization: state.auth.accessToken }
            });
            const claims = await res.json();
            commit("updateClaims", claims);
          }
        }
      } catch (e) {
        console.log("Ошибка fetchClaims", e);
      }

      
    },
    async fetchClaimDetail({ state, commit }, id) {
      try {
        const res = await fetch(backendUrl + "/claim/" + id + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const claimDetail = await res.json();
        commit("updateClaimDetail", claimDetail);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async deleteCompany({ state, dispatch }, id) {
      try {
        await axios.delete(`${backendUrl}/company/${id}/`, {
          headers: {
            Authorization: state.auth.accessToken
          }
        });
        console.log("try delete company");
        dispatch("getCompanyList");
      } catch (e) {
        console.log(e.response.data);
      }
    },

    async saveCompany({ state }, payload) {
      try {
        return axios.post(
          backendUrl + "/company/",
          {
            name: payload.companyName,
            inn: payload.inn,
            address: payload.address
          },
          { headers: { Authorization: state.auth.accessToken } }
        );
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getFiasAddressList({ state, commit }, address) {
      try {
        const res = await axios.post(
          backendUrl + "/company/fias-address/",
          { address: address },
          { headers: { Authorization: state.auth.accessToken } }
        );
        commit("updateGetFiasAddressList", res.data.value);
      } catch (e) {
        console.log(e);
      }
    },
    async getLocalityList({ state, commit, dispatch }) {
      try {
        const res = await fetch(backendUrl + "/user/locality/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const localitylist = await res.json();
        commit("updateLocalityList", localitylist);
        this.localitylist = localitylist;
        dispatch("getUsersList");
      } catch (e) {
        console.log(e);
      }
    },
    async getCompanyList({ state, commit }) {
      try {
        const res = await fetch(backendUrl + "/company/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const companylist = await res.json();
        console.log("companylist from server", companylist);
        commit("updateCompanyList", companylist);
      } catch (e) {
        console.log(e);
      }
    },
    async searchCompany({ commit }, payload) {
      var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
      var token = "c625ebcf495c3a4bf8bfce43f5672a53cdddc421";
      var options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Token " + token
        },
        body: JSON.stringify({ query: payload.inn })
      };
      fetch(url, options)
        .then(response => response.json())

        .then(result => {
          try {
            const companyValue = result.suggestions[0];
            console.log("vuex companyValue", companyValue);
            // this.formData.companyName = companyValue.value
            commit("setSearchCompanyResult", companyValue);
          } catch (e) {
            console.log("vuex nameSearchCompanyError", e);
            commit("updateNameSearchCompany", "Найти не удалось, введите вручную");
          }
        })
        .catch(error => console.log("error", error));
    },

    async getUsersList({ state, commit }) {
      console.log("сработал getUsersList");
      const response = await axios.get(backendUrl + "/user/", {
        headers: { Authorization: state.auth.accessToken }
      });
      const usersList = response.data;
      commit("updateUsersList", usersList);
      console.log("response usersList", usersList);
    },
    async getHomeList({ state, commit }) {
      try {
        const response = await axios.get(backendUrl + "/company/house/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const homeList = response.data;
        commit("updateHomeList", homeList);
        console.log("сработал getHomeList", response);
      } catch (e) {
        console.log(e);
      }
    },
    async getHomeDetail({ state, commit }, id) {
      try {
        const response = await axios.get(backendUrl + "/company/house/" + id + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const detailHome = response.data;
        commit("updateDetailHome", detailHome);
        console.log("response getHomeDetail", response);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getUserDetail({ state, commit }, id) {
      try {
        const response = await axios.get(backendUrl + `/user/${id}/`, {
          headers: { Authorization: state.auth.accessToken }
        });
        const userDetail = response.data;
        commit("updateUserDetail", userDetail);
        console.log("response data", response.data);
        console.log("response action getUserDetail", response);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getCompanyDetail({ state, commit }, id) {
      try {
        const response = await axios.get(backendUrl + "/company/" + id + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const detailCompany = response.data;
        commit("updateDetailCompany", detailCompany);
        console.log("response getCompanyDetail", response);
        console.log("@getCompDET", detailCompany);
        return detailCompany;
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getOwnerDetail({ state, commit }, id) {
      try {
        const response = await axios.get(backendUrl + "/property-owner/" + id + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const ownerDetail = response.data;
        commit("updateDetailOwner", ownerDetail);
        console.log("response getOwnerDetail", response);
        console.log("@getCompDET", ownerDetail);
      } catch (e) {
        console.log(e);
      }
    },
    async getExecutorDetail({ state, commit }, id) {
      try {
        const response = await axios.get(backendUrl + "/company/executor/" + id + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const ExecutorDetail = response.data;
        commit("updateDetailExecutor", ExecutorDetail);
        console.log("@getExecDET", ExecutorDetail);

        console.log("сработал action executorDetail", response.data);
        return ExecutorDetail;
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getClaimTypeDetail({ state, commit }, id) {
      console.log("id2", id);
      try {
        const response = await axios.get(`${backendUrl}/claim/claim-type/${id}/`, {
          headers: { Authorization: state.auth.accessToken }
        });
        console.log("await id", id);
        const ClaimTypeDetail = response.data;
        commit("updateDetailClaimType", ClaimTypeDetail);
        console.log("@getClaimTypeDet", ClaimTypeDetail);
        console.log("getClaimTypeDetail id", id);
        return ClaimTypeDetail;
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getLocalityDetail({ state, commit }, id) {
      try {
        const response = await axios.get(backendUrl + "/user/locality/" + id + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const localityDetail = response.data;
        commit("updateDetailLocality", localityDetail);
        console.log("@getLocDET", localityDetail);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getCityTypeList({ state, commit }) {
      try {
        const res = await axios.get(`${backendUrl}/user/city-type/`, {
          headers: { Authorization: state.auth.accessToken }
        });
        const cityTypeList = res.data;
        console.log("cityTypeList", cityTypeList);
        commit("updateCityTypeList", cityTypeList);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getClaimTypeList({ state, commit }) {
      try {
        const res = await fetch(backendUrl + "/claim/claim-type/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const claimTypeList = await res.json();
        commit("updateClaimTypeList", claimTypeList);
      } catch (e) {
        console.log(e);
      }
    },
    async getClaimTypeListCompany({ state, commit }, companies) {
      let url = "/claim/claim-type/";
      companies.forEach(company => {
        url = url + "?company=" + company;
        console.log("getClaimTypeListCompany url", url);
      });
      try {
        const res = await fetch(backendUrl + url, {
          headers: { Authorization: state.auth.accessToken }
        });
        const claimTypeList = await res.json();
        commit("updateClaimTypeList", claimTypeList);
      } catch (e) {
        console.log(e);
      }
    },

    async saveClaimType({ state, commit }, data) {
      try {
        const res = await axios.post(`${backendUrl}/claim/claim-type/`, data, {
          headers: { Authorization: state.auth.accessToken }
        });
        commit("updateCurrentClaimType", res.data);
        return res.data;
      } catch (e) {
        console.log("saveClaimType", e);
      }
    },
    // TODO ошибка в получении специализации
    async getEmployeeList({ state, commit }) {
      try {
        const res = await fetch(backendUrl + "/company/employee/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const employeeList = await res.json();
        console.log("getEmployeeList", employeeList);
        commit("updateEmployeeList", employeeList);
      } catch (e) {
        console.log(e.response.data);
      }
    },

    async getHouseList({ state, commit }) {
      try {
        const res = await fetch(backendUrl + "/company/house/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const houseList = await res.json();
        console.log("getHouseList", houseList);
        commit("updateHouseList", houseList);
      } catch (e) {
        console.log(e);
      }
    },

    async getHouseDetail({ state, commit }, houseId) {
      try {
        const res = await axios.get(backendUrl + "/company/house/" + houseId + "/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const houseDetail = res.data;
        console.log("houseDetail in action", res.data);
        commit("updateHouseDetail", houseDetail);
      } catch (e) {
        console.log(e.response.data);
      }
    },
    async getExecutorList({ state, commit }) {
      try {
        const res = await fetch(backendUrl + "/company/executor/", {
          headers: { Authorization: state.auth.accessToken }
        });
        const executorList = await res.json();
        commit("updateExecutorList", executorList);
      } catch (e) {
        console.log(e);
      }
    },
    async getRelativeExecutorList({ state, commit }, executorPayload) {
      const { company, claim_type } = executorPayload;
      try {
        if (claim_type) {
          const res = await fetch(
            backendUrl + `/company/executor/?company=${company}&specialization=${claim_type}`,
            { headers: { Authorization: state.auth.accessToken } }
          );
          const executorList = await res.json();
          commit("updateRelativeExecutorList", executorList);
        } else {
          console.log("catch");
          const res = await fetch(backendUrl + `/company/executor/?company=${company}`, {
            headers: { Authorization: state.auth.accessToken }
          });
          const executorList = await res.json();
          commit("updateRelativeExecutorList", executorList);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getRelativeHouseList({ state, commit }, companyId) {
      const addressUrl = `${backendUrl}/company/house/?company=${companyId}`;

      const headers = { Authorization: state.auth.accessToken };
      try {
        const res = await axios.get(addressUrl, { headers });
        const addressCompany = [];
        res.data.forEach(address => {
          const res = {};
          res.value = address.id;
          res.text = address.get_address;
          addressCompany.push(res);
        });
        console.log("Сработал getRelativeHouseList addressCompany =", addressCompany);
        commit("updateRelativeHouseList", addressCompany);
        return addressCompany;
      } catch (error) {
        console.log(error);
      }
    },
    async getRelativeClaimTypeList({ state, commit }, companyId) {
      try {
        const res = await axios.get(backendUrl + "/claim/claim-type/?company=" + companyId, {
          headers: { Authorization: state.auth.accessToken }
        });
        const relativeClaimTypeList = res.data;
        commit("updateRelativeClaimTypeList", relativeClaimTypeList);
      } catch (e) {
        console.log(e.response.data);
      }
    },

    async getCurrentClaimType({ state }, data) {
      try {
        const companyId = data.companyId;
        const name = data.name;
        console.log("getCurrentClaimType name", name);
        console.log("getCurrentClaimType company", companyId);
        const res = await axios.get(
          backendUrl + "/claim/claim-type/?company=" + companyId + "&name=" + name,
          { headers: { Authorization: state.auth.accessToken } }
        );
        return res.data;
      } catch (e) {
        console.log(e.response.data);
      }
    },

    async saveExecutor({ state, commit }, payload) {
      try {
        let phone = payload.phone;
        phone = phone.replace(/[-() ]/g, "");
        phone = phone.replace("+", "");
        let res = await axios.post(
          backendUrl + "/company/executor/",
          {
            specialization: payload.specialization,
            phone: phone,
            companies: payload.companies,
            employee: payload.employee
          },
          { headers: { Authorization: state.auth.accessToken } }
        );
        if (res.status == 201) {
          commit("showSavingSuccessful");
          setTimeout(() => {
            commit("hideSavingSuccessful");
          }, 3000);
        }
      } catch (error) {
        commit("showSavingError");
        commit("updateSavingError", error);
        setTimeout(() => {
          commit("hideSavingError");
        }, 3000);
        console.log(error);
      }
    },
    async createUser({ state }, payload) {
      console.log("work createUser action");
      try {
        let phone = payload.phone;
        phone = phone.replace(/[-() ]/g, "");
        phone = phone.replace("+", "");
        return axios.post(
          backendUrl + "/user/",
          {
            username: payload.username,
            phone: phone,
            companies: payload.companylist,
            last_name: payload.last_name,
            first_name: payload.first_name,
            patronymic: payload.patronymic,
            email: payload.email,
            role: 2,
            password: payload.password
          },
          { headers: { Authorization: state.auth.accessToken } }
        );
      } catch (e) {
        console.log("createUser error", e.response.data);
      }
    },

    async saveHome({ state }, payload) {
      try {
        return axios.post(
          backendUrl + "/company/house/",

          {
            company: payload.company,
            city: payload.city,
            street: payload.street,
            house_number: payload.number,
            house_fiasid: payload.house_fiasid
          },
          { headers: { Authorization: state.auth.accessToken } }
        );
      } catch (e) {
        console.log("error saveHome", e);
      }
    },

    async saveLocality({ state }, payload) {
      try {
        return axios.post(
          backendUrl + "/user/locality/",
          {
            prefix: payload.prefix,
            name: payload.locality
          },
          { headers: { Authorization: state.auth.accessToken } }
        );
      } catch (e) {
        console.log(e);
      }
    },
    /* eslint-disable */
    async savePropertyOwner({ state, commit, dispatch }, payload) {
      try {
        let {
          personal_account,
          phone,
          address,
          placement,
          entrance,
          floor,
          individual,
          entity
        } = payload;
        phone = phone.replace(/[-() ]/g, "");
        phone = phone.replace("+", "");
        const data = {
          personal_account,
          phone,
          address,
          placement,
          entrance,
          floor
        };
        if (individual.first_name && individual.last_name) {
          data["individual"] = individual;
          console.log("data individual", data["individual"]);
        }
        if (entity.name) {
          data["entity"] = entity;
          console.log("data entity", data["entity"]);
        }
        console.log("savePropertyOwner data", data);
        return axios.post(backendUrl + "/property-owner/", data, {
          headers: { Authorization: state.auth.accessToken }
        });
        dispatch("getPropertyOwners");
      } catch (e) {
        console.log(e.response.data);
      }
    },
    
    async saveUploadOwners({ state, commit }, payload) {
      try {
        const res = await axios.post(backendUrl + "/company/upload_property_owners/", payload, {
          headers: {
            Authorization: state.auth.accessToken,
            "Content-Disposition": "attachment; filename=upload.csv",
            "Content-Type": "multipart/form-data"
          }
        });
        console.log("payload", payload);
      } catch (e) {
        console.log(e);
      }
    },
    async saveClaims({ state, dispatch, commit }, payload) {
      try {
        const res = await axios.post(`${backendUrl}/claim/`, payload, {
          headers: {
            Authorization: state.auth.accessToken
          }
        });
        console.log("res.status", res.status);
        if (res.status === 201) {
          console.log("1144 res", res);
          commit("showSavingSuccessful");
          const applicant = await dispatch("getPropertyOwnerDetail", res.data.applicant);
          console.log("1147 applicant", applicant);
          const companyId = await dispatch("getTelegramChannelNumber", payload.company);
          dispatch("getCompanyDetail", companyId).then(company => {
            const pld = {};
            pld["chat_id"] = company.telegram_channel;
            pld[
              "text"
            ] = `Создана заявка № ${res.data.internal_id}\nАдрес: ${res.data.address} ${applicant.placement}\nЗаявитель: ${res.data.applicant_individual}; +${res.data.contact_phone}"\nТекст: ${res.data.text}`;
            dispatch("sendMessageToChannel", pld);
          });
          console.log("1145 const companyId", companyId);
          // console.log('1148 const telegramChannelId', company)
          return res.data;
          // TODO  Не используемый код
          // setTimeout(() => {
          //   commit("hideSavingSuccessful");
          // }, 3000);
        }
      } catch (error) {
        commit("showSavingError");
        commit("updateSavingError", error);
        console.log(error);
        setTimeout(() => {
          commit("hideSavingError");
          state.errorText = "";
        }, 3000);
      }
    },

    async changeClaim({ state, commit }, pld) {
      try {
        const { id, data } = pld;
        const response = await axios.patch(backendUrl + "/claim/" + id + "/", data, {
          headers: {
            Authorization: state.auth.accessToken,
            "Content-type": "application/json"
          }
        });
        if (response.status == 200) {
          console.log("response data", response.data);
          commit("showSavingSuccessful");
          setTimeout(() => {
            commit("hideSavingSuccessful");
          }, 3000);
        }
      } catch (e) {
        console.log("catch", e.message);
        console.log("e", e.response.data);
      }
      console.log("headers:", state.auth.accessToken);
    },
    async createCompany({ state }, username) {
      console.log("createCompany", username);
      try {
        const data = { name: `company${username}` };
        return axios.post(backendUrl + "/company/", data, {
          headers: {
            Authorization: "Token " + process.env.VUE_APP_TOKEN,
            "Content-type": "application/json"
          }
        });
      } catch (e) {
        console.log(e.response.data);
      }
      console.log("newCompany in crateCompany", response.data);
      // return response.data.id
    },
    async setCompanyInUser({ state, dispatch, commit }, payload) {
      const { username, userId } = payload;
      console.log("userId in setCompanyInUser", userId);
      console.log("сработал setCompany");
      console.log("setCompanyInUser", username);
      try {
        const newCompanyId = await dispatch("createCompany", username);
        localStorage.setItem("newCompanyId", newCompanyId.data.id);
        // .then(res => this.state.newCompanyId = res)
        console.log("username", username);
        console.log("newCompanyId", newCompanyId.data);
        // const id = localStorage.getItem('userId')
        // console.log('id', id)
        const data = { companies: [newCompanyId.data.id] };
        const response = await axios.patch(backendUrl + "/user/" + userId + "/", data, {
          headers: {
            Authorization: "Token " + process.env.VUE_APP_TOKEN,
            "Content-type": "application/json"
          }
        });
        console.log("******************", response.data);
      } catch (e) {
        console.log("setCompanyInUser", e);
      }
    },
    async changeCompany({ state, commit, dispatch }, pld) {
      try {
        const { id, data } = pld;
        await axios
          .patch(backendUrl + "/company/" + id + "/", data, {
            headers: {
              Authorization: state.auth.accessToken,
              "Content-type": "application/json"
            }
          })
          .then(response => {
            if (response.status === 200) {
              console.log("response data", response.data);
              dispatch("getCompanyList");
              commit("showSavingSuccessful");
              setTimeout(() => {
                commit("hideSavingSuccessful");
              }, 3000);
            }
          });
      } catch (e) {
        console.log("catch", e.message);
      }
    },
    async changeExecutor({ state, commit, dispatch }, pld) {
      try {
        const { id, data } = pld;
        await axios
          .patch(backendUrl + "/company/executor/" + id + "/", data, {
            headers: {
              Authorization: state.auth.accessToken,
              "Content-type": "application/json"
            }
          })
          .then(response => {
            if (response.status === 200) {
              console.log("response data", response.data);
              console.log("успешно");
              commit("showSavingSuccessful");
              setTimeout(() => {
                commit("hideSavingSuccessful");
              }, 3000);
              dispatch("getExecutorList");
            }
          });
      } catch (e) {
        console.log("error changeExecutor", e);
      }
    },
    async changeHome({ state, commit, dispatch }, pld) {
      const { id, data } = pld;
      const response = await axios.patch(`${backendUrl}/company/house/${id}/`, data, {
        headers: {
          Authorization: state.auth.accessToken,
          "Content-type": "application/json"
        }
      });
      if (response.status === 200) {
        commit("showSavingSuccessful");
        setTimeout(() => {
          commit("hideSavingSuccessful");
        }, 3000);
        dispatch("getHomeList");
      }
    },
    async changeClaimType({ state, commit, dispatch }, pld) {
      const { id, data } = pld;
      const response = await axios.patch(`${backendUrl}/claim/claim-type/${id}/`, data, {
        headers: {
          Authorization: state.auth.accessToken,
          "Content-type": "application/json"
        }
      });
      if (response.status == 200) {
        commit("showSavingSuccessful");
        setTimeout(() => {
          commit("hideSavingSuccessful");
        }, 3000);
        dispatch("getClaimTypeList");
        console.log("successSave");
      }
    },
    async changeLocality({ state, commit, dispatch }, pld) {
      const { id, data } = pld;
      const response = await axios.patch(`${backendUrl}/user/locality/${id}/`, data, {
        headers: {
          Authorization: state.auth.accessToken,
          "Content-type": "application/json"
        }
      });
      if (response.status == 200) {
        commit("showSavingSuccessful");
        setTimeout(() => {
          commit("hideSavingSuccessful");
        }, 3000);
        dispatch("getLocalityList");
      }
    },
    async changeCompanyName({ state }, pld) {
      const { id, data } = pld;
      return axios.patch(backendUrl + "/company/" + id + "/", data, {
        headers: {
          Authorization: state.auth.accessToken,
          "Content-type": "application/json"
        }
      });
    },
    async changeOwner({ state, commit, dispatch }, pld) {
      try {
        const { id, data } = pld;
        await axios
          .put(backendUrl + "/property-owner/" + id + "/", data, {
            headers: {
              Authorization: state.auth.accessToken,
              "Content-type": "application/json"
            }
          })
          .then(response => {
            if (response.status == 200) {
              console.log("response data", response.data);
              dispatch("getCompanyList");
              dispatch("getPropertyOwners");
              commit("showSavingSuccessful");
              setTimeout(() => {
                commit("hideSavingSuccessful");
              }, 3000);
            }
          });
      } catch (e) {
        console.log("catch", e.message);
      }
    },
    changeSavingSuccessful({ state, commit }) {
      const savingSuccessful = true;
      commit("updateSavingSuccessful", savingSuccessful);
    },
    async getPropertyOwners({ state, commit }) {
      try {
        const res = await axios.get(`${backendUrl}/property-owner/`, {
          headers: { Authorization: state.auth.accessToken }
        });
        const propertyOwnerList = res.data;

        commit("updatePropertyOwnerList", propertyOwnerList);
      } catch (e) {
        console.log("getPropertyOwners", e);
      }
    },
    async getClaimLog({ state, commit }, claimId) {
      try {
        const res = await axios.get(`${backendUrl}/claim/claim-log/?claim=${claimId}`, {
          headers: { Authorization: state.auth.accessToken }
        });
        const claimLogList = res.data;
        commit("updateClaimLogList", claimLogList);
        console.log("claimLogList**********", claimLogList);
        console.log("work claimLogList");
      } catch (e) {
        console.log("getClaimLog", e);
      }
    },
    async addClaimLog({ state, commit, dispatch }, payload) {
      try {
        const { message, data } = payload;
        console.log("1433 addClaimLog", payload);
        await axios.post(
          `${backendUrl}/claim/claim-log/`,
          {
            claim: data.id,
            pointer: localStorage.getItem("userId"),
            message: message
          },
          {
            headers: { Authorization: state.auth.accessToken }
          }
        );
        const companyId = await dispatch("getTelegramChannelNumber", data.company);
        dispatch("getCompanyDetail", companyId).then(company => {
          const pld = {};
          pld["chat_id"] = company.telegram_channel;
          pld["text"] = `Изменена заявка № ${data.internal_id}; ${message}`;
          dispatch("sendMessageToChannel", pld);
        });
        dispatch("getClaimLog", data.id);
      } catch (e) {
        console.log(e);
      }
    },
    async getPropertyOwnerDetail({ state, commit, dispatch }, id) {
      try {
        const response = await axios.get(`${backendUrl}/property-owner/${id}/`, {
          headers: {
            Authorization: state.auth.accessToken,
            "Content-type": "application/json"
          }
        });
        const propertyOwnerDetail = response.data;
        commit("updatePropertyOwner", propertyOwnerDetail);
        console.log("propertyOwnerDetail", propertyOwnerDetail);
        return await response.data;
      } catch (error) {
        console.log(error.response);
      }
    },
    async savePreset({ state, dispatch }, payload) {
      try {
        const { home, company, owner, claimType, executor } = payload;
        const userId = localStorage.getItem("userId");
        console.log("company savePreset", company);
        const newCompany = await dispatch("saveCompany", company);
        const newCompanyId = newCompany.data.id;
        console.log("newCompanyId timeout", newCompanyId);
        const dataUser = { companies: [newCompanyId] };
        const response = await axios.patch(backendUrl + "/user/" + userId + "/", dataUser, {
          headers: {
            Authorization: "Token " + process.env.VUE_APP_TOKEN,
            "Content-type": "application/json"
          }
        });
        const homePayload = home;
        homePayload.city = home.city;
        homePayload.house_number = home.number;
        homePayload.street = home.street;
        homePayload.house_fiasid = home.house_fiasid;
        homePayload.company = newCompanyId;
        const resHome = await dispatch("saveHome", homePayload);
        console.log("resHome", resHome.data);
        const ownerPayload = owner;
        ownerPayload.address = resHome.data.id;
        const resOwner = dispatch("savePropertyOwner", ownerPayload);
        console.log(resOwner);
        // const claimTypePayload = {company: newCompanyId, name: claimType}
        // const resClaimType = await dispatch('saveClaimType', claimTypePayload)
        const executorPayload = executor;
        executorPayload.specialization = claimType;
        executorPayload.companies = [newCompanyId];
        dispatch("saveExecutor", executorPayload);
        const data = { last_login: moment().format() };
        const userPayload = { id: userId, data: data };
        dispatch("editUser", userPayload);
        router.push({ name: "claimboard" });
      } catch (e) {
        console.log(e);
      }
    },
    async clearCountNewComment({ dispatch }, id) {
      console.log("id!!!", id);
      const response = await axios.patch(
        backendUrl + "/claim/" + id + "/",
        { count_new_comment: 0 },
        {
          headers: {
            Authorization: "Token " + process.env.VUE_APP_TOKEN,
            "Content-type": "application/json"
          }
        }
      );
    },
    async createExternalOutgoingCall({ dispatch, commit }, payload) {
      const { sipLogin, phone } = payload;
      const headers = { Authorization: `Bearer ${this.state.auth.accessToken}` };
      const url = "/create-outgoing/";
      const response = await axios.post(
        backendUrl + url,
        { sip_account: sipLogin, phone },
        { headers }
      );
      const { data } = response;
    },
    async getTelegramChannelNumber({ commit }, companyId) {
      try {
        const res = await axios.get(`${backendUrl}/company/${companyId}/`, {
          headers: { Authorization: `Token ${process.env.VUE_APP_TOKEN}` }
        });
        const response = res.data.id;
        console.log("response getTelegramChannel", response);
        return response;
      } catch (e) {
        console.log("error getTelegramChannel!", e);
      }
    },
    sendMessageTelegram(chatId, text) {
      const token = "bot5261851830:AAF9v6oBcTddOORqAtMFMFKbVJaOy0JUU0Y";
      // const chatId = 127037610
      // const text = 'Привет'
      axios.post(`https://api.telegram.org/${token}/sendMessage`, { chat_id: chatId, text: text });
    },
    sendMessageToTelegram({ state }, payload) {
      console.log("state.session", state.session);
      const token = "bot5261851830:AAF9v6oBcTddOORqAtMFMFKbVJaOy0JUU0Y";
      axios.post(`https://api.telegram.org/${token}/sendMessage`, {
        chat_id: payload.chat_id,
        text: payload.text
      });
    },

    sendMessageToChannel({ dispatch }, payload) {
      console.log("Сработал sendMessageToChannel");
      axios.post(`https://api.telegram.org/${botToken}/sendMessage`, {
        chat_id: `-${payload.chat_id}`,
        text: payload.text
      });
    }
  },
  modules: {},
  getters: {
    getServerUrl: state => {
      return backendUrl;
    },
    allClaims(state) {
      const claims = [];
      const listClaims = state.claims;
      listClaims.forEach(claim => {
        const res = {};
        res.internal_id = claim.internal_id;
        res.id = claim.id;
        res.company = claim.company;
        res.pointer = claim.pointer;
        res.executor = claim.executor;
        res.applicant_name = claim.applicant_name;
        res.applicant = claim.applicant;
        res.status = claim.status;
        res.created = claim.created.split(".", 1);
        res.planned_date = claim.planned_date;
        res.claim_type = claim.claim_type;
        res.emergency = claim.emergency;
        res.text = claim.text;
        res.payable = claim.payable;
        res.count_new_comment = claim.count_new_comment;
        res.appeal = claim.appeal;
        claims.push(res);
      });
      return claims;
    },
    claimsCount(state) {
      return state.claims.length;
    },
    getterClaimDetail(state) {
      return state.claimDetail;
    },
    getterCurrentUser(state) {
      console.log("сработал getterCurrentUser");
      return state.currentUser;
    },
    getSearchCompanyResult(state) {
      return state.searchCompanyResult;
    },

    // TODO Состояния листов в компонентах таблицы
    localityList(state) {
      const localitylist = [];
      const locality = state.localityList;
      locality.forEach(local => {
        const res = {};
        res.value = local.id;
        res.text = local.get_full_name;
        localitylist.push(res);
      });
      return localitylist;
    },
    companyList(state) {
      const companyList = [];
      const company = state.companyList;
      company.forEach(comp => {
        const res = {};
        res.value = comp.id;
        res.text = comp.name;
        res.inn = comp.inn;
        companyList.push(res);
      });

      return companyList;
    },

    getClaimTypeList(state) {
      const claimTypeList = [];
      const claimTypes = state.claimTypeList;
      claimTypes.forEach(claimType => {
        const res = {};
        res.value = claimType.id;
        res.text = claimType.name;
        claimTypeList.push(res);
      });
      return claimTypeList;
    },
    EmployeeList(state) {
      const employeeList = [];
      const employees = state.employeeList;
      employees.forEach(employee => {
        const res = {};
        res.value = employee.id;
        res.text = employee.get_full_name;
        employeeList.push(res);
      });
      return employeeList;
    },
    HouseList(state) {
      const houseList = [];
      const houses = state.houseList;
      houses.forEach(house => {
        const res = {};
        res.value = house.id;
        res.text = house.get_address;
        houseList.push(res);
      });
      console.log("HouseList houseList", houseList);
      return houseList;
    },
    getterCityTypeList(state) {
      const cityTypeList = [];
      const ctyTypes = state.cityTypeList;
      ctyTypes.forEach(cityType => {
        const res = {};
        res.value = cityType.id;
        res.text = cityType.name;
        cityTypeList.push(res);
      });
      console.log("getterCityTypeList", cityTypeList);
      return cityTypeList;
    },
    getApplicantList(state) {
      const applicantList = [];
      const applicants = state.propertyOwnerList;
      applicants.forEach(applicant => {
        const res = {};
        res.value = applicant.id;
        res.text = applicant.get_requisites;
        res.personal_account = applicant.personal_account;
        res.get_requisites = applicant.get_requisites;
        res.phone = applicant.phone;
        res.address = applicant.address;
        res.entrance = applicant.entrance;
        res.entity = applicant.entity;
        res.placement = applicant.placement;
        applicantList.push(res);
      });
      return applicantList;
    },
    getterExecutorList(state) {
      const executorList = [];
      const executors = state.executorList;
      console.log("EXECUTOR LIST", state.executorList);
      executors.forEach(executor => {
        const res = {};
        res.value = executor.id;
        res.text = executor.get_requisites;
        res.companies = executor.companies;
        res.specialization = executor.specialization;
        res.phone = executor.phone;
        res.contractor = executor.contractor;
        res.get_requisites = executor.get_requisites;
        executorList.push(res);
      });
      console.log("executorList", executorList);
      return executorList;
    },
    getRelativeExecutorList(state) {
      const executorList = [];
      const executors = state.relativeExecutorList;
      executors.forEach(executor => {
        const res = {};
        res.value = executor.id;
        res.text = executor.get_requisites;
        executorList.push(res);
      });
      return executorList;
    },
    getErrorText(state) {
      return state.errorText;
    },
    getRelativeClaimTypeList(state) {
      const claimTypeList = [];
      const claimsType = state.relativeClaimTypeList;
      claimsType.forEach(claimType => {
        const res = {};
        res.value = claimType.id;
        res.text = claimType.name;
        claimTypeList.push(res);
      });
      return claimTypeList;
    },
    getLocalityList(state) {
      const localityList = [];
      const localities = state.localityList;
      localities.forEach(locality => {
        const res = {};
        res.value = locality.id;
        res.text = locality.get_full_name;
        localityList.push(res);
      });
      console.log("getter getLocalityList", localityList);
      return localityList;
    },
    getUsersList(state) {
      const usersList = [];
      const users = state.usersList;
      users.forEach(user => {
        const res = {};
        res.value = user.id;
        res.text = user.get_full_name;
        res.email = user.email;
        res.phone = user.phone;
        res.username = user.username;
        usersList.push(res);
      });
      return usersList;
    },
    getterClaimTypeList(state) {
      const claimTypeList = [];
      const claimTypes = state.claimTypeList;
      claimTypes.forEach(claimType => {
        const res = {};
        res.value = claimType.id;
        res.text = claimType.name;
        res.company = claimType.company;
        claimTypeList.push(res);
      });
      return claimTypeList;
    },
    getterHomeList(state) {
      const homeList = [];
      const houses = state.homeList;
      houses.forEach(house => {
        const res = {};
        res.value = house.id;
        res.text = house.get_address;
        res.company = house.company;
        res.city = house.city;
        res.street = house.street;
        res.house_number = house.house_number;
        res.house_fiasid = house.house_fiasid;
        homeList.push(res);
      });
      console.log("сработал getter homeList", homeList);
      return homeList;
    },
    getterRelativeHouseList(state) {
      const houseList = [];
      const houses = state.relativeHouseList;
      houses.forEach(house => {
        const res = {};
        res.value = house;
      });
    },
    getSavingSuccessful(state) {
      return state.savingSuccessful;
    },
    getSavingError(state) {
      return state.savingError;
    },
    getLazyLoadEditDialog(state) {
      return state.lazyLoadEditDialog;
    },
    getPersistentCreateClaimDialog(state) {
      return state.persistentCreateClaimDialog;
    },
    getPersistentCreateClaimTypeDialog(state) {
      return state.persistentCreateClaimTypeDialog;
    },
    getDialogCreateClaim(state) {
      return state.dialogCreateClaim;
    },
    getDialogEditClaim(state) {
      return state.dialogEditClaim;
    },
    getDetailCompany(state) {
      console.log("#getDetailCompany", state.detailCompany);
      return state.detailCompany;
    },
    getShowEditCompanyDetail(state) {
      return state.editCompanyDetail;
    },
    getLockClaimDetail(state) {
      return state.lockClaimDetail;
    },
    getLockExecutorDetail(state) {
      return state.lockExecutorDetail;
    },
    getClaimLogList(state) {
      const claimLogList = [];
      const logLists = state.claimLogList;
      const statusList = [
        { value: 1, text: "Принята" },
        { value: 2, text: "Исполнение" },
        { value: 3, text: "Закрыта" }
      ];

      Object.keys(logLists).forEach(log => {
        if (log === "status") {
          console.log("log status", log.status);
          statusList.forEach(status => {
            if (status["value"] === log.status) {
              log["status"] = statusList["text"];
            }
          });
        }
        claimLogList.push(log);
      });
      return claimLogList;
    },
    getNameSearchCompany(state) {
      return state.nameSearchCompany;
    },
    getterPropertyOwnerDetail(state) {
      return state.propertyOwner;
    },
    getterCurrentUserInitials(state) {
      const first_name = state.currentUser.first_name;
      const last_name = state.currentUser.last_name;
      let initialFirst_name = "";
      let initialPatronymic = "";
      console.log("сработал initials");
      if (first_name.length > 0) {
        initialFirst_name = `${first_name.slice(0, 1)}.`;
      }
      if (state.currentUser.patronymic.length > 0) {
        initialPatronymic = `${state.currentUser.patronymic.slice(0, 1)}.`;
      }
      return `${last_name} ${initialFirst_name} ${initialPatronymic}`;
    },
    getterFiasAddressList(state) {
      console.log("getter", state.fiasAddressList);
      return state.fiasAddressList;
    }
  }
});

export default store;
