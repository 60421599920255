<!-- Редактирование специализации -->
<template>
  <v-card class="card-wrapper" height="100%" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class="header" style="padding: 24px ;">
          <v-col>
            <span class="headline">Редактировать специализацию</span>
          </v-col>
        </v-card-title>
      </v-sheet>

      <v-card-text style="padding: 24px ;">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col sm="6">
                <v-select
                  v-model="localClaimTypeDetail.company"
                  :items="companyList"
                  label="Компания"
                  @change="changes = true"
                >
                </v-select>
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-model="localClaimTypeDetail.name"
                  label="Специализация"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <transition name="fade">
        <div>
          <v-sheet>
            <v-card-actions style="padding:22px">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex v-if="getSavingSuccessful" class="ml-2" style="color:#66BB6A">
                СОХРАНЕНО!
              </v-flex>
              <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                tile
                style="margin:12px"
                color="#fe81a1"
                min-width="135"
                outlined
                @click="closeEditClaimTypeDetail"
                >Закрыть
              </v-btn>
              <v-btn
                tile
                style="margin:12px"
                color="#6292a8"
                min-width="135"
                outlined
                @click="saveClaimType"
                >Сохранить
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    claimTypeId: null,
    detailObject: Object
  },
  data() {
    return {
      getDetailObj: this.detailObject,
      baseClaimTypeDetail: {},
      localClaimTypeDetail: {},
      changeData: {},
      valid: true,
      changes: false
    };
  },
  setup() {
    // TypeError: Cannot read properties of null (reading 'getDetailClaimType') // TODO
    this.baseClaimTypeDetail = { ...this.getDetailClaimType };
    this.localClaimTypeDetail = { ...this.getDetailClaimType };
  },
  methods: {
    closeEditClaimTypeDetail() {
      this.$emit("close");
    },

    saveClaimType() {
      Object.keys(this.baseClaimTypeDetail).forEach(prop => {
        if (this.baseClaimTypeDetail[prop] !== this.localClaimTypeDetail[prop]) {
          this.changeData[prop] = this.localClaimTypeDetail[prop];
        }
      });

      this.$store.dispatch("changeClaimType", {
        id: this.claimTypeId,
        data: this.changeData
      });
    }
  },

  computed: {
    ...mapGetters(["companyList", "getSavingSuccessful", "getSavingError"]),
    getDetailClaimType() {
      console.log("getDetailClaimType###", this.$store.state.claimTypeDetail);
      return this.$store.state?.claimTypeDetail;
    }
  }
};
</script>

<style></style>
