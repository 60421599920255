<!-- Компонент главного поиска -->
<template>
  <v-container style=" overflow-y:scroll" fluid>
    <v-sheet color="#0288D1" dark tile class="br-15%">
      <v-layout>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            dense
            hide-details
            label="Поиск по всем полям"
            single-line
          ></v-text-field>
          <v-btn class="ml-4 " icon title="Фильтр заказов" x-small @click="showFilter">
            <i class="material-icons filter_list "></i>
          </v-btn>
        </v-card-title>
      </v-layout>
    </v-sheet>
    <v-form ref="filterForm">
      <v-expand-transition>
        <div v-show="filterField">
          <v-sheet class="mt-4" color="#FAFAFA">
            <v-layout class="filter">
              <v-col sm="2">
                <v-select
                  v-model="filterData.company"
                  :items="companyList"
                  clearable
                  label="Компания"
                  required
                  @change="company => changeCompanyList(company)"
                >
                </v-select>
              </v-col>
              <v-col sm="2">
                <v-select
                  v-model="filterData.applicant__address"
                  :items="companyAddresses"
                  clearable
                  label="Адрес"
                  @change="getFilterResult"
                >
                </v-select>
              </v-col>
              <v-col sm="2">
                <v-select
                  v-model="filterData.claim_type"
                  :items="relativeSpecializationList"
                  clearable
                  label="Тип заявки"
                  @change="getFilterResult"
                >
                </v-select>
              </v-col>
              <v-col sm="2" class="filter_btn">
                <v-list-item class="pr-12 mb-2">
                  <v-list-item-action class="mr-0">
                    <v-checkbox
                      v-model="filterData.emergency"
                      @change="deactivatePayable"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content class="mr-12px">
                    <v-list-item-title>Аварийная</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col sm="2" class="filter_btn">
                <v-list-item class="pr-12 mb-2">
                  <v-list-item-action class="mr-0">
                    <v-checkbox
                      v-model="filterData.payable"
                      @change="deactivateEmergency"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Платная</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-layout>
            <v-layout class="filter">
              <v-col sm="2">
                <v-select
                  v-model="filterData.status"
                  :items="statusList"
                  clearable
                  label="Статус"
                  @change="getFilterResult"
                >
                </v-select>
              </v-col>
              <v-col sm="2">
                <v-select
                  v-model="filterData.pointer"
                  :items="getUsersList"
                  clearable
                  label="Назначил"
                  @change="getFilterResult"
                >
                </v-select>
              </v-col>
              <v-col sm="2">
                <v-select
                  v-model="filterData.executor"
                  :items="getterExecutorList"
                  clearable
                  label="Исполнитель"
                  @change="getFilterResult"
                >
                </v-select>
              </v-col>
              <v-col sm="2">
                <v-menu
                  ref="filterDataMenu"
                  v-model="filterDataMenu"
                  :close-on-content-click="false"
                  :return-value.sync="rangeDate"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="rangeDate"
                      clearable
                      label="Диапазон дат"
                      readonly
                      v-bind="attrs"
                      @input="getFilterResult"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="rangeDate" no-title range scrollable>
                    <v-btn
                      color="#6292a8"
                      min-width="135"
                      outlined
                      tile
                      @click="filterDataMenu = false"
                    >
                      Отмена
                    </v-btn>
                    <v-btn color="#6292a8" min-width="135" outlined tile @click="getFilterDate">
                      Сохранить
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="2">
                <download-excel
                  :data="selectedRows"
                  :fields="json_fields"
                  class="btn"
                  name="filename.xls"
                  worksheet="My Worksheet"
                >
                  <v-btn class="mb-3 mr-16" min-width="135" color="#6292a8" outlined tile>
                    Выгрузить
                  </v-btn>
                </download-excel>
              </v-col>
            </v-layout>
          </v-sheet>
        </div>
      </v-expand-transition>
    </v-form>
    <edit-claim />
    <v-data-table
      :footer-props="tableFooterProps"
      :headers="dataHeaders"
      :items="getAllClaims"
      :items-per-page="25"
      :search="search"
      show-select
      calculate-widths
      dense
      sort-by="created"
      sort-desc
      fixed-header
      height="calc(100vh - 190px)"
      item-key="id"
      mobile-breakpoint="0"
    >
      <!-- Исправлена ошибка особого синтаксиса vuetify eslint было <template v-slot:header.data-table-select -->
      <template v-slot:[`header.data-table-select`]>
        <v-checkbox
          style="padding-bottom: 6px;"
          color="rgb(0 0 0);"
          dense
          :indeterminate="indeterminate"
          :value="isAllSelected"
          @change="selectAllRows"
          :title="checkbox"
        ></v-checkbox>
      </template>
      <!-- Столбцы главной таблицы  -->
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            :style="
              item.id === selected
                ? 'background-color: #dddddd;'
                : `background-color: ${item.status_color};`
            "
            @dblclick="showDetails(item.id)"
          >
            <td>
              <v-checkbox
                :value="isRowSelected(item)"
                @change="selectRow(item)"
                color="rgb(0 0 0);"
                dense
              ></v-checkbox>
            </td>

            <!-- <td v-if="getterCurrentUser.role === 1" class="table_instruction"></td> -->
            
            <td class="table_instruction">{{  item.id  }}</td>

            <td class="table_instruction">{{ item.internal_id }}</td>

            <td :title="item.company" class="table_instruction">{{ item.company }}</td>

            <td :title="item.pointer" class="table_instruction">
              {{ item.pointer }}
            </td>
            <td :title="item.executor" class="table_instruction ">
              {{ item.executor }}
            </td>
            <td :title="item.applicant_name" class="table_instruction">
              {{ item.applicant_name }}
            </td>
            <td :title="item.applicant" class="table_instruction">
              {{ item.applicant }}
            </td>

            <td :title="item.status" class="table_instruction">{{ item.status }}</td>
            <td :title="item.created" class="table_instruction">
              {{ item.created | fixDateTime }}
            </td>

            <td :title="item.planned_date" class="table_instruction">
              {{ item.planned_date | fixPlanedDate }}
            </td>
            <td :title="item.claim_type" class="table_instruction">{{ item.claim_type }}</td>
            <!-- <td>
              <v-icon :title="item.text" size="18px"> message </v-icon>
            </td>
            <td>
              <v-badge
                v-if="item.count_new_comment && item.count_new_comment !== 0"
                :content="item.count_new_comment"
                color="red"
                inline
                small
              >
                <v-icon :title="item.count_new_comment" size="18px"> mdi-comment-eye </v-icon>
              </v-badge>
            </td> -->
            <td class="table_instruction">
              <v-icon v-if="item.emergency" color="black" size="18px">done</v-icon>
            </td>
            <td class="table_instruction">
              <v-icon v-if="item.payable" color="black" size="18px">done</v-icon>
            </td>
            <td>
              <v-icon :title="item.text" size="18px"> message </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog>
      <order-filters
        @close="showFilters = false"
        @reset="showFilters = false"
        @submit="showFilters = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import fixDateTime from "@/filters/fixdate";
import fixPlanedDate from "@/filters/fixplaneddate";
import EditClaim from "@/components/EditClaim";
import config from "@/config";
import axios from "axios";
import moment_timezone from "moment-timezone";

// TODO Присваиваем функцию ко всем всплывающим console.log
// console.log = function () { };

export default {
  components: {
    EditClaim
  },
  name: "Claims",
  data() {
    return {
      badge: false,
      filterDate: "",
      APIData: [],
      listClaims: [],
      search: "",
      selected: [],
      address: "",
      interval: null,
      filterField: false,
      companyAddresses: [],
      relativeSpecializationList: [],
      filterDataMenu: false,
      rangeDate: "",
      timeZone: "",
      selectedLines: {},
      tableFooterProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [25, 50, 100],
        itemsPerPageText: "Строк"
      },
      statusList: [
        { value: 1, text: "Принята" },
        { value: 2, text: "Исполнение" },
        { value: 3, text: "Закрыта" },
        { value: 4, text: "Отменена" }
      ],
      // Можно изменять размеры с помощью , width: 140px фиксированно или 15%
      dataHeaders: [
       
        // { text: "", value: "", width:1 },
        { text: "ID", value: "item.id", width: 67 },
        { text: "№", value: "internal_id", width: 67 },
        { text: "Компания", value: "company", width: 126 },
        { text: "Назначил", value: "pointer", width: 110 },
        {
          text: "Исполнитель",
          value: "executor",
          width: 130
        },
        { text: "Заявитель", value: "applicant_name", width: 114 },
        { text: "Адрес", value: "applicant", width: 154 },
        {
          text: "Статус",
          value: "status",
          width: 90
        },

        { text: "Время создания", value: "created", width: 148 },
        { text: "Плановая дата", value: "planned_date", width: 140 },
        { text: "Тип заявки", value: "claim_type", width: 116 },
        { text: "Авария", value: "emergency", width: 93 },
        {
          text: "Платная",
          value: "payable",
          width: 100
        },
        {
          text: "Текст",
          value: "text",
          width: 83
        }
      ],
      filterData: {
        company: localStorage.getItem("newCompanyId"),
        applicant__address: "",
        claim_type: "",
        created__lte: "",
        created__gte: "",
        status: "",
        emergency: "",
        payable: "",
        pointer: "",
        executor: ""
      },
      json_fields: {
        Собственник: "applicant_name",
        Адрес: "applicant",
        "Тип заявки": "claim_type",
        Компания: "company",
        Создана: {
          field: "created",
          callback: value => {
            const createdDate = value.split("T");
            const date = createdDate[0];
            const time = createdDate[1].split(".")[0];
            return `${date} ${time}`;
          }
        },
        Исполнитель: "executor",
        "Плановая дата": {
          field: "planned_date",
          callback: value => {
            if (value !== "") {
              const plannedDate = value.split("T")[0];
              return `${plannedDate}`;
            } else {
              console.log(`undefined ${value}`);
              return value;
            }
          }
        },
        Назначил: "pointer",
        Статус: "status",
        "Текст заявки": "text",
        Аварийная: "emergency",
        Платная: "payable"
      },
      selectedRows: []
    };
  },
  filters: {
    fixDateTime,
    fixPlanedDate
  },
  methods: {
    ...mapMutations(["updateClaimDetail"]),
    getFilterResult() {
      this.$store.dispatch("fetchClaims");
      this.$store.commit("updateFilterData", this.filterData);
      console.log("getFilterResult", this.filterData);
    },
    deactivatePayable(newValue) {
      if (newValue === true) {
        this.filterData.payable = false;
        this.$store.dispatch("fetchClaims");
        this.$store.commit("updateFilterData", this.filterData);
      }
    },

    deactivateEmergency(newValue) {
      if (newValue === true) {
        this.filterData.emergency = false;
        this.$store.dispatch("fetchClaims");
        this.$store.commit("updateFilterData", this.filterData);
      }
    },
    showFilter() {
      this.$store.dispatch("getExecutorList");
      this.filterField = !this.filterField;
      this.filterData.created__gte = "";
      this.filterData.created__lte = "";
      this.$refs.filterForm.reset();
      // this.filterData.company = this.companyList[0].value
      // this.changeCompanyList(this.filterData.company)
    },
    showDetails(id) {
      this.$store.dispatch("clearCountNewComment", id);
      this.$emit("get:details", id);
    },
    // selectItem(id) {
    //   this.$store.dispatch("fetchClaimDetail", id);
    //   this.$store.commit("showDialogEditClaim");
    //   // this.$store.dispatch('getCompanyList')
    // },
    async changeCompanyList(companyId) {
      // this.$store.dispatch('fetchClaims')
      this.filterData.applicant__address = "";
      this.filterData.claim_type = "";
      this.getFilterResult();
      const addressUrl = `${config.BACKEND_URL}/company/house/?company=${companyId}`;
      const headers = { Authorization: this.$store.state.auth.accessToken };
      try {
        const res = await axios.get(addressUrl, { headers });
        const addressCompany = [];
        res.data.forEach(address => {
          const res = {};
          res.value = address.id;
          res.text = address.get_address;
          addressCompany.push(res);
        });
        this.companyAddresses = addressCompany;
        console.log("addressCompany =", addressCompany);
      } catch (error) {
        console.log(error);
      }
      const specializationUrl = `${config.BACKEND_URL}/claim/claim-type/?company=${companyId}`;
      try {
        const res = await axios.get(specializationUrl, { headers });
        const specializationCompany = [];
        res.data.forEach(specialization => {
          const res = {};
          res.text = specialization.name;
          res.value = specialization.id;
          specializationCompany.push(res);
        });
        this.relativeSpecializationList = specializationCompany;
      } catch (error) {
        console.log(error);
      }
    },
    getFilterDate() {
      console.log("filterData", this.rangeDate);
      this.filterDataMenu = false;
      this.$refs.filterDataMenu.save(this.rangeDate);
      console.log("rangeDate", this.rangeDate);
      console.log(
        "filterData.create_lte",
        this.filterData.created__lte,
        "filterData.created_gte",
        this.filterData.created__gte
      );
      this.filterData.created__gte = this.rangeDate[0];
      this.filterData.created__lte = this.rangeDate[1];
      this.$store.commit("updateFilterData", this.filterData);
      console.log("getFilterDate", this.filterData);
      this.$store.dispatch("fetchClaims");
    },
    selectRow(row) {
      const index = this.selectedRows.findIndex(line => row.id === line.id);
      console.log("selectRow", index);
      if (index === -1) {
        this.selectedRows.push(row);
      } else {
        this.selectedRows.splice(index, 1);
      }
    },
    selectAllRows(value) {
      this.getAllClaims.forEach(row => {
        if (value && !this.isRowSelected(row)) {
          this.selectedRows.push(row);
        } else if (!value && this.isRowSelected(row)) {
          const index = this.selectedRows.findIndex(line => row.id === line.id);
          this.selectedRows.splice(index, 1);
        }
      });
    },
    isRowSelected(row) {
      return this.selectedRows.findIndex(line => row.id === line.id) !== -1;
    }
  },
  computed: {
    ...mapGetters([
      "claimDetail",
      "companyList",
      "getUsersList",
      "getterExecutorList",
      "getterCurrentUser"
    ]),
    getAllClaims() {
      const items = this.$store.getters.allClaims;
      const statusColors = {
        "#C8E6C9": ["Принята"], // green #ccffcc
        "#FFF9C4": ["Исполнение"], // yellow #ffff88
        "#E0E0E0": ["Отменена"],
        "#ffb837": [19], // orange #ffd383
        "#ff4d4d": [4, 5, 24, 30] // red #ffcccc
      };
      items.forEach(item => {
        const statusCode = item.status;
        const wasCancelled = item.cancelled;
        item.status_color = "";
        const cr = moment_timezone.utc(item.created[0]).tz(this.timeZone);
        item.created = cr.format();
        if (!wasCancelled) {
          if (item.emergency === false) {
            Object.keys(statusColors).forEach(color => {
              if (statusColors[color].includes(statusCode)) {
                item.status_color = color;
              } else if (item.appeal === true && item.status !== "Закрыта") {
                item.status_color = "#80D8FF";
              }
            });
          } else if (item.emergency === true && item.status !== "Закрыта") {
            item.status_color = "#FF8A80";
            item.badge = true;
          }
        } else {
          item.status_color = "#aaaaff"; // blue
        }
        if (item.planned_date != null) {
          if (
            new Date().valueOf() > new Date(item.planned_date).valueOf() &&
            item.status !== "Закрыта"
          ) {
            item.status_color = "#FF80AB";
          }
        }
      });

      return items;
    },

    // selectResult() {
    //   return this.getAllClaims.filter((item) => this.selectedLines[item.id]);
    // },
    rowsHashMap() {
      const rowsMap = {};
      this.getAllClaims.forEach(row => {
        rowsMap[row.id] = row;
      });
      return rowsMap;
    },
    selectedRowsOnThisPage() {
      return this.selectedRows.filter(row => this.rowsHashMap[row.id]);
    },
    indeterminate() {
      return !!(this.selectedRows.length && !this.isAllSelected);
    },
    isAllSelected: {
      get() {
        return (
          this.getAllClaims.length > 0 &&
          this.selectedRowsOnThisPage.length === this.getAllClaims.length
        );
      },
      set(value) {
        this.selectAllRows(value);
      }
    }
  },
  watch: {
    rangeDate(newValue) {
      if (newValue == null) {
        this.filterData.created__gte = "";
        this.filterData.created__lte = "";
        // this.$store.commit('updateFilterData', this.filterData)
      }
    }
  },
  
  mounted() {
   
    this.timeZone = moment_timezone.tz.guess(true);
    this.interval = setTimeout(() => {
      this.$store.dispatch("fetchClaims");
    }, 10);
    this.interval = setInterval(() => {
      this.$store.dispatch("fetchClaims");
    }, 90000);
  },

};
</script>

<style lang="scss">
.filter {
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0 0 78px;
}
.filter_btn {
  display: flex;
  justify-content: space-evenly;
}

.card_title {
  display: flex;
  flex: none;
}

.data_table {
  padding: 0 !important;
}

.avatar {
  width: 24px;
}

.table_instruction {
  white-space: nowrap !important; /* Запрещаем перенос строк */
  overflow: hidden !important; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis !important; /* Добавляем многоточие */
  text-align: left !important;
}
tbody {
  tr:hover {
    box-shadow: inset 7em 5em rgba(0, 0, 0, 0.15);
  
  }
}

</style>
