<template>
  <v-card height="100%" class="card-wrapper" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class="header" style="padding:24px ">
          <v-col>
            <span class="headline">Редактировать компанию</span>
          </v-col>
        </v-card-title>
      </v-sheet>

      <v-card-text style="padding: 24px  ;">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  v-model="localCompanyDetail.name"
                  @change="changes = true"
                  label="Компания"
                >
                </v-text-field>
              </v-col>

              <v-col sm="6">
                <v-text-field v-model="localCompanyDetail.inn" label="ИНН" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <transition name="fade">
        <div>
          <v-sheet class="pb-10">
            <v-card-actions style="padding:24px">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex class="ml-2" v-if="getSavingSuccessful" style="color:#66BB6A">
                СОХРАНЕНО!
              </v-flex>
              <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                color="#fe81a1"
                @click="closeEditCompanyDetail"
                outlined
                tile
                min-width="135"
                style="margin:12px "
                >Закрыть
              </v-btn>
              <v-btn
                color="#6292a8"
                outlined
                tile
                style="margin:12px "
                min-width="135"
                @click="saveCompany"
                >Сохранить
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    companyId: null
  },
  data() {
    return {
      baseCompanyDetail: {},
      localCompanyDetail: {},
      changedData: {},
      valid: false,
      changes: false
    };
  },
  created() {
    this.$store.dispatch("getLocalityList");
    this.baseCompanyDetail = { ...this.getDetailCompany };
    this.localCompanyDetail = { ...this.getDetailCompany };
  },
  methods: {
    closeEditCompanyDetail() {
      this.$emit("close");
    },
    saveCompany() {
      Object.keys(this.baseCompanyDetail).forEach(prop => {
        if (this.baseCompanyDetail[prop] !== this.localCompanyDetail[prop]) {
          this.changedData[prop] = this.localCompanyDetail[prop];
        }
      });
      console.log("changeData", this.changedData);
      this.$store.dispatch("changeCompany", {
        id: this.companyId,
        data: this.changedData
      });
      setTimeout(() => {
        this.$emit("close");
      }, 1500);
    }
  },
  computed: {
    ...mapGetters(["localityList", "getSavingSuccessful", "getSavingError"]),
    getDetailCompany() {
      console.log("getDetailCompany", this.$store.state.detailCompany);
      return this.$store.state.detailCompany;
    }
  }
};
</script>
