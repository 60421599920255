<template>
  <v-sheet>
    <v-sheet color="#0288D1" dark tile>
      <v-card-title class=" header" style="padding:24px">
        <span class="headline">
          Панель вызовов
        </span>
        <v-icon title="Скрыть панель" @click="$store.commit('setCallPanelVisibility', false)"
          >close
        </v-icon>
      </v-card-title>
    </v-sheet>
    <v-container class="py-0 body-1" fluid>
      <v-row>
        <v-col sm="12">
          <sip-dialer :callPhone="callPhone" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import SipDialer from "@/components/SipDialer";
export default {
  name: "CallPanel",
  components: {
    SipDialer
  },
  computed: {
    callPhone: {
      get() {
        return this.$store.state.callPanel.callNumber;
      },
      set(phone) {
        this.$store.commit("setCallNumber", phone);
      }
    }
  }
};
</script>

<style></style>
