<!-- Модальное окно, таблица Список специализаций  -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="50%">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer v-model="showEditClaimTypeDialog" absolute right temporary>
            <edit-claim-type-detail
              v-if="showEditClaimTypeDialog"
              :detailObject="claimDetailObject"
              :claim-type-id="currentClaimTypeId"
              @close="showEditClaimTypeDialog = false"
            />
          </v-navigation-drawer>
        </div>
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px">
                <v-col>
                  <span class="headline">Список специализаций</span>
                </v-col>
              </v-card-title>
              <v-row class="ml-4 mr-4">
                <v-col class="ml-2 mr-2 mt-4 ">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    label="Поиск"
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="ml-9 mr-9">
                  <v-data-table
                    :footer-props="tableFooterProps"
                    :headers="dataHeaders"
                    :items="getterClaimTypeList"
                    :search="search"
                    calculate-widths
                    dense
                    fixed-header
                    height="40vh"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="editDetailClaimType(item.value)"
                        >
                          <td style="cursor:pointer">{{ item.company }}</td>
                          <td style="cursor:pointer">{{ item.text }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions style="padding: 24px; justify-content: end;">
                <v-btn
                  tile
                  style="margin:12px"
                  min-width="135"
                  color="#fe81a1"
                  outlined
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditClaimTypeDetail from "@/components/EditClaimTypeDetail";

export default {
  components: {
    EditClaimTypeDetail
  },
  data: () => ({
    dialog: false,
    search: "",
    claimDetailObject: {},
    showEditClaimTypeDialog: false,
    currentClaimTypeId: null,
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [
      { text: "Компания", value: "company" },
      { text: "Специализация", value: "text" }
    ]
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditClaimTypeList() {
      this.dialog = true;
    },
    async editDetailClaimType(id) {
      this.currentClaimTypeId = id;
      console.log("editDetailClaimType сработал", "id -", id);
      console.log("id", id);
      this.showEditClaimTypeDialog = true;
      await this.getClaimTypeDetail(id).then(res => {
        this.claimDetailObject = res;
        console.log("!claimDetailObject", this.claimDetailObject);
      });
    },
    ...mapActions({
      getClaimTypeDetail: "getClaimTypeDetail"
    })
  },
  computed: {
    ...mapGetters(["getterClaimTypeList", "getLazyLoadEditDialog"])
  }
};
</script>

<style></style>
