<template>
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer
        :key="componentKey"
        v-model="drawer"
        app
        dark
        expand-on-hover
        width="300"
      >
        <v-list>
          <v-list-group v-model="settingsMenu" no-action>
            <template v-slot:activator>
              <v-list-item-action width="10px">
                <v-list-item-avatar size="25px" style="margin:0">
                  <v-img src="../assets/images/default-avatar.svg"></v-img>
                </v-list-item-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span style="padding-right:10px; font-weight:bold;">{{ initials }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link>
              <v-list-item-content @click="openEditSettings">
                <v-list-item-title>
                  Настройки
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link>
              <v-list-item-content @click="logout">
                <v-list-item-title>
                  Выйти
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider></v-divider>
        <v-list-item
          v-if="getterCurrentUser.role === 1"
          class="mt-12"
          @click="$store.commit('setCallPanelVisibility', true)"
        >
          <v-list-item-action>
            <v-icon color="#81C784">mdi-phone</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title><b class="menu-item-title">Звонки</b></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="openCreateClaim">
          <v-list-item-action>
            <v-icon color="#81C784">mdi-plus-circle-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title><b class="menu-item-title">Создать заявку</b></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list style="padding:0">
          <v-list-group
            v-for="item in items2"
            v-show="item.show"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            :value="testValue"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              link
              @click="
                handle_function_call(child.action);
                item.active = false;
              "
            >
              <v-list-item-content v-if="item.active === true">
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <status-bar ref="childComponent" />
      </v-navigation-drawer>

      <v-main style="padding: 0 0 0 55px; ">
        <template>
          <div v-if="isLogined">
            <div class="dashboard__workspace">
              <v-container fill-height fluid>
                <v-row class="text-center">
                  <v-col cols="12" lg="12" sm="12">
                    <v-card width="100vw">
                      <claims
                        :base-claim-detail="baseClaimData"
                        :claimDetail="claimsDetail"
                        @get:details="id => openOrderDetailWindow(id)"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <v-navigation-drawer
                v-model="showClaimDetails"
                :permanent="getLockClaimDetail"
                absolute
                right
                temporary
              >
                <claim-detail
                  v-if="showClaimDetails"
                  :base-claim-detail="claimsDetail"
                  @close="closeOrderDetailWindow"
                  @update:details="saveClaimDetails"
                />
              </v-navigation-drawer>
              <v-navigation-drawer v-model="getShowCallPanel" absolute left temporary width="25%">
                <call-panel
                  v-if="showCallPanel"
                  :callPhone="callPhone"
                  :showCallPanel="showCallPanel"
                />
              </v-navigation-drawer>
            </div>
          </div>
        </template>
      </v-main>
    </v-app>
  </v-app>
</template>
<script>
import Claims from "@/components/Claims";
import ClaimDetail from "@/components/ClaimDetail";
import StatusBar from "@/components/StatusBar";
import { mapGetters } from "vuex";
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway";
import CallPanel from "@/components/CallPanel";

export default {
  mixins: [clickaway],
  created() {
    this.$store.dispatch("getCurrentUser", localStorage.getItem("userId"));
    this.checkRole();
  },
  mounted() {
    this.$store.dispatch("getUsersList");
    console.log("Компонент создан");
    if (this.getterCurrentUser.role === 2) {
      this.items2.pop();
    }
  },
  name: "Claimboard",
  components: {
    CallPanel,
    Claims,
    ClaimDetail,
    StatusBar
  },
  data: () => ({
    settingsMenu: false,
    callMenu: false,
    componentKey: 0,
    testValue: false,
    currentUser: "Anton",
    callPhone: "",

    items2: [
      {
        icon: "mdi-account-key",
        show: true,
        active: false,
        items: [
          { title: "Создать собственника", action: "openCreateOwners" },
          { title: "Редактировать собственников", action: "openEditOwners" },
          { title: "Загрузить собственников", action: "openUploadOwners" }
        ],
        title: "Собственники"
      },
      {
        icon: "mdi-account-hard-hat",
        active: false,
        show: true,
        items: [
          { title: "Создать исполнителя", action: "openCreateExecutor" },
          { title: "Создать специализацию", action: "openCreateClaimType" },
          { title: "Редактировать специализацию", action: "openEditClaimType" },
          { title: "Редактировать исполнителя", action: "openEditExecutor" }
        ],
        title: "Исполнители"
      },
      {
        icon: "mdi-home",
        show: true,
        active: false,
        items: [
          { title: "Создать дом", action: "openCreateHouse" },
          { title: "Редактировать дома", action: "openEditHouse" }
        ],
        title: "Дома"
      },
      {
        icon: "mdi-briefcase",
        show: true,
        active: false,
        items: [
          { title: "Создать компанию", action: "openCreateCompany" },
          { title: "Редактировать компанию", action: "openEditCompany" }
        ],
        title: "Компании"
      },
      // {
      //   icon: 'mdi-home-city-outline',
      //   active: false,
      //   items: [
      //     {title: 'Создать город', action: 'openCreateLocality'},
      //     {title: 'Редактировать города', action: 'openEditLocality'}
      //   ],
      //   title: 'Города',
      // },
      {
        icon: "mdi-account-group-outline",
        show: false,
        active: false,
        items: [{ title: "Редактировать пользователей", action: "openEditUsers" }],
        title: "Пользователи"
      }
    ],
    drawer: null,
    lockClaimDetail: false,
    showClaimDetails: false,
    showCallPanel: true,
    claimsDetail: {},
    showCreateCompany: false,
    baseClaimData: "",
    changedData: {},
    claimDetailKeys: ["claim_type", "executor", "emergency", "payable"],
    claimLogData: "",
    executorList: [],
    claimTypeList: [],
    userName: "",
    statusList: [
      { value: 1, text: "Принята" },
      { value: 2, text: "Исполнение" },
      { value: 3, text: "Закрыта" },
      { value: 4, text: "Отменена" }
    ],
    items: [
      { title: "Home", icon: "mdi-home-city" },
      { title: "My Account", icon: "mdi-account" },
      { title: "Users", icon: "mdi-account-group-outline" }
    ],
    mini: true
  }),
  methods: {
    closePanel() {
      this.$store.commit("setCallPanelVisibility", false);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    checkRole() {
      if (this.$store.state.currentUser.role !== 3) {
        this.items2.forEach(item => {
          if (item.title === "Пользователи") {
            item.show = true;
          }
        });
      }
    },
    logout() {
      localStorage.removeItem("token");
      window.location.reload();
      this.$store.commit("updateGetCurrentUser", {});
    },
    handle_function_call(function_name) {
      this[function_name]();
    },
    openEditSettings() {
      this.$refs.childComponent.openEditSettingsFunction();
      this.settingsMenu = false;
    },
    openCreateOwners() {
      this.$refs.childComponent.openCreateOwnerFunction();
      this.settingsMenu = false;
    },
    openEditOwners() {
      this.$refs.childComponent.editOwnerListFunction();
      this.$store.commit("editDialogUmount");
      this.$store.dispatch("getCompanyList");
      this.$store.dispatch("getPropertyOwners");
      this.settingsMenu = false;
    },
    openCreateExecutor() {
      this.$refs.childComponent.openCreateExecutorFunction();
      this.settingsMenu = false;
    },
    openCreateClaimType() {
      this.$refs.childComponent.openCreateClaimTypeFunction();
      this.settingsMenu = false;
    },
    openEditExecutor() {
      this.$refs.childComponent.openEditExecutorFunction();
      this.$store.commit("editDialogUmount");
      this.$store.dispatch("getExecutorList");
      this.settingsMenu = false;
    },
    openCreateHouse() {
      this.$refs.childComponent.openCreateHomeFunction();
      this.$store.dispatch("getCompanyList");
      this.settingsMenu = false;
    },
    openEditHouse() {
      this.$refs.childComponent.openEditHomeFunction();
      this.$store.commit("editDialogUmount");
      this.$store.dispatch("getHomeList");
      this.settingsMenu = false;
    },
    openCreateCompany() {
      this.$refs.childComponent.openCreateCompanyFunction();
      this.$store.dispatch("getLocalityList");
    },
    openEditCompany() {
      this.$refs.childComponent.openEditCompanyFunction();
      this.$store.commit("editDialogUmount");
      this.$store.dispatch("getCompanyList");
      this.settingsMenu = false;
    },
    openCreateLocality() {
      this.$refs.childComponent.openCreateLocalityFunction();
    },
    openEditLocality() {
      this.$refs.childComponent.openEditLocalityFunction();
      this.$store.commit("editDialogUmount");
      this.$store.dispatch("getLocalityList");
    },
    openEditUsers() {
      this.$refs.childComponent.openEditUsersFunction();
      this.settingsMenu = false;
    },
    openCreateClaim() {
      this.$refs.childComponent.openCreateClaimFunction();
      this.settingsMenu = false;
      this.$store.commit("hideSavingSuccessful");
    },
    openUploadOwners() {
      this.$refs.childComponent.uploadOwnersFunction();
      this.settingsMenu = false;
      this.settingsMenu = false;
    },
    openEditClaimType() {
      this.$refs.childComponent.openEditClaimTypeFunction();
    },
    async openOrderDetailWindow(id) {
      const claimDetailUrl = `${this.$store.getters.getServerUrl}/claim/${id}/`;
      this.claimsDetail = await fetch(claimDetailUrl, {
        headers: { Authorization: this.$store.state.auth.accessToken }
      }).then(response => response.json());
      ["planned_date", "closed"].forEach(key => {
        if (this.claimsDetail[key]) {
          this.claimsDetail[key] = moment(this.claimsDetail[key]).format("YYYY-MM-DD");
        }
      });
      this.showClaimDetails = true;
      this.baseClaimData = { ...this.claimsDetail };
      console.log("baseClaimData", this.baseClaimData);
      console.log("ClaimData", this.claimsDetail);
    },
    closeOrderDetailWindow() {
      this.showClaimDetails = false;
      this.$store.commit("canBeClosedCreateClaimDialog");
    },
    saveClaimDetails(value) {
      this.changedData["company"] = this.claimsDetail["company"];
      this.claimsDetail = value;
      Object.keys(this.claimsDetail).forEach(prop => {
        if (this.claimsDetail[prop] !== this.baseClaimData[prop]) {
          this.changedData[prop] = this.claimsDetail[prop];
          console.log("change prop", prop);
        }
      });
      var localLogData = [];
      console.log(localLogData);
      const pld = { id: this.baseClaimData.id, data: this.changedData };
      Object.keys(this.changedData).forEach(logData => {
        console.log("logData", logData);
        if (logData === "executor") {
          this.$store.dispatch("getExecutorList");
          this.executorList = this.$store.state.executorList;
          this.executorList.forEach(executors => {
            console.log("executors", executors);
            if (executors.id === this.changedData.executor) {
              this.claimLogData = `Изменён исполнитель на: ${executors.get_requisites}`;
              localLogData.push(this.claimLogData);
            }
          });
        } else if (logData === "contact_phone") {
          this.claimLogData = `Изменён контактный телефон с ${this.baseClaimData.contact_phone} на ${this.changedData.contact_phone}`;
          localLogData.push(this.claimLogData);
        } else if (logData === "status") {
          this.statusList.forEach(status => {
            if (status.value === this.changedData.status) {
              this.claimLogData = `Статус заявки: ${status.text}`;
              localLogData.push(this.claimLogData);
              console.log("claimLogStatus", status.text);
            }
          });
        } else if (logData === "claim_type") {
          this.$store.dispatch("getClaimTypeList");
          this.claimTypeList = this.$store.state.claimTypeList;
          this.claimTypeList.forEach(claimType => {
            if (claimType.id === this.changedData.claim_type) {
              this.claimLogData = `Изменён тип заявки на: ${claimType.name}`;
              localLogData.push(this.claimLogData);
            }
          });
        } else if (logData === "text") {
          this.claimLogData = `Изменен текст заявки: "${this.changedData.text}"`;
          localLogData.push(this.claimLogData);
        } else if (logData === "payable") {
          if (this.changedData.payable === true) {
            this.claimLogData = "Изменен статус заявки на: Платная";
            localLogData.push(this.claimLogData);
          } else if (this.changedData.payable === false) {
            this.claimLogData = "Удалён статус заявки: Платная";
            localLogData.push(this.claimLogData);
          }
        } else if (logData === "emergency") {
          if (this.changedData.emergency === true) {
            this.claimLogData = "Изменен статус заявки на: Аварийная";
            localLogData.push(this.claimLogData);
          } else if (this.changedData.emergency === false) {
            this.claimLogData = "Удалён статус заявки: Аварийная";
            localLogData.push(this.claimLogData);
          }
        } else if (logData === "planned_date") {
          const clearData = this.changedData.planned_date.split("T")[0];
          this.claimLogData = `Изменена плановая дата на: ${moment(clearData).format(
            "DD.MM.YYYY"
          )}`;
          localLogData.push(this.claimLogData);
        }
      });

      const pldLog = { message: localLogData.join("; "), data: this.baseClaimData };
      console.log("pldLog", pldLog);
      console.log("changeData", this.changedData);
      console.log("pld", pld.data);
      this.$store.dispatch("changeClaim", pld);
      if (this.claimLogData.length > 0) {
        this.$store.dispatch("addClaimLog", pldLog);
      }
      this.claimLogData = [];
      this.changedData = {};
      console.log("id", this.baseClaimData.id);
      console.log("payload", pld);
      this.baseClaimData = { ...this.claimsDetail };
      console.log("test", localLogData);
    },
    getUserName() {
      this.$store.state.usersList.forEach(user => {
        if (user.id === this.localClaimDetail.pointer) console.log("user", user.get_full_name);
      });
    }
  },
  computed: {
    ...mapGetters(["getSavingSuccessful", "getLockClaimDetail", "getterCurrentUser"]),
    isLogined() {
      return this.$store.state.auth.logined;
    },
    initials: function() {
      const first_name = this.getterCurrentUser.first_name;
      const last_name = this.getterCurrentUser.last_name;
      let initialFirst_name = "";
      let initialPatronymic = "";
      console.log("сработал initials");
      if (first_name.length > 0) {
        initialFirst_name = `${first_name.slice(0, 1)}.`;
      }
      if (this.getterCurrentUser.patronymic.length > 0) {
        initialPatronymic = `${this.getterCurrentUser.patronymic.slice(0, 1)}.`;
      }
      return `${last_name} ${initialFirst_name} ${initialPatronymic}`;
    },
    callPanelIsOpen: {
      get() {
        return this.$store.state.callPanel.isOpen;
      },
      set(value) {
        this.$store.commit("setCallPanelVisibility", value);
      }
    },
    getShowCallPanel: {
      get() {
        return this.$store.state.callPanel.isOpen;
      },
      set(newValue) {
        return this.$store.commit("setCallPanelVisibility", newValue);
      }
    }
  },
  watch: {
    showClaimDetails(newValue) {
      if (newValue === true) {
        console.log("changedData", this.changedData);
        this.lockClaimDetail = true;
        setTimeout(() => {
          this.lockClaimDetail = false;
        }, 5000);
      }
    }
  }
};
</script>

<style></style>
