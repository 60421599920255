import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
import ru from "vuetify/es5/locale/ru";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify, {
  iconfont: "md" // 'md' || 'mdi' || 'fa' || 'fa4'
});

export default new Vuetify({
  icons: {
    iconfont: "md"
  },
  lang: {
    locales: { ru },
    current: "ru"
  }
});
