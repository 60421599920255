<template>
  <v-card class="card-wrapper" height="100%" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class=" header" style="padding:24px ">
          <v-col>
            <span class="headline">Редактировать пользователя</span>
          </v-col>
        </v-card-title>
      </v-sheet>
      <v-card-text style="padding: 24px ;">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localUserDetail.username"
                  label="Логин"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localUserDetail.phone"
                  v-mask="'+7 (###) ###-##-##'"
                  label="Телефон"
                  @change="changes = true"
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localUserDetail.email"
                  label="Email"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localUserDetail.last_name"
                  label="Фамилия"
                  @change="changes = true"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localUserDetail.first_name"
                  label="Имя"
                  @change="changes = true"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localUserDetail.patronymic"
                  label="Отчество"
                  @change="changes = true"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <div>
        <v-sheet>
          <v-card-actions style="padding:22px">
            <v-icon v-if="getSavingSuccessful" color="#66BB6A">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-flex v-if="getSavingSuccessful" class="ml-2" style="color:#66BB6A">
              СОХРАНЕНО!
            </v-flex>
            <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
              <v-icon v-if="getSavingError" color="#EF5350">
                mdi-close-circle
              </v-icon>
              ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
            </v-flex>
            <v-spacer />
            <v-btn
              style="margin:12px "
              color="#fe81a1"
              min-width="135"
              tile
              outlined
              @click="closeEditOwnerDetail"
              >Закрыть
            </v-btn>
            <v-btn
              style="margin:12px "
              color="#6292a8"
              min-width="135"
              outlined
              tile
              @click="saveEditUserDetail"
              >Сохранить
            </v-btn>
          </v-card-actions>
        </v-sheet>
      </div>
    </template>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    userId: null
  },
  data: () => ({
    baseOwnerDetail: {},
    localUserDetail: {},
    changedData: {
      individual: {}
    },
    valid: true,
    changes: false
  }),
  created() {
    this.$store.dispatch("getLocalityList");
    this.baseUserDetail = { ...this.getDetailUser };
    this.localUserDetail = { ...this.getDetailUser };
  },
  methods: {
    closeEditOwnerDetail() {
      this.$emit("close");
    },
    saveEditUserDetail() {
      console.log("save", this.baseUserDetail);
      try {
        Object.keys(this.baseOwnerDetail).forEach(prop => {
          if (this.baseUserDetail[prop] !== this.localUserDetail[prop]) {
            this.changedData[prop] = this.localUserDetail[prop];
            console.log("prop", this.baseUserDetail[prop], this.localUserDetail[prop]);
            if (this.changedData["phone"]) {
              this.changedData["phone"] = this.changedData["phone"].replace(/[-() ]/g, "");
              this.changedData["phone"] = this.changedData["phone"].replace("+", "");
            }
          }
        });
      } catch (e) {
        console.log("block 1");
      }
      console.log("base -", this.baseOwnerDetail, "local-", this.localOwnerDetail);
      console.log("changedData", this.changedData, this.changedData.individual);
      if (this.localUserDetail.phone) {
        this.clearPhone = this.localUserDetail.phone.replace(/[-() ]/g, "");
        this.clearPhone = this.clearPhone.replace("+", "");
        this.localUserDetail.phone = this.clearPhone;
      }
      console.log("localOwnerDetail", this.localOwnerDetail);
      this.$store.dispatch("editUser", {
        id: this.userId,
        data: this.localUserDetail
      });
      this.$store.dispatch("getLocalityList");
    }
  },
  changeClaim() {
    console.log("changeClaim");
  },
  computed: {
    ...mapGetters(["localityList", "getSavingSuccessful", "getSavingError"]),
    getDetailUser() {
      return this.$store.state.userDetail;
    }
  }
};
</script>
<style lang="scss"></style>
