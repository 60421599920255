<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" :persistent="getPersistentCreateClaimDialog" width="40%">
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px ">
                <v-col>
                  <span class="headline">Загрузить собственников</span>
                </v-col>
              </v-card-title>

              <v-card-text style="padding:24px ">
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="company"
                          :items="companyList"
                          :rules="requiredRules"
                          label="Компания"
                          @change="company => changeCompany(company)"
                        ></v-select>
                      </v-col>
                      <v-col  sm="6">
                        <v-select
                          v-model="home"
                          :items="$store.state.relativeHouseList"
                          :rules="requiredRules"
                          label="Адрес"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col  sm="6">
                        <v-file-input
                          accept=".xlsx"
                          hint="Скачайте, заполните и загрузите файл"
                          label="Загрузите файл"
                          @change="selectFile"
                        ></v-file-input>
                      </v-col>
                      <v-col sm="2">
                        <v-btn
                          style="margin:12px 0 0 0 "
                          tile
                          color="#81C784"
                          enctype="application/vnd.ms-excel"
                          href="template.xlsx"
                          outlined
                        >
                          Скачать шаблон
                          <v-icon dark right>
                            mdi-cloud-download-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions  style="justify-content: end; padding:24px ">
                <v-flex
                  v-if="getSavingSuccessful"
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>

                  СОХРАНЕНО!
                </v-flex>
                <v-flex v-if="getSavingError" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
                </v-flex>

                <v-btn
                  style="margin:12px "
                  min-width="135"
                  tile
                  color="#fe81a1"
                  outlined
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  style="margin:12px "
                  min-width="135"
                  tile
                  color="#6292a8"
                  outlined
                  @click="saveUploadOwners"
                >
                  Загрузить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  async mounted() {
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getHouseList");
  },
  props: {
    showUploadOwners: {
      type: Boolean
    }
  },
  data: () => ({
    downloadUrl: `${process.env.VUE_APP_BASE_URL}/public/template.xls`,
    dialog: false,
    company: Number,
    home: Number,
    file: null,
    requiredRules: [v => !!v || "Это поле обязательное"]
  }),
  methods: {
    changeCompany(companyId) {
      this.$store
        .dispatch("getRelativeHouseList", companyId)
        .then(response => {
          console.log("response changeCompany", response);
        })
        .catch(e => {
          console.log(e);
        });
    },
    getProcess() {
      console.log("process", process.env.VUE_APP_BASE_URL);
      return `${process.env.VUE_APP_BASE_URL}/public/template.xls`;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    getDialogUploadOwners() {
      this.dialog = true;
    },
    saveUploadOwners() {
      if (!this.file) {
        this.message = "vfv";
      }
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("company", this.company);
      formData.append("address", this.home);
      this.$store.dispatch("saveUploadOwners", formData);
      this.$emit("close");
      console.log("choseFile", this.formData);
      this.$store.commit("showSavingSuccessful");
      setTimeout(() => {
        this.$store.commit("hideSavingSuccessful");
        this.$store.dispatch("getLocalityList");
        this.closeDialog();
      }, 1500);
    },
    selectFile(file) {
      this.file = file;
    }
  },
  computed: {
    ...mapGetters([
      "HouseList",
      "companyList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "getterRelativeHouseList"
    ])
  }
  // watch: {
  //    formData: {
  //     handler: function () {
  //       this.$store.commit('canNotBeClosedCreateClaimDialog')
  //       console.log('сработал commit canNotBeClosedCreateClaimDialog')
  //  },
  //    deep: true
  //  },
  // }
};
</script>
