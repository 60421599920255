<!-- TODO все компоненты редактирования населенных пунктов не используется  -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1150px" color="#FF8A80">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer
            v-model="showEditLocalityDialog"
            temporary
            width="888px"
            absolute
            right
          >
            <edit-locality-detail
              v-if="showEditLocalityDialog"
              :locality-id="currentLocalityId"
              @close="showEditLocalityDialog = false"
            />
          </v-navigation-drawer>
        </div>
        <v-layout>
          <v-flex>
            <v-card height="878px">
              <v-card-title class="py-6 header">
                <v-row>
                  <v-col>
                    <span class="headline">Редактировать населенных пунктов</span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-row class="ml-2 mr-2">
                <v-col class="ml-4 mr-4 mt-6 search_panel">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="ml-2 mr-2">
                <v-col class="ml-2 mr-2">
                  <v-data-table
                    :headers="dataHeaders"
                    class="order-list__table"
                    :items="localityList"
                    :search="search"
                    height="520"
                    calculate-widths
                    fixed-header
                    dense
                    :footer-props="tableFooterProps"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="editDetailLocality(item.value)"
                        >
                          <td class="text-left" style="cursor:pointer">{{ item.text }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="#fe81a1" text @click="closeDialog" tile style="margin:12px" outlined>
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EditLocalityDetail from "@/components/EditLocalityDetail";

export default {
  components: {
    EditLocalityDetail
  },
  props: {
    showEditLocality: {
      type: Boolean
    }
  },
  data: () => ({
    dialog: false,
    showEditLocalityDialog: false,
    currentLocalityId: null,
    benched: 0,
    search: "",
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [{ text: "Город", value: "text" }]
  }),
  watch: {
    showEditCompany(value) {
      if (value) {
        this.$store.dispatch("getCompanyList");
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditLocality() {
      this.dialog = true;
    },
    async editDetailLocality(id) {
      this.$store.commit("editDialogMount");
      await this.getLocalityDetail(id);
      this.currentLocalityId = id;
      this.showEditLocalityDialog = true;
    },
    deleteCompany(id) {
      this.deleteCompanyAction(id);
    },
    ...mapActions({
      deleteCompanyAction: "deleteCompany",
      getLocalityDetail: "getLocalityDetail"
    })
  },
  computed: {
    ...mapGetters(["localityList", "getLazyLoadEditDialog"])
  },
  mounted() {
    this.$store.dispatch("getLocalityList");
  }
};
</script>

<style>
.hover:hover {
  color: #1976d2;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}
</style>
