<!-- Создания населенного пункта. Компонент подключен, но не используется. На всякий случай оставлю -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        scrollable
        width="50%"
        :persistent="getPersistentCreateClaimDialog"
      >
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px">
                <v-col>
                  <span class="headline"><b>Создать населенный пункт</b></span>
                </v-col>
              </v-card-title>

              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col sm="6">
                        <v-select
                          v-model="formData.prefix"
                          :items="getterCityTypeList"
                          :rules="requiredRules"
                          label="Тип населенного пункта"
                          required
                        ></v-select>
                      </v-col>
                      <v-col sm="6">
                        <v-text-field
                          v-model="formData.locality"
                          :rules="requiredRules"
                          label="Название"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions style="padding:24px; justify-content: end;">
                <v-flex
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  v-if="getSavingSuccessful"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>

                  СОХРАНЕНО!
                </v-flex>
                <v-flex class="ml-5 mb-7" v-if="getSavingError" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
                </v-flex>

                <v-btn
                  style="margin:12px"
                  color="#fe81a1"
                  outlined
                  tile
                  min-width="135"
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  style="margin:12px"
                  color="#6292a8"
                  outlined
                  tile
                  min-width="135"
                  @click="saveLocality"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  async mounted() {
    this.$store.dispatch("getCityTypeList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateLocality: {
      type: Boolean
    }
  },
  data: () => ({
    dialog: false,
    formData: {
      prefix: "",
      locality: ""
    },
    requiredRules: [v => !!v || "Это поле обязательное"]
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
    },
    getDialogCreateLocality() {
      this.dialog = true;
    },
    saveLocality() {
      this.$store.dispatch("saveLocality", this.formData);
      console.log("formData", this.formData);
      this.$store.commit("showSavingSuccessful");
      setTimeout(() => {
        this.$store.commit("hideSavingSuccessful");
        this.closeDialog();
      }, 1500);
    }
  },
  computed: {
    ...mapGetters([
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "getErrorText",
      // "localityTypes",
      "getterCityTypeList"
    ])
  },
  watch: {
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
      },
      deep: true
    }
  }
};
</script>
