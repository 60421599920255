<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="60%">
        <div v-if="getLazyLoadEditDialog">
          <v-navigation-drawer
            v-model="showEditUserDialog"
            :permanent="getLockClaimDetail"
            absolute
            right
            temporary
          >
            <edit-user-detail
              v-if="showEditUserDialog"
              :user-id="currentUserId"
              @close="showEditUserDialog = false"
            />
          </v-navigation-drawer>
        </div>
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px ">
                <v-col>
                  <span class="headline">Список пользователей</span>
                </v-col>
              </v-card-title>
              <v-row class="ml-4 mr-4">
                <v-col class="ml-2 mr-2 mt-4 ">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    label="Поиск"
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="ml-9 mr-9">
                  <v-data-table
                    :footer-props="tableFooterProps"
                    :headers="dataHeaders"
                    :items="getUsersList"
                    :search="search"
                    calculate-widths
                    dense
                    fixed-header
                    height="40vh"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.value"
                          cursor="pointer"
                          @dblclick="getUserDetailMethod(item.value)"
                        >
                          <td class="table_instruction" style="cursor:pointer">
                            {{ item.username }}
                          </td>
                          <td class="table_instruction" style="cursor:pointer">{{ item.text }}</td>
                          <td class="table_instruction" style="cursor:pointer">{{ item.email }}</td>
                          <td class="table_instruction" style="cursor:pointer">{{ item.phone }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-card-actions style="padding: 24px; justify-content: end;">
                <v-btn
                  color="#fe81a1"
                  outlined
                  tile
                  min-width="135"
                  style="margin:12px"
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditUserDetail from "@/components/EditUserDetail";

export default {
  components: {
    EditUserDetail
  },
  props: {
    showEditHome: {
      type: Boolean
    }
  },
  data: () => ({
    lockHomeDetail: true,
    dialog: false,
    showEditUserDialog: false,
    currentLocalityId: null,
    benched: 0,
    currentUserId: null,
    search: "",
    tableFooterProps: {
      disableItemsPerPage: false,
      itemsPerPageOptions: [20, 100],
      itemsPerPageText: "Строк"
    },
    dataHeaders: [
      { text: "Логин", value: "username", width: "15%" },
      { text: "Пользователь", value: "get_full_name", width: "15%" },
      { text: "Email", value: "email", width: "15%" },
      { text: "Телефон", value: "phone", width: "15%" }
    ]
  }),
  watch: {
    showEditCompany(value) {
      if (value) {
        this.$store.dispatch("getCompanyList");
      }
    },
    homeList() {
      return this.$store.getters.getterHomeList;
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getDialogEditUsers() {
      this.dialog = true;
    },
    async getUserDetailMethod(id) {
      this.$store.commit("editDialogMount");
      console.log("сработал getUserDetail");
      await this.getUserDetail(id);
      this.currentUserId = id;
      this.showEditUserDialog = true;
    },
    deleteCompany(id) {
      this.deleteCompanyAction(id);
    },
    ...mapActions({
      deleteCompanyAction: "deleteCompany",
      getUserDetail: "getUserDetail"
    })
  },
  computed: {
    ...mapGetters(["getLazyLoadEditDialog", "getLockClaimDetail", "getUsersList"]),
    homeList() {
      return this.$store.getters.getterHomeList;
    }
  },

  mounted() {
    this.$store.dispatch("getUsersList");
  }
};
</script>

<style>
.table_instruction {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
  text-align: left;
}
</style>
