<!--TODO Компонент подключен к главной таблицы, но похоже не используется  -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog :value="getDialogEditClaim" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Редактирование заявки № {{ getterClaimDetail.id }} </span>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8">
                <v-select
                  v-model="formData.status"
                  :items="statusList"
                  :label="getterClaimDetail.status"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-card-text>
            <small>* Обязательные поля</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" tile text @click="closeDialogEditClaim">
              Закрыть
            </v-btn>
            <v-btn color="blue darken-1" tile text @click="saveClaim">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    claimDetail: {
      type: Object
    }
  },
  data: () => ({
    formData: {
      status: null
    },
    statusList: [
      { value: 1, text: "Принята" },
      { value: 2, text: "Передана на исполнение" },
      { value: 3, text: "Закрыта" }
    ],

    date: new Date().toISOString().substring(0, 10),
    menu: false,
    modal: false
  }),
  methods: {
    saveClaim() {
      this.formData.planned_date = `${this.formData.planned_date}T00:00`;
      this.$store.dispatch("saveClaims", this.formData);
      console.log("formData", this.formData);
      this.closeDialogCreateClaim();
    },
    closeDialogEditClaim() {
      this.$store.commit("hideDialogEditClaim");
    }
  },
  computed: {
    ...mapGetters(["getDialogEditClaim", "getterClaimDetail", "companyList"]),
    getStatus() {
      const status = this.getterClaimDetail.status;
      const res = {};
      res.value = status;
      res.text = "Принята";
      return res;
    }
  }
};
</script>
