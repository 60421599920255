<template>
  <div class="login">
    <v-container>
      <div style="display:flex; justify-content: center; ">
        <v-card min-width="500" width="500">
          <v-card-title class="pa-8 primary" >
            <span v-if="registration" class="headline white--text" style="padding-left: 12px;">
              Регистрация
            </span>
            <span v-if="!registration" class="headline white--text" style="padding-left: 12px;" >
              Авторизация
            </span>
          </v-card-title>
          <v-card-text class="pa-8">
            <v-form ref="form">
              <v-container>
                <v-row>
                  <transition name="fade">
                    <v-col v-if="successRegistration" sm="12">
                      <h2 style="color: #66BB6A;">
                        Вы успешно зарегистрировались на сайте!
                      </h2>
                      <span> Логин и пароль отправлены на почту </span>
                    </v-col>
                  </transition>
                </v-row>
                <v-row>
                  <v-col v-if="!registration" sm="12">
                    <v-tooltip right v-model="loginHelp">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataLogin.login"
                          dense
                          hide-details
                          label="Логин"
                          light
                          outlined
                          @click="loginHelp = false"
                        >
                          <v-icon
                            v-on="on"
                            v-bind="attrs"
                            size="20"
                            slot="append"
                            color="#E0E0E0"
                            @click="loginHelp = !loginHelp"
                          >
                            mdi-help-circle
                          </v-icon>
                        </v-text-field>
                      </template>
                      <span>Введите логин, который вы установили</span><br />
                      <span>при регистрации.</span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="!registration" sm="12">
                    <v-tooltip right v-model="passwordHelp">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataLogin.password"
                          dense
                          hide-details
                          label="Пароль"
                          light
                          outlined
                          type="password"
                          v-on:keyup.enter="logIn"
                          @click="passwordHelp = false"
                        >
                          <v-icon
                            v-on="on"
                            v-bind="attrs"
                            size="20"
                            slot="append"
                            color="#E0E0E0"
                            @mouseover="passwordHelp = true"
                            @mouseleave="passwordHelp = false"
                          >
                            mdi-help-circle
                          </v-icon>
                        </v-text-field>
                      </template>
                      <span>Введите пароль, полученный на почту </span><br />
                      <span>при регистрации.</span><br />
                    </v-tooltip>
                  </v-col>

                  <v-col v-if="registration" sm="12">
                    <v-tooltip right v-model="loginSignUpHelp">
                      <template v-slot:activator="{ on, attrs }">
                        <div></div>
                        <v-text-field
                          v-model="dataSignUp.username"
                          :rules="requiredRules"
                          dense
                          hide-details
                          label="Логин"
                          light
                          outlined
                          @click="loginSignUpHelp = false"
                        >
                          <v-icon
                            v-on="on"
                            v-bind="attrs"
                            size="20"
                            color="#E0E0E0"
                            slot="append"
                            @mouseover="loginSignUpHelp = true"
                            @mouseleave="loginSignUpHelp = false"
                          >
                            mdi-help-circle
                          </v-icon>
                        </v-text-field>
                      </template>
                      <span>Введите login, в последующем он Вам </span><br />
                      <span> понадобится при входе. </span><br />
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="registration" cols="12" sm="12">
                    <v-text-field
                      v-model="dataSignUp.last_name"
                      :rules="requiredRules"
                      dense
                      hide-details
                      label="Фамилия"
                      light
                      outlined
                    >
                      <v-tooltip left>
                        <template>
                          <v-icon small slot="append" size="20" color="E0E0E0">
                            mdi-help-circle
                          </v-icon>
                        </template>
                      </v-tooltip>
                    </v-text-field>
                  </v-col>
                  <v-col v-if="registration" sm="12">
                    <v-text-field
                      v-model="dataSignUp.first_name"
                      :rules="requiredRules"
                      dense
                      hide-details
                      label="Имя"
                      light
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="verifySuccess" color="success" cols="4" large sm="4">
                    <v-icon color="#81C784">mdi-checkbox-marked-circle-outline</v-icon>
                  </v-col>
                  <v-col v-if="registration" sm="12">
                    <v-tooltip right nudge-top="15" v-model="emailSignUpHelp">
                      <!-- style="color: rgb(254, 129, 161) !important; caret-color: rgb(254, 129, 161) !important;" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataSignUp.email"
                          :rules="emailRules"
                          dense
                          label="E-mail"
                          light
                          outlined
                          @click="emailSignUpHelp = false"
                        >
                          <v-icon
                            v-on="on"
                            v-bind="attrs"
                            slot="append"
                            size="20"
                            color="#E0E0E0"
                            @click="emailSignUpHelpMethod"
                          >
                            mdi-help-circle
                          </v-icon>
                        </v-text-field>
                      </template>
                      <span>Введите email, письмо с паролем будет отправлено</span><br />
                      <span>на указанный Вами почтовый ящик.</span><br />
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="registration" sm="7">
                    <v-tooltip right :nudge-right="toConfirm ? 180 : 0" v-model="phoneSignUpHelp">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataSignUp.phone"
                          v-mask="'+7 (9##) ###-##-##'"
                          :append-icon="showIcon ? 'mdi-checkbox-marked-circle-outline' : undefined"
                          :label="labelPhone"
                          :rules="requiredRules"
                          placeholder="+7 (9"
                          dense
                          light
                          class="mb-2"
                          required
                          outlined
                          @click="clickPhone"
                          v-on:keyup.enter="logIn"
                        >
                          <v-icon v-on="on" v-bind="attrs" slot="append" size="20" color="#E0E0E0">
                            mdi-help-circle
                          </v-icon>
                        </v-text-field>
                      </template>
                      <span>Введите номер мобильного телефона,</span><br />
                      <span>на него поступит звонок, в поле </span><br />
                      <span>подтвердить нужно ввести последние</span><br />
                      <span>4 цифры определившегося номера. </span><br />
                    </v-tooltip>
                  </v-col>
                  <transition name="fade">
                    <v-col style="margin: 1px 0 0 7px;" v-if="toConfirm" sm="4">
                      <v-btn
                        :disabled="loading"
                        :loading="loading"
                        color="#6292a8"
                        outlined
                        tile
                        @click="getConfirmPhone"
                      >
                        Подтвердить
                      </v-btn>
                    </v-col>
                  </transition>
                  <v-col v-if="passCodeShow" sm="4">
                    <v-text-field
                      v-model="passCode"
                      :disabled="passCodeDisabled"
                      dense
                      hint="Последние 4 цифры"
                      label="Введите код"
                      maxlength="4"
                    >
                    </v-text-field>
                  </v-col>
                  <div class="errorBlock">{{ errorValue }}</div>

                  <v-col sm="12" style="justify-content: center; display: flex;">
                    <v-btn
                      v-if="!registration"
                      tile
                      color="#6292a8"
                      min-width="135"
                      outlined
                      @click="logIn"
                      >Войти
                    </v-btn>
                    <v-btn
                      v-if="registration"
                      :disabled="!passCodeDisabled"
                      tile
                      color="#6292a8"
                      outlined
                      @click="signUp"
                      >Зарегистрироваться
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card-actions style="justify-content: center;">
                      <v-btn
                        class="btn_hover"
                        v-if="!registration"
                        color="#6292a8"
                        text
                        tile
                        @click="changeRegistration"
                      >
                        Регистрация
                      </v-btn>
                      <v-btn
                        class="btn_hover"
                        v-if="!registration"
                        :href="forgotPasswordUrl"
                        color="#6292a8"
                        text
                        tile
                      >
                        Забыли пароль?
                      </v-btn>
                      <v-btn
                        color="#6292a8"
                        v-if="registration"
                        outlined
                        tile
                        min-width="135"
                        @click="changeRegistration"
                      >
                        Назад
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style=" padding: 0 0 0 0; margin: 0 0 0 0;">
                    <p
                      style="justify-content: center; display: flex; padding: 0 0 0 0; margin: 0 0 0 0;"
                    >
                      Если возникли вопросы, звоните&ensp;
                      <a href="tel: +73812207234"> +7(3812) 207-234</a>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import config from "@/config";
import axios from "axios";

export default {
  data: () => ({
    loginHelp: false,
    passwordHelp: false,
    phoneSignUpHelp: false,
    emailSignUpHelp: false,
    loginSignUpHelp: false,
    bgImage: "/images/operator.jpg",
    errorValue: "",
    errorValueShow: false,
    successRegistration: false,
    showIcon: false,
    genID: "",
    passCode: "",
    passCodeShow: false,
    verifySuccess: false,
    passCodeDisabled: false,
    forgotPasswordUrl: "",
    email: "",
    verifyCode: {
      1630574722638: "7516",
      1630574733562: "7418"
    },
    toConfirm: false,
    labelPhone: "Телефон",
    loader: null,
    loading: false,
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "Введите действительный email"
    ],
    requiredRules: [v => !!v || "Это поле обязательное"],
    registration: false,
    dataLogin: {
      login: "",
      password: ""
    },
    dataSignUp: {
      username: "",
      phone: "",
      email: "",
      first_name: "",
      last_name: ""
    }
  }),
  methods: {
    emailSignUpHelpMethod() {
      this.emailSignUpHelp = !this.emailSignUpHelp;
      this.loginHelp = false;
      this.passwordHelp = false;
      this.phoneSignUpHelp = false;
      this.loginSignUpHelp = false;
    },
    forgotPassword() {
      const url = config.BACKEND_URL.split("/");
      const resetPassword = `${url[0]}//${url[2]}/reset-password/`;
      this.forgotPasswordUrl = resetPassword;
    },
    getConfirm() {
      this.loader = "loading";
    },
    changeRegistration() {
      this.errorValue = "";
      this.registration = !this.registration;
      this.$refs.form.reset();
      this.passCode = "";
      this.successRegistration = false;
      this.passCodeShow = false;
      this.showIcon = false;
      this.loginHelp = false;
      this.passwordHelp = false;
      this.phoneSignUpHelp = false;
      this.emailSignUpHelp = false;
      this.loginSignUpHelp = false;
    },
    logIn() {
      this.$store.commit("setErrorLoginValue", "");
      localStorage.setItem("phoneLogin", "");
      localStorage.setItem("phonePassword", "");
      this.errorValue = "";
      this.$store.dispatch("login", {
        login: this.dataLogin.login,
        password: this.dataLogin.password
      });
      setTimeout(() => {
        this.errorValue = this.$store.state.errorLoginValue;
      }, 200);
      setTimeout(() => {
        this.$store.commit("setErrorLoginValue", "");
        this.errorValue = this.$store.state.errorLoginValue;
      }, 4000);
    },

    async signUp() {
      this.errorValue = "";
      if (this.$refs.form.validate()) {
        console.log(this.dataSignUp);
        let phone = this.dataSignUp.phone;
        phone = phone.replace(/[-() ]/g, "");
        phone = phone.replace("+", "");
        try {
          var res = await axios.post(`${config.BACKEND_URL}/user/create-admin/`, {
            last_name: this.dataSignUp.last_name,
            first_name: this.dataSignUp.first_name,
            email: this.dataSignUp.email,
            phone: phone,
            username: this.dataSignUp.username,
            role: 3
          });
          const payload = {
            username: this.dataSignUp.username,
            userId: res.data.id
          };
          const user = this.dataSignUp;
          const text = `Зарегистрирован новый пользователь\n login: ${user.username}\nФамилия: ${user.last_name}
          \nИмя: ${user.first_name}\nEmail: ${user.email}\nТелефон: ${user.phone}`;
          await this.$store.dispatch("sendMessageToTelegram", {
            chat_id: "-1001671404073",
            text: text
          });
          await this.$store.dispatch("setCompanyInUser", payload);
          this.$metrika.reachGoal("registration_success");
        } catch (e) {
          console.log(e.response.data);
          const errors = e.response.data;
          Object.keys(errors).forEach(error => {
            console.log(error);
            console.log(`Ошибка ${error}: ${errors[error]}`);
            this.errorValue = `Ошибка ${error}: ${errors[error]}`;
          });
        }
      }
      if (typeof res != "undefined") {
        if (res.status === 201) {
          this.changeRegistration();
          this.passCodeShow = false;
          this.successRegistration = true;
        }
      }
    },
    clickPhone() {
      // console.log("clickPhone");
      // this.dataSignUp.phone = "+7 (9";
      console.log("formDataPhone", this.dataSignUp.phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    },
    async getConfirmPhone() {
      this.loader = "loading";

      function genID() {
        return new Date().getTime();
      }

      this.genID = genID();
      const addressUrl = `${config.BACKEND_URL}/user/confirm-phone/`;
      var clearPhone = this.dataSignUp.phone.replace(/[-() ]/g, "");
      const data = { recipient: clearPhone, id: this.genID };
      try {
        const res = await axios.post(addressUrl, data);
        console.log(res.data);
        const response = JSON.parse(res.data);
        if (response.success === true) {
          this.verifyCode[response.result.id] = response.result.code;
          console.log(this.verifyCode);
          this.toConfirm = false;
          setTimeout(() => {
            this.passCodeShow = true;
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      console.log(this[l]);
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
    "dataSignUp.phone": function(newVal) {
      if (newVal != null) {
        if (newVal.length === 18) {
          this.toConfirm = true;
          this.phoneSignUpHelp = false;
        } else {
          this.toConfirm = false;
          this.phoneSignUpHelp = false;
          this.showIcon = false;
          this.passCodeShow = false;
          this.passCode = "";
        }
      } else {
        this.toConfirm = false;
      }
    },
    passCode(newVal) {
      if (newVal != null) {
        if (newVal.length === 4) {
          if (this.passCode === this.verifyCode[this.genID]) {
            this.passCodeDisabled = true;
            this.showIcon = true;
          }
        }
      }
    }
  },
  mounted() {
    this.forgotPassword();
  }
};
</script>

<style lang="scss">
.login {
  background-image: url("~@/assets/images/operator.jpg");
}

.btn_hover:hover {
  outline: solid 1px;
}
.errorBlock {
  width: 100%;
  height: 20px;
  padding-left: 16px;
}
</style>
