<template>
  <v-row style="justify-content: center; padding: 0 20px 0 0;" justify="center">
    <v-layout>
      <v-flex style="display: flex; justify-content: space-around;" >
        <v-card flat height="595" width="900">
          <v-card-text>
            <v-form ref="createUserForm">
              <v-container>
                <v-row>
                  <v-col sm="4">
                    <v-text-field
                      v-model="formData.username"
                      :rules="requiredRules"
                      label="Логин"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col  sm="4">
                    <v-text-field
                      v-model="formData.phone"
                      v-mask="'+7 (###) ###-##-##'"
                      :label="labelPhone"
                      :rules="lengthPhone"
                      required
                      @click="clickPhone"
                    ></v-text-field>
                  </v-col>

                  <v-col sm="4">
                    <v-text-field
                      v-model="formData.last_name"
                      :rules="requiredRules"
                      label="Фамилия"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col :sm="4">
                    <v-text-field
                      v-model="formData.first_name"
                      :rules="requiredRules"
                      label="Имя"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col :sm="4">
                    <v-text-field
                      v-model="formData.patronymic"
                      :rules="requiredRules"
                      label="Отчество"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col  sm="4">
                    <v-autocomplete
                      v-model="formData.companyList"
                      :items="companyList"
                      :rules="requiredRules"
                      label="Компания"
                      multiple
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col sm="4">
                    <v-text-field
                      v-model="formData.email"
                      :rules="emailRules"
                      label="email"
                      required
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="4">
                    <v-text-field
                      v-model="formData.password"
                      :rules="requiredRules"
                      label="Пароль"
                      required
                      type="password"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions style="justify-content: end; padding:26px">
            <v-flex
              v-if="getSavingSuccessful"
              absolute
              class="ml-2 mb-7"
              height="calc(100% - 10px)"
              style="color:#66BB6A"
            >
              <v-icon color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>

              СОХРАНЕНО!
            </v-flex>
            <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
              <v-icon v-if="getSavingError" color="#EF5350">
                mdi-close-circle
              </v-icon>
              ОШИБКА <span v-if="errorValue">{{ errorValue }}</span>
            </v-flex>

            <v-btn color="#fe81a1" min-width="135" tile outlined @click="closeTab">
              Закрыть
            </v-btn>
            <v-btn
              color="#6292a8"
              min-width="135"
              tile
              outlined
              style="margin-left:32px"
              @click="saveUser"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { VueMaskDirective } from "v-mask";

Vue.directive("mask", VueMaskDirective);

export default {
  async mounted() {
    this.$store.dispatch("getCompanyList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateExecutor: {
      type: Boolean
    }
  },
  data: () => ({
    formData: {
      companyList: [],
      username: "",
      email: "",
      phone: "",
      first_name: "",
      last_name: "",
      patronymic: ""
    },
    errorValue: "",
    labelPhone: "Телефон +7 (XXX) XXX-XX-XX",
    requiredRules: [v => !!v || "Это поле обязательное"],
    lengthPhone: [
      v => !!v || "Это поле обязательное",
      v => (v && v.length > 17) || "Введите корректно номер"
    ],
    emailRules: [
      v => !!v || "Это поле обязательное",
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid"
    ]
  }),
  methods: {
    //TODO не используется
    // formReset() {
    //   this.$refs.createUserForm.reset()
    //   this.$store.commit('hideSavingError')
    // },
    closeTab() {
      this.$emit("closeTab");
      this.formData.phone = "";
      this.$refs.createUserForm.reset();
      this.$store.commit("hideSavingError");
    },
    saveUser() {
      this.$store.commit("hideSavingError");
      if (this.$refs.createUserForm.validate()) {
        this.$store
          .dispatch("createUser", this.formData)
          .then(response => {
            console.log("success", response);
            this.$store.commit("showSavingSuccessful");
            setTimeout(() => {
              this.$store.commit("hideSavingSuccessful");
              this.closeTab();
            }, 3000);
          })
          .catch(e => {
            const error = `${Object.keys(e.response.data)[0]}: ${
              Object.values(e.response.data)[0]
            }`;
            this.errorValue = error;
            this.$store.commit("showSavingError");
            console.log("error%%%", error);
          });
      }
    },
    clickPhone() {
      console.log("clickPhone");
      this.formData.phone = "+7 (";
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    }
  },
  computed: {
    ...mapGetters([
      "companyList",
      "getClaimTypeList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError"
    ])
  },
  watch: {
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
        console.log("сработал commit canNotBeClosedCreateClaimDialog");
      },
      deep: true
    }
  }
};
</script>
