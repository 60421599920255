import Vue from "vue";
import VueRouter from "vue-router";
//import Claims from './components/Claims'
import ClaimBoard from "./views/Claimboard";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Navbar from "@/components/Navbar";
import CreateCompany from "@/components/CreateCompany";
import Preset from "@/components/Preset";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "claimboard",
    component: ClaimBoard,
    meta: { requiresAuth: true }
  },
  {
    path: "/navbar",
    name: "navbar",
    component: Navbar,
    meta: { requiresAuth: true }
  },
  {
    path: "/cr",
    name: "cr",
    component: CreateCompany,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup
  },
  {
    path: "/preset",
    name: "preset",
    component: Preset
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const logined = !!(token !== null && token && token !== "Token ");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!logined && to.path !== "/login") {
      next({ name: "login" });
    } else if (to.path === "/login") {
      next({ name: "claimboard" });
    } else {
      next();
    }
  } else if (logined && to.path === "/login") {
    next(from);
  } else {
    next();
  }
});

export default router;
