<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        overlay-color="#757575"
        overlay-opacity="0.8"
        persistent
        scrollable
        transition="dialog-bottom-transition"
        width="50%"
      >
        <v-card background-color="teal darken-3" show-arrows tile width="2000">
          <v-toolbar color="primary" dark flat height="120" max-height="120">
            <v-toolbar-title>Предварительные настройки</v-toolbar-title>
          </v-toolbar>
          <v-stepper v-model="e1" alt-labels flat non-linear>
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" editable step="1">
                Компания
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" editable step="2">
                Дом
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" editable step="3">
                Собственник
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 4" editable step="4">
                Исполнитель
              </v-stepper-step>
            </v-stepper-header>

            <v-row>
              <v-col>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        v-if="e1 === 1"
                        style=" justify-content: space-around; display: flex;"
                        label
                      >
                        <v-chip large label class="ml-6" color="#E1F5FE" text-color="blue"
                          >Для полноценной работы, необходимы предварительные настройки. Введите ИНН
                          Вашей УК.
                        </v-chip>
                      </v-col>
                      <v-col
                        v-if="e1 === 2"
                        style="justify-content: space-around; display: flex;"
                        label
                      >
                        <v-chip large label class="ml-6" color="#E1F5FE" text-color="blue">
                          Введите адрес обслуживаемого дома.
                        </v-chip>
                      </v-col>
                      <v-col
                        v-if="e1 === 3"
                        style="justify-content: space-around; display: flex;"
                        label
                      >
                        <v-chip large label class="ml-6" color="#E1F5FE" text-color="blue">
                          Введите данные любого собственника, остальных Вы сможете загрузить позже
                          списком.
                        </v-chip>
                      </v-col>
                      <v-col
                        v-if="e1 === 4"
                        style="justify-content: space-around; display: flex;"
                        label
                      >
                        <v-chip large label class="ml-6" color="#E1F5FE" text-color="blue">
                          Введите данные исполнителя, на которого Вы сможете назначать заявки.
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
            <v-stepper-items>
              <v-stepper-content
                step="1"
                style="justify-content: space-around; display: flex; padding:0 0 0 360px"
              >
                <v-card flat height="100vh" width="800">
                  <v-card-text>
                    <v-form ref="form">
                      <v-container>
                        <v-row>
                          <v-col cols="4" sm="3">
                            <v-text-field
                              v-model="formCompany.inn"
                              hint="Название компании заполнится автоматически"
                              label="ИНН"
                              maxlength="12"
                              placeholder="Введите ИНН компании"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="6">
                            <v-btn
                              color="#81C784"
                              outlined
                              tile
                              min-width="135"
                              style="margin:13px 0 0 33px"
                              @click="searchCompany"
                            >
                              Найти
                            </v-btn>
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="formCompany.companyName"
                              :label="getNameSearchCompany"
                              :rules="requiredRules"
                              required
                              @click="clickNameCompany"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="formCompany.address"
                              label="Адрес"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-card-actions class="justify-center">
                          <v-btn
                            tile
                            style="margin:0 160px 0 0"
                            color="#6292a8"
                            outlined
                            min-width="135"
                            @click="saveStepCompany"
                          >
                            Далее
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2" style="justify-content: space-around; display: flex;">
                <v-card flat height="100vh" width="800">
                  <v-card-text>
                    <v-form ref="form">
                      <v-container>
                        <v-row>
                          <v-col cols="4" sm="4">
                            <v-autocomplete
                              v-model="presetAutocompleteCity"
                              :items="presetCityList"
                              :rules="requiredRules"
                              :search-input.sync="presetSearchCityQuery"
                              class="mr-4"
                              color="white"
                              label="Введите населенный пункт"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4" sm="4">
                            <v-autocomplete
                              v-model="presetAutocompleteValue"
                              :items="presetFiasAddressList"
                              :search-input.sync="presetSearchAddressQuery"
                              class="mr-4 ml-4"
                              label="Улица"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                          <v-col :sm="4" cols="4">
                            <v-autocomplete
                              v-model="presetAutocompleteHome"
                              :items="presetNumberHomeList"
                              :rules="requiredRules"
                              :search-input.sync="presetSearchHomeQuery"
                              class="ml-4"
                              label="Номер дома"
                              required
                              return-object
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      tile
                      style="margin:12px"
                      min-width="135"
                      color="#6292a8"
                      outlined
                      @click="saveStepHome"
                    >
                      Далее
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3" style="justify-content: space-around; display: flex;">
                <v-card flat height="100vh" width="800">
                  <v-card-text>
                    <v-form ref="formOwner">
                      <v-container>
                        <v-row>
                          <v-col :sm="6" cols="6">
                            <v-text-field
                              v-model="formOwner.personal_account"
                              class="mr-4"
                              label="Номер лицевого счета"
                              maxlength="10"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col :sm="6" cols="6">
                            <v-text-field
                              v-model="formOwner.phone"
                              v-mask="'+7 (###) ###-##-##'"
                              :label="labelPhone"
                              :rules="lengthPhone"
                              class="ml-4"
                              required
                              @click="clickPhone"
                            ></v-text-field>
                          </v-col>
                          <v-col :sm="4" cols="4">
                            <v-text-field
                              v-model="formOwner.placement"
                              :rules="requiredRules"
                              class="mr-4"
                              label="Помещение"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col :sm="4" cols="4">
                            <v-text-field
                              v-model="formOwner.entrance"
                              :rules="requiredRules"
                              class="mr-4 ml-4"
                              label="Подъезд"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col :sm="4" cols="4">
                            <v-text-field
                              v-model="formOwner.floor"
                              :rules="requiredRules"
                              class="ml-4"
                              label="Этаж"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-switch
                              style="width: 150px;"
                              v-model="switch1"
                              label="Организация"
                              @change="changeSwitch"
                            ></v-switch>
                          </v-col>

                          <v-col v-if="!switch1" cols="4" md="4" sm="4">
                            <v-text-field
                              v-model="formOwner.individual.last_name"
                              class="mr-4"
                              label="Фамилия"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="!switch1" cols="4" md="4" sm="4">
                            <v-text-field
                              v-model="formOwner.individual.first_name"
                              class="ml-4 mr-4"
                              label="Имя"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="!switch1" cols="4" md="4" sm="4">
                            <v-text-field
                              v-model="formOwner.individual.patronymic"
                              class="ml-4"
                              label="Отчество"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="switch1" cols="4" md="4" sm="4">
                            <v-text-field
                              v-model="formOwner.entity.name"
                              class="mr-4"
                              label="Организация"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-flex absolute class="ml-4 mb-7"> </v-flex>
                    <v-btn
                      tile
                      min-width="135"
                      style="margin:0 21px 0 0"
                      color="#6292a8"
                      outlined
                      @click="saveStepOwner"
                    >
                      Далее
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="4" style="justify-content: space-around; display: flex;">
                <v-card flat height="100vh" width="800">
                  <v-card-text>
                    <v-form ref="form">
                      <v-container>
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-select
                              v-model="formClaimType.claimType"
                              :items="claimTypeList"
                              :rules="requiredRules"
                              class="mr-4"
                              color="white"
                              label="Специализация"
                            ></v-select>
                          </v-col>
                          <v-col cons="6" sm="6">
                            <v-text-field
                              v-model="formExecutor.phone"
                              v-mask="'+7 (###) ###-##-##'"
                              :label="labelPhone"
                              :rules="lengthPhone"
                              class="ml-4"
                              required
                              @click="clickPhoneExecutor"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4" sm="4">
                            <v-text-field
                              v-model="formExecutor.employee.last_name"
                              :rules="requiredRules"
                              class="mr-4"
                              label="Фамилия"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col :sm="4" cols="4">
                            <v-text-field
                              v-model="formExecutor.employee.first_name"
                              :rules="requiredRules"
                              class="ml-4 mr-4"
                              label="Имя"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col :sm="4" cols="4">
                            <v-text-field
                              v-model="formExecutor.employee.patronymic"
                              class="ml-4"
                              label="Отчество"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      tile
                      min-width="135"
                      style="margin:0 20px 0 0"
                      color="#6292a8"
                      outlined
                      @click="savePreset"
                    >
                      Завершить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config";

export default {
  async mounted() {
    this.$store.dispatch("getClaimTypeList");
    this.$store.dispatch("getCityTypeList");
    this.$store.dispatch("getCompanyList");
  },
  data: () => ({
    // Home
    presetAutocompleteCity: {},
    presetCityList: [],
    presetSearchCityQuery: "",
    presetAutocompleteValue: null,
    presetFiasAddressList: [],
    presetSearchAddressQuery: "",
    timerId: null,
    presetAutocompleteHome: null,
    presetNumberHomeList: [],
    presetSearchHomeQuery: "",
    nameCompany: "Название компании",
    labelPhone: "Телефон +7 (XXX) XXX-XX-XX",
    companyNotifications: true,
    homeNotifications: false,
    ownerNotifications: false,
    executorNotifications: false,
    e1: 1,
    switch1: false,
    dialog: true,
    formCompany: {
      companyName: "",
      inn: "",
      address: ""
    },
    formHome: {
      company: "",
      homeNumber: "",
      street: "",
      building: ""
    },
    formOwner: {
      personal_account: null,
      phone: "",
      address: Number,
      placement: "",
      entrance: null,
      floor: null,
      individual: { last_name: "", first_name: "", patronymic: "" },
      entity: { name: "" }
    },
    formClaimType: {
      claimType: ""
    },
    formExecutor: {
      companylist: [],
      claimType: Number,
      phone: "",
      employee: { first_name: "", last_name: "", patronymic: "" }
    },
    requiredRules: [v => !!v || "Это поле обязательное"],
    lengthPhone: [
      v => !!v || "Это поле обязательное",
      v => (v && v.length > 17) || "Введите корректно номер"
    ],
    lengthPersonalAccount: [
      v => !!v || "Это поле обязательное",
      v => (v && v.length <= 10) || "Введите корректно номер"
    ],
    claimTypeList: [
      { value: 26, text: "Слесарь-сантехник" },
      { value: 27, text: "Электрик" },
      { value: 28, text: "Инженер" },
      { value: 29, text: "Диспетчер" }
    ]
  }),
  methods: {
    changeSwitch() {
      this.formOwner.individual.last_name = "";
      this.formOwner.individual.first_name = "";
      this.formOwner.individual.patronymic = "";
      this.formOwner.entity.name = "";
    },
    nextLocality() {
      this.e1 = 2;
      console.log("stateCompanyId", this.$store.state.newCompanyId);
    },
    saveStepCompany() {
      this.e1 = 2;
      this.companyNotifications = false;
      this.homeNotifications = true;
    },
    saveStepHome() {
      this.e1 = 3;
      this.homeNotifications = false;
      this.ownerNotifications = true;
    },
    saveStepOwner() {
      this.e1 = 4;
      this.ownerNotifications = false;
      this.executorNotifications = true;
    },
    savePreset() {
      if (this.$refs.form.validate()) {
        const payload = {
          // locality: this.formLocality,
          home: {
            city: this.presetAutocompleteCity.text,
            number: this.presetAutocompleteHome.text,
            street: this.presetAutocompleteValue.text,
            house_fiasid: this.presetAutocompleteHome.value
          },
          company: this.formCompany,
          owner: this.formOwner,
          claimType: this.formClaimType.claimType,
          executor: this.formExecutor
        };
        console.log("savePreset payload", payload);
        this.$store.dispatch("savePreset", payload);
        // this.$store.dispatch('saveCompany', this.formCompany)
      }
    },
    clickPhone() {
      console.log("clickPhone");
      this.formOwner.phone = "+7 (";
      console.log("formDataPhone", this.formOwner.phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    },
    clickPhoneExecutor() {
      this.formExecutor.phone = "+7 (";
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    },
    clickNameCompany() {
      this.$store.commit("updateNameSearchCompany", "Название компании");
    },
    searchCompany() {
      this.$store.dispatch("searchCompany", this.formCompany);
      setTimeout(() => {
        this.formCompany.companyName = this.getSearchCompanyResult.value;
        this.formCompany.address = this.getSearchCompanyResult.data.address.unrestricted_value;
        this.$store.commit("setSearchCompanyResult", "");
      }, 500);
    }
  },
  computed: {
    ...mapGetters([
      "HouseList",
      "localityList",
      "companyList",
      "getClaimTypeList",
      "getterCityTypeList",
      "getNameSearchCompany",
      "getSearchCompanyResult"
    ])
  },
  watch: {
    presetSearchCityQuery(val) {
      if (!val) return;
      fetch(config.BACKEND_URL + "/company/fias-address/", {
        method: "POST",
        headers: {
          Authorization: this.$store.state.auth.accessToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ address: val, city: { value: "city" } })
      })
        .then(res => res.json())
        .then(res => {
          res.forEach(data => {
            const result = {};
            result.value = data.data.city_fias_id;
            result.text = data.value;
            this.presetCityList.push(result);
          });
        });
    },
    presetSearchAddressQuery(val) {
      if (!val) return;
      this.timerId = setTimeout(async () => {
        try {
          const res = await fetch(config.BACKEND_URL + "/company/fias-address/", {
            method: "POST",
            headers: {
              Authorization: this.$store.state.auth.accessToken,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              address: val,
              locations: [{ city_fias_id: this.presetAutocompleteCity.value }]
            })
          });
          const result = await res.json();
          const resList = [];
          result.forEach(data => {
            const res = {};
            res.value = data.data.street_fias_id;
            res.text = data.data.street_with_type;
            resList.push(res);
          });
          console.log("resList", resList);
          this.presetFiasAddressList = resList;
        } catch (e) {
          this.presetFiasAddressList = [];
          console.log(e);
        }
      }, 10);
    },
    presetSearchHomeQuery(val) {
      if (!val) return;
      try {
        fetch(config.BACKEND_URL + "/company/fias-address/", {
          method: "POST",
          headers: {
            Authorization: this.$store.state.auth.accessToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            address: val,
            locations: [{ street_fias_id: this.presetAutocompleteValue.value }]
          })
        })
          .then(res => res.json())
          .then(res => {
            this.presetNumberHomeList = [];
            res.forEach(data => {
              const result = {};
              result.value = data.data.house_fias_id;
              // result.text = data.data.house
              let house = data.data.house;
              let block = data.data.block;
              let text = "";
              if (block) {
                text = `${house} к.${block}`;
              } else {
                text = house;
              }
              result.text = text;
              this.presetNumberHomeList.push(result);
            });
          });
      } catch (e) {
        this.presetNumberHomeList = [];
      }
    }
  }
};
</script>
<style>
.v-stepper,
.v-stepper__header {
  box-shadow: unset !important;
}
</style>
