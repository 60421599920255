<!-- Создание заявки/обращения (Иконка + в NavBar) -->
<template>
  <v-container>
    <v-row>
      <v-dialog v-model="dialog" :persistent="getPersistentCreateClaimDialog" max-width="50%">
        <v-layout>
          <v-flex>
            <v-card >
              <v-card-title
                :class="{
                  'header-emergency': formData.emergency === true,
                  'header-payable': formData.payable === true
                }"
                class=" header"
                style="padding:24px "
              >
                <v-row style="height: 12vh; display: flex; align-items: center;">
                  <v-col style="padding: 0px 0px 12px 0px"> 
                    <!-- Компонент переключатель -->
                    <v-switch
                      v-model="formData.appeal"
                      class="ml-4"
                      color="success"
                      @change="changeSwitch"
                    >
                      <template v-slot:label>
                        <span class="headline" style="color: #F5F5F5">
                          <span :class="formData.appeal ? 'activeClaim' : 'standardColor'"
                            >Заявка</span
                          >
                          /
                          <span  :class="!formData.appeal ? 'activeClaim' : 'standardColor'"
                            >Обращение</span
                          >
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col sm="3">
                    <span v-if="formData.emergency" class=" "> Аварийная </span>
                    <span v-if="formData.payable" class=" "> Платная </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <!-- Убрал линию с данными между хедером и формой   -->
              <!-- <v-sheet>
                <v-layout>
                  <v-flex
                    class="d-flex align-start ml-10"
                  
                    pb="4"
                    sm="3"
                    style="color: #636162"
                  >
                    <span v-if="formData.applicant"> {{ localPropertyOwnerDetail.phone }}</span>
                  </v-flex> -->
              <!-- <v-flex
                    class="d-flex align-start ml-10"
                    cols="4"
                    pb="4"
                    sm="3"
                    style="color: #636162"
                  >
                    <span>
                      <span v-if="localPropertyOwnerDetail.individual">
                        {{ localPropertyOwnerDetail.individual.last_name }}
                        {{ localPropertyOwnerDetail.individual.first_name }}
                        {{ localPropertyOwnerDetail.individual.patronymic }}
                      </span>
                      <span v-else-if="localPropertyOwnerDetail.entity">
                        {{ localPropertyOwnerDetail.entity.name }}
                      </span>
                    </span>
                  </v-flex> -->
              <!-- <v-flex cols="3" sm="3" style="color: #636162">
                    <span v-if="localPropertyOwnerDetail.placement !== undefined">
                      {{ addressOwnerFunction }}
                    </span>
                  </v-flex> -->
              <!-- </v-layout>
              </v-sheet> -->
              <v-card-text>
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col sm="6">
                        <v-select
                          v-model="formData.company"
                          :items="companyList"
                          :rules="requiredRules"
                          
                          label="Компания"
                          required
                          @change="company => changeCompanyList(company)"
                        ></v-select>
                      </v-col>
                      <v-col sm="6">
                        <v-select
                          v-model="formData.address"
                          :items="$store.state.relativeHouseList"
                          :rules="requiredRules"
                          
                          label="Адрес дома"
                          required
                        ></v-select>
                      </v-col>

                      <v-col sm="6">
                        <v-autocomplete
                          v-model="applicantAutocompleteValue"
                          :items="relativeApplicantList"
                          :no-filter="true"
                          :rules="requiredRules"
                          :search-input.sync="searchApplicantQuery"
                          
                          item-value="id"
                          label="Заявитель"
                          placeholder="Наберите любые реквизиты заявителя"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="6">
                        <v-text-field
                          v-model="formData.contact_phone"
                          v-mask="'+7 (###) ###-##-##'"
                          :label="labelPhone"
                          :rules="lengthPhone"
                          
                          required
                          @click="clickPhone"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="12" style="max-height: 278px;">
                        <v-textarea
                          v-model="formData.text"
                          :label="!formData.appeal ? 'Текст заявки' : 'Текст обращения'"
                          :rows="!formData.appeal ? 2 : 10"
                          :rules="requiredRules"
                          hide-details
                          no-resize
                          outlined
                          required
                          autofocus
                          tile
                        ></v-textarea>
                      </v-col>

                      <v-col v-if="!formData.appeal" sm="6">
                        <v-select
                          v-model="formData.claim_type"
                          :items="relativeSpecializationList"
                         
                          clearable
                          item-text="text"
                          item-value="value"
                          label="Специализация"
                          required
                          @change="claimType => changeClaimType(claimType)"
                        ></v-select>
                      </v-col>
                      <v-col v-if="!formData.appeal" sm="6">
                        <v-select
                          v-model="formData.executor"
                          :items="relativeExecutorList"
                          
                          clearable
                          item-text="text"
                          item-value="value"
                          label="Исполнитель"
                          required
                        ></v-select>
                      </v-col>
                      <v-col v-if="!formData.appeal" sm="6" style="padding: 12px 12px 19px 12px;">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }" >
                            <v-text-field
                            
                              v-model="formData.planned_date"
                              
                              clearable
                              label="Планируемая дата выполнения"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.planned_date"
                            no-title
                            scrollable
                            @input="menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col v-if="!formData.appeal" sm="3" style="padding: 22px 0px 0px 10px;">
                        <v-list-item  style="padding: 0 0 0 0;">
                          <v-list-item-action>
                            <v-checkbox
                              v-model="formData.payable"
                              @change="deactivateEmergency"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Платная</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col v-if="!formData.appeal" sm="3" style="padding: 22px 0px 0px 10px;">
                        <v-list-item  >
                          <v-list-item-action>
                            <v-checkbox
                              v-model="formData.emergency"
                              @change="deactivatePayable"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Аварийная</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions class="justify-end" style="    padding: 14px 14px 21px 0;">
                <v-flex
                  v-if="getSavingSuccessful"
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  СОХРАНЕНО!
                </v-flex>
                <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
                </v-flex>

                <v-btn
                  color="#fe81a1"
                  min-width="135"
                  outlined
                  tile
                  style="margin:14px"
                  @click="closeDialogCreateClaim"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  color="#6292a8"
                  min-width="135"
                  outlined
                  tile
                  style="margin:14px; "
                  @click="saveClaim"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import config from "@/config";
import Vue from "vue";
import { VueMaskDirective } from "v-mask";


Vue.directive("mask", VueMaskDirective);

export default {
  mounted() {
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getClaimTypeList");
    this.$store.dispatch("getEmployeeList");
    this.$store.dispatch("getPropertyOwners");
    this.$store.dispatch("getExecutorList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateClaim: Boolean
  },
  data: () => ({
    // getSavingError: true,
    row: null,
    localPropertyOwnerDetail: {},
    newClaim: "",
    applicantExist: true,
    baseFormData: {},
    dialog: false,
    planned_date: "",
    clearPhone: "",
    searchApplicantQuery: "",
    relativeApplicantList: [],
    applicantAutocompleteValue: {},
    applicantLoading: true,
    companyAddresses: [],
    relativeSpecializationList: [],
    relativeExecutorList: [],
    canNotBeClosed: false,
    switchClaim: false,
    addressOwner: "",
    labelPhone: "Телефон +7 (XXX) XXX-XX-XX",
    defaultSelected: {
      value: null,
      text: "Не назначен"
    },
    formData: {
      company: "",
      claim_type: null,
      applicant: null,
      text: "",
      contact_phone: "",
      executor: null,
      emergency: false,
      payable: false,
      address: "",
      planned_date: null,
      appeal: false
    },
    timerId: null,
    date: new Date().toISOString().substring(0, 10),
    menu: false,
    modal: false,
    payload: {},
    requiredRules: [v => !!v || "Это поле обязательное"],
    lengthPhone: [
      v => !!v || "Это поле обязательное",
      v => (v && v.length > 17) || "Введите корректно номер"
    ]
  }),
  methods: {
    async changeCompanyList(companyId) {
      this.$store.dispatch("getRelativeHouseList", companyId);
      const headers = { Authorization: this.$store.state.auth.accessToken };
      const specializationUrl = `${config.BACKEND_URL}/claim/claim-type/?company=${companyId}`;
      try {
        const res = await axios.get(specializationUrl, { headers });
        const specializationCompany = [];
        res.data.forEach(specialization => {
          const res = {};
          res.text = specialization.name;
          res.value = specialization.id;
          specializationCompany.push(res);
        });
        this.relativeSpecializationList = specializationCompany;
      } catch (error) {
        console.log(error);
      }
    },
    async changeClaimType(claimTypeId) {
      const executorUrl = `${config.BACKEND_URL}/company/executor/?company=${this.formData.company}&specialization=${claimTypeId}`;
      const headers = { Authorization: this.$store.state.auth.accessToken };
      try {
        const res = await axios.get(executorUrl, { headers });
        const executorCompany = [];
        res.data.forEach(executor => {
          const result = {};
          result.text = executor.get_requisites;
          result.value = executor.id;
          executorCompany.push(result);
        });
        this.relativeExecutorList = executorCompany;
      } catch (error) {
        console.log(error);
      }
    },
    clearMessage() {
      this.message = "";
    },
    sendMessageTelegram(chatId, text) {
      const token = "bot5261851830:AAF9v6oBcTddOORqAtMFMFKbVJaOy0JUU0Y";
      axios.post(`https://api.telegram.org/${token}/sendMessage`, { chat_id: chatId, text: text });
    },
    saveClaim() {
      if (this.$refs.form.validate()) {
        Object.keys(this.formData).forEach(data => {
          if (this.formData[data] != null) {
            this.payload[data] = this.formData[data];
          }
        });
        if (this.formData.planned_date != null) {
          this.payload.planned_date = `${this.formData.planned_date}T00:00`;
        }
        if (this.payload.contact_phone) {
          this.clearPhone = this.payload.contact_phone.replace(/[-() ]/g, "");
          this.clearPhone = this.clearPhone.replace("+", "");
          this.payload.contact_phone = this.clearPhone;
        }
        this.$store.dispatch("saveClaims", this.payload).then(response => {
          console.log("response", response);
          this.newClaim = response.id;
        });
        setTimeout(() => {
          this.closeDialogCreateClaim();
          this.formData.emergency = false;
          this.formData.payable = false;
        }, 1500);
      }
      this.$store.dispatch("getExecutorDetail", this.formData.executor).then(response => {
        this.chatId = response.chat_id;
      });
      setTimeout(() => {
        try {
          const text = `Вам поступила новая заявка. №${this.newClaim}`;
          this.sendMessageTelegram(this.chatId, text);
          // this.$store.dispatch('sendMessageTelegram', '-1001722539438', text)
          this.clearMessage();
          this.chatId = "";
          this.$store.dispatch("getTelegramChannelNumber", this.formData.company);
        } catch (e) {
          console.log(`error - ${e}`);
        }
      }, 2300);
    },
    closeDialogCreateClaim() {
      console.log("localStorage.token");
      this.relativeApplicantList = [];
      this.dialog = false;
      this.applicantExist = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
      this.labelPhone = "Телефон +7 (XXX) XXX-XX-XX";
      this.localPropertyOwnerDetail = {};
      console.log("localProperty", this.$store.state.propertyOwner.address);
    },
    getDialogWindow() {
      this.dialog = true;
    },
    clickPhone() {
      console.log("clickPhone");
      this.formData.contact_phone = "+7 (";
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    },
    deactivatePayable(newValue) {
      if (newValue === true) {
        this.formData.payable = false;
      }
    },
    deactivateEmergency(newValue) {
      if (newValue === true) {
        this.formData.emergency = false;
      }
    },
    changeSwitch() {
      this.$refs.form.reset();
      this.applicantExist = false;
    }
  },
  computed: {
    ...mapGetters([
      "getDialogCreateClaim",
      "getClaimTypeList",
      "companyList",
      "getApplicantList",
      "getExecutorList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "getErrorText",
      "getterPropertyOwnerDetail"
    ]),
    addressOwnerFunction() {
      const addressOwner = `${this.$store.state.houseDetail.street} \
         ${this.$store.state.houseDetail.house_number} кв. \
         ${this.localPropertyOwnerDetail.placement}`;
      return addressOwner;
    }
  },
  watch: {
    searchApplicantQuery(val) {
      if (!val || val === this.formData?.applicant?.get_requisites) return;
      if (val.length < 1) return;
      if (typeof val !== "number" || val.length < 2) {
        console.log("val", val);
      }
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(async () => {
        if (val.length < 30) {
          try {
            const headers = { Authorization: this.$store.state.auth.accessToken };
            const res = await axios.get(
              `${config.BACKEND_URL}/property-owner/?company=${this.formData.company}&address=${this.formData.address}&search=${val}`,
              { headers }
            );
            const resList = [];
            console.log("searchApplicant", res);
            res.data.forEach(data => {
              const res = {};
              res.value = data.id;
              res.text = data.get_full_requisites;
              resList.push(res);
            });
            resList.forEach(res => {
              console.log("resList", res);
              if (res.text.indexOf(` ${val})`) !== -1) {
                console.log("find", res);
                resList.unshift(res);
              } else {
                console.log("find nothing", val);
              }
            });
            this.relativeApplicantList = resList;
            console.log("relativeApplicantList", this.relativeApplicantList);
          } catch (err) {
            console.log(err);
          }
        }
      }, 200);
    },

    // Ошибка получения null newValue?.value Добавил Необязательный оператор цепочки (?.) обращается к свойству объекта или вызывает функцию. Если объект является undefined or null, он возвращает undefined вместо того, чтобы выдавать ошибку.

    applicantAutocompleteValue(newValue) {
      console.log("applicantAutocompleteValue ***", newValue);
      this.formData.applicant = newValue?.value;
      this.$store.dispatch("getPropertyOwnerDetail", newValue?.value);

      setTimeout(() => {
        this.applicantExist = true;
        this.localPropertyOwnerDetail = { ...this.$store.state.propertyOwner };
        console.log("localPropertyOwnerDetail", this.localPropertyOwnerDetail);
        this.$store.dispatch("getHouseDetail", this.localPropertyOwnerDetail.address);
        console.log("getHouseDetail", this.$store.state.houseDetail);
      }, 700);
      // this.applicantAutocompleteValue = propertyOwnerId
    },
    localPropertyOwnerDetail(newValue) {
      console.log("localPropertyOwnerDetail newValue", newValue);
    },
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
        console.log("сработал commit canNotBeClosedCreateClaimDialog");
      },
      deep: true
    }
  }
};
</script>

<style>
.header {
  color: #ffffff;
  background-color: #0288d1;
}

.header-emergency {
  background: linear-gradient(90deg, #0288d1 50%, #fe81a1);
}

.header-payable {
  background: linear-gradient(90deg, #0288d1 50%, #81c784);
}
.activeClaim {
  color: #90a4ae;
  font-size: 1.5rem;
}
</style>
