<!-- Модальное окно создания дома -->
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :persistent="getPersistentCreateClaimDialog" 
        width="50%"
      >
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class=" header" style="padding:24px">
                <v-col>
                  <span class="headline">Создать дом</span>
                </v-col>
              </v-card-title>
              <v-sheet color="#a6ced0">
                <v-layout>
                  <v-flex sm="3" style="color: #636162">
                    <span v-if="formData.applicant">
                      {{ $store.state.houseDetail.street }}
                      {{ $store.state.houseDetail.number }}
                      кв. {{ localPropertyOwnerDetail.placement }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-sheet>
              <v-card-text style="padding:24px">
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col sm="6">
                        <v-select
                          v-model="company"
                          :items="companyList"
                          :rules="requiredRules"
                          label="Компания"
                          required
                        ></v-select>
                      </v-col>

                      <v-col sm="6">
                        <v-autocomplete
                          v-model="autocompleteCity"
                          :items="cityList"
                          :rules="requiredRules"
                          :search-input.sync="searchCityQuery"
                          label="Введите населенный пункт"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                      <v-col sm="6">
                        <v-autocomplete
                          v-model="autocompleteValue"
                          :items="getFiasAddress"
                          :search-input.sync="searchAddressQuery"
                          label="Улица"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                      <v-col :sm="6">
                        <v-autocomplete
                          v-model="autocompleteHome"
                          :items="numberHomeList"
                          :rules="requiredRules"
                          :search-input.sync="searchHomeQuery"
                          label="Номер дома"
                          required
                          return-object
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions style="padding:24px; justify-content: end;">
                <v-flex
                  v-if="getSavingSuccessful"
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  СОХРАНЕНО!
                </v-flex>
                <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
                </v-flex>

                <v-btn
                  style="margin:12px"
                  color="#fe81a1"
                  outlined
                  tile
                  min-width="135"
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  style="margin:12px"
                  color="#6292a8"
                  outlined
                  tile
                  min-width="135"
                  @click="saveHome"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config";

export default {
  async mounted() {
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getLocalityList");
    this.$store.dispatch("getCityTypeList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateHome: {
      type: Boolean
    }
  },
  data: () => ({
    searchAddressQuery: "",
    searchHomeQuery: "",
    searchCityQuery: "",
    autocompleteValue: null,
    autocompleteCity: {},
    autocompleteHome: null,
    timerId: null,
    cityList: [],
    dialog: false,
    tab: null,
    company: Number,
    formData: {
      address: "",
      locality: Number,
      street: "",
      homeNumber: null,
      building: ""
    },
    numberHomeList: [],
    getFiasAddress: [],
    requiredRules: [v => !!v || "Это поле обязательное"]
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
    },
    getDialogCreateHome() {
      this.dialog = true;
    },
    saveHome() {
      if (this.$refs.form.validate()) {
        const payload = {
          company: this.company,
          street: this.autocompleteValue.text,
          city: this.autocompleteCity.text,
          number: this.autocompleteHome.text,
          house_fiasid: this.autocompleteHome.value
        };
        console.log("saveHome payload", payload);
        this.$store.dispatch("saveHome", payload);
        console.log("formdata", this.formData);
        this.$store.commit("showSavingSuccessful");
        setTimeout(() => {
          this.$store.commit("hideSavingSuccessful");
          this.$store.dispatch("getLocalityList");
          this.closeDialog();
        }, 1500);
      }
    }
  },
  computed: {
    ...mapGetters([
      "companyList",
      "localityList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "getterHomeList",
      // "localityTypes",
      "getterCityTypeList",
      "getterFiasAddressList"
    ])
  },
  watch: {
    searchCityQuery(val) {
      if (!val) return;
      fetch(config.BACKEND_URL + "/company/fias-address/", {
        method: "POST",
        headers: {
          Authorization: this.$store.state.auth.accessToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ address: val, city: { value: "city" } })
      })
        .then(res => res.json())
        .then(res => {
          res.forEach(data => {
            const result = {};
            result.value = data.data.city_fias_id;
            result.text = data.value;
            this.cityList.push(result);
          });
        });
    },
    searchHomeQuery(val) {
      if (!val) return;
      try {
        fetch(config.BACKEND_URL + "/company/fias-address/", {
          method: "POST",
          headers: {
            Authorization: this.$store.state.auth.accessToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            address: val,
            locations: [{ street_fias_id: this.autocompleteValue.value }]
          })
        })
          .then(res => res.json())
          .then(res => {
            this.numberHomeList = [];
            res.forEach(data => {
              const result = {};
              result.value = data.data.house_fias_id;
              let house = data.data.house;
              let block = data.data.block;
              let text = "";
              if (block) {
                text = `${house} к.${block}`;
              } else {
                text = house;
              }
              result.text = text;
              this.numberHomeList.push(result);
            });
          });
      } catch (e) {
        this.numberHomeList = [];
      }
    },
    searchAddressQuery(val) {
      if (!val || val === this.autocompleteValue?.value) return;
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(async () => {
        try {
          const res = await fetch(config.BACKEND_URL + "/company/fias-address/", {
            method: "POST",
            headers: {
              Authorization: this.$store.state.auth.accessToken,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              address: val,
              locations: [{ city_fias_id: this.autocompleteCity.value }]
            })
          });
          const result = await res.json();
          const resList = [];
          result.forEach(data => {
            const res = {};
            res.value = data.data.street_fias_id;
            res.text = data.data.street_with_type;
            resList.push(res);
          });
          console.log("resList", resList);
          this.getFiasAddress = resList;
        } catch (e) {
          this.getFiasAddress = [];
          console.log(e);
        }
      }, 10);
    },
    getFiasAddress() {},
    autocompleteValue(newValue) {
      console.log("newValue", newValue);
    },
    numberHomeList() {},
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
      },
      deep: true
    }
  }
};
</script>
