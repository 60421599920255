<!-- Детальная информация и возможность изменить по такому алгоритму действий. NavBar->Редактировать исполнителя->Клик на одного из списка исполнителей->Открывается модальное окно->Возможность редактирования исполнителя  -->

<template>
  <v-card class="card-wrapper" height="100%" @keyup.esc="close">
    <template>
      <v-sheet>
        <v-card-title class="header" style="padding:24px ">
          <v-col>
            <span class="headline">Редактировать исполнителя</span>
          </v-col>
        </v-card-title>
      </v-sheet>

      <v-card-text style="padding:24px ">
        <v-form v-model="valid" class="compact-form">
          <v-container>
            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localExecutorDetail.employee.last_name"
                  label="Фамилия"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localExecutorDetail.employee.first_name"
                  label="Имя"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="localExecutorDetail.employee.patronymic"
                  label="Отчество"
                  @change="changes = true"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4">
                <v-text-field
                  v-model="localExecutorDetail.phone"
                  v-mask="'+7 (###) ###-##-##'"
                  label="Телефон"
                  @change="changes = true"
                  @click="clickPhone"
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <div>
                  <v-autocomplete
                    v-model="localExecutorDetail.companies"
                    :items="companyList"
                    label="Компания"
                    multiple
                    @change="changes = true"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col sm="4">
                <div>
                  <v-select
                    v-model="localExecutorDetail.specialization"
                    :items="getClaimTypeList"
                    label="Специализация"
                    @change="changes = true"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <transition name="fade">
        <div>
          <v-sheet>
            <v-card-actions style="padding:22px">
              <v-icon v-if="getSavingSuccessful" color="#66BB6A">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-flex v-if="getSavingSuccessful" class="ml-2" style="color:#66BB6A">
                СОХРАНЕНО!
              </v-flex>
              <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                <v-icon v-if="getSavingError" color="#EF5350">
                  mdi-close-circle
                </v-icon>
                ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
              </v-flex>
              <v-spacer />
              <v-btn
                style="margin:12px "
                color="#fe81a1"
                min-width="135"
                outlined
                tile
                @click="closeEditCompanyDetail"
                >Закрыть
              </v-btn>
              <v-btn
                style="margin:12px "
                color="#6292a8"
                tile
                min-width="135"
                outlined
                @click="saveExecutor"
                >Сохранить
              </v-btn>
            </v-card-actions>
          </v-sheet>
        </div>
      </transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    executorId: null
  },
  data() {
    return {
      baseExecutorDetail: {
        specialization: Number,
        phone: "",
        companies: [],
        employee: {
          first_name: "",
          last_name: "",
          patronymic: ""
        }
      },
      localExecutorDetail: {
        specialization: Number,
        phone: "",
        companies: [],
        employee: {
          first_name: "",
          last_name: "",
          patronymic: ""
        }
      },
      phoneField: "",
      changedData: {},
      valid: true,
      changes: false,
      lengthPhone: [
        v => !!v || "Это поле обязательное",
        v => (v && v.length > 17) || "Введите корректно номер"
      ]
    };
  },
  created() {
    this.baseExecutorDetail = this.$store.state.executorDetail;
    console.log("baseExecutorDetail", this.baseExecutorDetail);
    this.localExecutorDetail = { ...this.getDetailExecutor };
    this.$store.dispatch("getClaimTypeList");
    this.$store.dispatch("getExecutorDetail", this.executorId);
    if (this.localExecutorDetail.phone) {
      this.phoneField = this.localExecutorDetail.phone;
    }
  },
  methods: {
    closeEditCompanyDetail() {
      this.$emit("close");
    },
    saveExecutor() {
      // Object.keys(this.baseExecutorDetail).forEach((prop) => {
      //   if (this.baseExecutorDetail[prop] !== this.localExecutorDetail[prop]){
      //     this.changedData[prop] = this.localExecutorDetail[prop];
      //   }
      // })
      // Object.keys(this.baseExecutorDetail.employee).forEach((prop) => {
      //   console.log(this.baseExecutorDetail.employee[prop], this.localExecutorDetail.employee[prop])
      //   if (this.baseExecutorDetail.employee[prop] !== this.localExecutorDetail.employee[prop]){
      //     this.changedData.employee[prop] = this.localExecutorDetail.employee[prop];
      //     console.log('changeData employee', this.changedData.employee)
      //   }
      // })
      // console.log('employee', this.changedData.employee)
      // console.log('base', this.baseExecutorDetail, 'local', this.localExecutorDetail)
      // console.log('changeData', this.changedData)
      // console.log('executorId', this.executorId)
      if (this.localExecutorDetail.phone) {
        this.clearPhone = this.localExecutorDetail.phone.replace(/[-() ]/g, "");
        this.clearPhone = this.clearPhone.replace("+", "");
        this.localExecutorDetail.phone = this.clearPhone;
      }
      console.log("this.localExecutorDetail", this.localExecutorDetail);
      this.$store.dispatch("changeExecutor", {
        id: this.executorId,
        data: this.localExecutorDetail
      });
      setTimeout(() => {
        this.$emit("close");
      }, 1500);
    },
    clickPhone() {
      console.log("clickPhone");
      this.formData.contact_phone = "+7 (";
      console.log("formDataPhone", this.formData.contact_phone);
      this.labelPhone = this.labelPhone.split(" ", 1)[0];
    }
  },
  computed: {
    ...mapGetters(["companyList", "getClaimTypeList", "getSavingSuccessful", "getSavingError"]),
    getDetailExecutor() {
      return this.$store.state.executorDetail;
    }
  },
  watch: {
    changes(newValue) {
      if (newValue === true) {
        this.$store.commit("lockedExecutorDetail");
        setTimeout(() => {
          this.$store.commit("unlockedExecutorDetail");
        }, 5000);
      }
    }
  }
};
</script>
<style></style>
