<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" :persistent="getPersistentCreateClaimDialog" max-width="50%">
        <v-layout>
          <v-flex>
            <v-card>
              <v-card-title class="header" style="padding:24px">
                <v-col>
                  <span class="headline">Создать компанию</span>
                </v-col>
              </v-card-title>
              <v-sheet color="#a6ced0">
                <v-layout>
                  <v-flex sm="3" style="color: #636162">
                    <span v-if="formData.applicant">
                      {{ $store.state.houseDetail.street }}
                      {{ $store.state.houseDetail.number }}
                      кв. {{ localPropertyOwnerDetail.placement }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-sheet>
              <v-card-text style="padding:24px ">
                <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col sm="3" style="padding: 12px 0px 12px 12px;">
                        <v-text-field
                          v-model="formData.inn"
                          hint="Название компании заполнится автоматически"
                          label="ИНН"
                          maxlength="12"
                          placeholder="Введите ИНН компании"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col sm="3" style="display: flex;justify-content: center; align-items: center; max-width: 184px; margin: 0px 0px 8px 0;">
                        <v-btn
                          min-width="135"
                          color="#81C784"
                          outlined
                          tile
                          @click="searchCompany"
                        >
                          Найти
                        </v-btn>
                      </v-col>
                      <v-col  style="padding: 12px 12px 12px 0px;">
                        <v-text-field
                          v-model="formData.companyName"
                          :label="getNameSearchCompany"
                          :rules="requiredRules"
                          required
                          @click="clickNameCompany"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12">
                        <v-text-field
                          v-model="formData.address"
                          label="Адрес"
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions style="padding: 24px;" class="justify-end">
                <v-flex
                  v-if="getSavingSuccessful"
                  absolute
                  class="ml-2 mb-7"
                  height="calc(100% - 10px)"
                  style="color:#66BB6A"
                >
                  <v-icon color="#66BB6A">
                    mdi-checkbox-marked-circle
                  </v-icon>

                  СОХРАНЕНО!
                </v-flex>
                <v-flex v-if="getSavingError" class="ml-5 mb-7" style="color: #EF5350">
                  <v-icon v-if="getSavingError" color="#EF5350">
                    mdi-close-circle
                  </v-icon>
                  ОШИБКА <span v-if="getErrorText">({{ getErrorText }})</span>
                </v-flex>

                <v-btn
                  style="margin:12px"
                  min-width="135"
                  color="#fe81a1"
                  outlined
                  tile
                  @click="closeDialog"
                >
                  Закрыть
                </v-btn>
                <v-btn
                  style="margin:12px"
                  min-width="135"
                  color="#6292a8"
                  outlined
                  tile
                  @click="saveCompany"
                >
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.$store.dispatch("getLocalityList");
    this.$store.commit("hideSavingError");
  },
  props: {
    showCreateCompany: {
      type: Boolean
    }
  },
  data: () => ({
    nameCompany: "Название компании",
    showTooltip: false,
    dialog: false,
    autocompleteCompany: null,
    formData: {
      companyName: "",
      inn: null,
      address: ""
    },
    requiredRules: [v => !!v || "Это поле обязательное"]
  }),
  methods: {
    clickNameCompany() {
      this.$store.commit("updateNameSearchCompany", "Название компании");
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      setTimeout(() => {
        this.$store.commit("canBeClosedCreateClaimDialog");
      }, 100);
    },
    getDialogCreateCompany() {
      this.dialog = true;
    },
    saveCompany() {
      this.$store.dispatch("saveCompany", this.formData);
      this.$emit("close");
      console.log("formData saveCompany", this.formData);
      console.log(this.localityList);
      this.$store.commit("showSavingSuccessful");
      setTimeout(() => {
        this.$store.commit("hideSavingSuccessful");
        this.closeDialog();
      }, 1500);
    },
    searchCompany() {
      this.$store.dispatch("searchCompany", this.formData);
      setTimeout(() => {
        this.formData.companyName = this.getSearchCompanyResult.value;
        this.formData.address = this.getSearchCompanyResult.data.address.unrestricted_value;
        this.$store.commit("setSearchCompanyResult", "");
      }, 500);
    }
  },
  computed: {
    ...mapGetters([
      "localityList",
      "getPersistentCreateClaimDialog",
      "getSavingSuccessful",
      "getSavingError",
      "getNameSearchCompany",
      "getSearchCompanyResult"
    ])
  },
  watch: {
    formData: {
      handler: function() {
        this.$store.commit("canNotBeClosedCreateClaimDialog");
      },
      deep: true
    }
  }
};
</script>
